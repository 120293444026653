import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";

function Megaphone({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>custom-icon-2</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M720-440v-80h160v80H720Zm48 280-128-96 48-64 128 96-48 64Zm-80-480-48-64 128-96 48 64-128 96ZM200-200v-160h-40q-33 0-56.5-23.5T80-440v-80q0-33 23.5-56.5T160-600h160l200-120v480L320-360h-40v160h-80Zm100-280Zm260 134v-268q27 24 43.5 58.5T620-480q0 41-16.5 75.5T560-346ZM160-520v80h182l98 58v-196l-98 58H160Z"
          fill={colors[color] ? colors[color].main : colors.dark.main}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

// Setting default values for the props of CustomSVG2
Megaphone.defaultProps = {
  color: "dark",
  size: "16px",
};

// Typechecking props for the CustomSVG2
Megaphone.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "white",
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Megaphone;
