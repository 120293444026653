import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

// Import MUI components
import Checkbox from "@mui/material/Checkbox";

// Import SoftUI components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Import Layout Components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import rocket from "assets/images/illustrations/rocket-white.png";

// Import Firebase Components
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import FirebaseContext from "configs/firebasecontext";
import { httpsCallable } from "@firebase/functions";

function SignUp() {
  const navigate = useNavigate();

  const [agreement, setAgreement] = useState(true);
  const [successfulRegistration, setSuccessfulRegistration] = useState(false);
  const [error, setError] = useState(null);
  const [verificationSent, setVerificationSent] = useState(false);
  const [signingUp, setSigningUp] = useState(false);
  const { auth, functions } = useContext(FirebaseContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');

  const handleSetAgreement = () => setAgreement(!agreement);

  const handleSignUp = async (email, password) => {
    try {
      setSigningUp(true);
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await sendEmailVerification(user);
      setVerificationSent(true);

      // Console Log the user
      console.log("User:", user);

      // Create the Workspace and Tenant
      await createWorkspaceAndTenant(user);

    } catch (error) {
      console.log(error);
      switch (error.code) {
        case 'auth/email-already-in-use':
          setError('Email already in use');
          break;
        case 'auth/invalid-email':
          setError('Invalid email');
          break;
        case 'auth/weak-password':
          setError('Password must be at least 6 characters');
          break;
        default:
          setError('Error signing up');
          break;
      }
    } finally {
      setSigningUp(false);
    }
  };

  // Handle Create Workspace and Tenant
  const createWorkspaceAndTenant = async (user) => {
    if (!user) {
      // Console Log the error
      console.error("Error: User not found");
      return;
    }

    // Console Log
    console.log("User in Workspace Setup:", user);

    try {
      // Call the createWorkspaceAndTenant function
      const createWorkspaceAndTenant = httpsCallable(functions, process.env.REACT_APP_CLOUD_FUNCTION_CREATE_WORKSPACE_TENANT);
      const response = await createWorkspaceAndTenant({ name: name, userId: user.uid, userEmail: user.email });

      // Console log the response
      console.log("Response:", response.data);

      if (response.data.error || !response.data.success) {
        setError("There was an error creating your account. Please contact support.");
        return;
      }

      if (response.data.success) {
        setSuccessfulRegistration(true);
        navigate("/app/dashboards/default")
      }

    } catch (error) {
      console.error("Error creating workspace and tenant:", error);
      setError("There was an error creating your account. Please contact support.");
    }
  };

  return (
    <>
      <IllustrationLayout
        title="Sign Up"
        description="Enter your name, email and password to register. You'll be ready to start using PropelPosts in no time."
        illustration={{
          image: rocket,
          title: "Your journey starts here",
          description: "Join and start growing your brand with better, faster, and targetted content for your audience.",
        }}
      >
        <SoftBox component="form" role="form">
          {successfulRegistration && (
            <SoftBox mb={2}>
              <SoftTypography variant="h6" fontWeight="bold" textAlign="center" color="success">
                Registration Successful! Please Enter Your Payment Details
              </SoftTypography>
            </SoftBox>
          )}
          {error && (
            <SoftBox mb={2}>
              <SoftTypography variant="h6" fontWeight="bold" textAlign="center" color="error">
                Error: {error}
              </SoftTypography>
            </SoftBox>
          )}
          <SoftBox mb={2}>
            <SoftInput placeholder="Name" size="large" value={name} onChange={e => setName(e.target.value)} />
          </SoftBox>
          <SoftBox mb={2}>
            <SoftInput type="email" placeholder="Email" size="large" value={email} onChange={e => setEmail(e.target.value)} />
          </SoftBox>
          <SoftBox mb={2}>
            <SoftInput type="password" placeholder="Password" size="large" value={password} onChange={e => setPassword(e.target.value)} />
          </SoftBox>
          <SoftBox display="flex" alignItems="center">
            <Checkbox checked={agreement} onChange={handleSetAgreement} />
            <SoftTypography
              variant="button"
              fontWeight="regular"
              onClick={handleSetAgreement}
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              &nbsp;&nbsp;I agree the&nbsp;
            </SoftTypography>
            <SoftTypography component="a" href="https://www.propelposts.com/terms" target="_blank" rel="noopener noreferrer" variant="button" fontWeight="bold" textGradient>
              Terms and Conditions
            </SoftTypography>
          </SoftBox>
          <SoftBox mt={4} mb={1}>
            <SoftButton variant="gradient" color="info" size="large" fullWidth onClick={() => handleSignUp(email, password)}>
              {signingUp ? "Signing Up..." : "Sign Up"}
            </SoftButton>
          </SoftBox>
          <SoftBox mt={3} textAlign="center">
            <SoftTypography variant="button" color="text" fontWeight="regular">
              Already have an account?&nbsp;
              <SoftTypography
                component={Link}
                to="/authentication/sign-in"
                variant="button"
                color="info"
                fontWeight="bold"
                textGradient
              >
                Sign in
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </IllustrationLayout>
    </>
  );
}

export default SignUp;
