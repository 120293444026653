import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Import Giphy
import { GiphyFetch } from '@giphy/js-fetch-api';
import { Grid } from '@giphy/react-components';

import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import SoftBox from 'components/SoftBox';

import { Modal } from '@mui/material';

// Create Giphy Instance
const gf = new GiphyFetch(process.env.REACT_APP_GIPHY_API_KEY);

export function GiphySearch({ setPostImageUrl, setImageMetaData, insertImageIntoEditor, displayGifSearch, setDisplayGifSearch }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [fetchGifs, setFetchGifs] = useState(() => () => gf.trending({ limit: 10 }));
  const [giphyModal, setGiphyModal] = useState(false);

  const handleSearch = () => {
    setFetchGifs(() => (offset) => gf.search(searchTerm, { limit: 10 }));
    setGiphyModal(true); // Open the modal when search is made
  };

  return (
    <>
      {displayGifSearch && (
        <>
          <SoftBox display="flex" alignItems="center" mb={2} sx={{ width: '100%' }}>
            <SoftInput
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search for GIFs"
              sx={{ flexGrow: 1, marginRight: '8px' }}
            />
            <SoftButton 
              variant="contained" 
              color="light" 
              onClick={handleSearch}
            >
              Search GIFs
            </SoftButton>
          </SoftBox>
        </>
      )}

      <Modal open={giphyModal} onClose={() => setGiphyModal(false)}>
        <SoftBox sx={{ ...modalStyle }}>
          <SoftBox sx={{ overflowY: 'auto', maxHeight: '80vh' }}>
            <Grid
              width={400}
              columns={3}
              fetchGifs={fetchGifs}
              onGifClick={(gif, e) => {
                e.preventDefault();
                setPostImageUrl(gif?.images?.downsized?.url || gif?.images?.original?.url || '');
                setImageMetaData('image/gif');
                insertImageIntoEditor(gif?.images?.downsized?.url || gif?.images?.original?.url || '');
                setDisplayGifSearch(false);
                setGiphyModal(false);
              }}
            />
          </SoftBox>
        </SoftBox>
      </Modal>
    </>
  );
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

GiphySearch.propTypes = {
  setPostImageUrl: PropTypes.func.isRequired,
  setImageMetaData: PropTypes.func.isRequired,
  insertImageIntoEditor: PropTypes.func.isRequired,
  displayGifSearch: PropTypes.bool.isRequired,
  setDisplayGifSearch: PropTypes.func.isRequired,
};
