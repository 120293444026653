import { Navigate } from 'react-router-dom';
import { useAuth } from './firebaseAuthProvider';
import PropTypes from 'prop-types';

const ProtectedRoute = ({ element }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    // If the user is authenticated, return the element
    return element;
  } else {
    // If the user is not authenticated, redirect to the sign-in page
    return <Navigate to="/app/sign-in" replace />;
  }
};

ProtectedRoute.propTypes = {
  element: PropTypes.element.isRequired,
};

export default ProtectedRoute;
