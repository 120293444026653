import React, { useState, useEffect, useContext } from 'react';

// Import MUI components
import { Grid, Box, TextField, IconButton, Typography, Select, MenuItem } from '@mui/material';
import { Add as AddIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Soft UI components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';
import SoftSnackbar from 'components/SoftSnackbar';

// Import User Context
import { useUserData } from "context/userManagement";

// Import Firebase Context
import FirebaseContext from "configs/firebasecontext";
import { fetchWorkspaceMembers, saveInvitationLink } from "components/DatabaseFunctions";

// Import Firebase Functions
import { httpsCallable } from "@firebase/functions";

// Import Upgrade Plan Modal
import UpgradePlanModal from "components/SharedFunctions/upgradePlanModal";

const TeamMembers = () => {
  const { db, functions } = useContext(FirebaseContext);
  const { currentWorkspace, userId, currentUserData } = useUserData();
  const [members, setMembers] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newMemberEmail, setNewMemberEmail] = useState('');
  const [newMemberRole, setNewMemberRole] = useState('Team Member');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  // Variables for Upgrade Plan Modal
  const [openUpgradePlanModal, setOpenUpgradePlanModal] = useState(false);
  const userPermissions = currentUserData?.tenantData[0]?.subscriptionData[0]?.planAccess || {};
  const maxTeamMembers = currentUserData?.tenantData[0]?.subscriptionData[0]?.teamMemberLimit || 1;

  // Variable for email sending
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    const loadMembers = async () => {
      setIsLoading(true);
      try {
        const { workspaceMembers, lastVisible } = await fetchWorkspaceMembers(db, userId, currentWorkspace.id);
        setMembers(workspaceMembers);
        setLastVisible(lastVisible);

        // Console log the workspace members
        console.log("Workspace Members:", workspaceMembers);

      } catch (error) {
        console.error("Error fetching members:", error);

        // Set the snackbar message to the error message
        setSnackbarMessage("Error fetching members: " + error.message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setTimeout(() => setSnackbarOpen(false), 3000);

      }
      setIsLoading(false);
    };

    loadMembers();
  }, [db, currentWorkspace]);

  const loadMoreMembers = async () => {
    if (isLoading || !lastVisible) return;
    setIsLoading(true);
    try {
      const { workspaceMembers, lastVisible: newLastVisible } = await fetchWorkspaceMembers(db, userId, currentWorkspace.id, 10, lastVisible);
      setMembers((prevMembers) => [...prevMembers, ...workspaceMembers]);
      setLastVisible(newLastVisible);
    } catch (error) {
      console.error("Error fetching more members:", error);

        // Set the snackbar message to the error message
        setSnackbarMessage("Error fetching members: " + error.message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setTimeout(() => setSnackbarOpen(false), 3000);

    }
    setIsLoading(false);
  };

  // Generate Invitation Link and Token for inviting new members
  const generateInvitationLink = async (email, role) => {
    try {
        // Generate a new invite token
        const inviteToken = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

        // Generate the invitation link
        if (currentUserData && currentUserData.tenantData && currentUserData.tenantData.length > 0) {

          const tenantId = currentUserData.tenantData[0].tenantId; // Access the tenant ID
      
          const invitationLink = `${window.location.origin}/invite?token=${inviteToken}&workspaceId=${currentWorkspace.id}&tenantId=${tenantId}`;
      
          // Use the invitationLink as needed
          console.log(invitationLink);

          // Save the invitation link to the database
          await saveInvitationLink(db, userId, currentWorkspace.id, email, invitationLink, inviteToken, role);

          // Return the invitation link
          return invitationLink;

          } else {
              console.error('Tenant data is not available');

              // Set the snackbar message to the error message
              setSnackbarMessage("Error generating invitation link");
              setSnackbarSeverity("error");
              setSnackbarOpen(true);
              setTimeout(() => setSnackbarOpen(false), 3000);
          }

    } catch (error) {
        console.error("Error generating invitation link:", error);

        // Set the snackbar message to the error message
        setSnackbarMessage("Error generating invitation link");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setTimeout(() => setSnackbarOpen(false), 3000);
    }
    };

  const handleInviteMember = async () => {
    if (!newMemberEmail) {
        setSnackbarMessage("Please enter an email address");
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
        return;
      }

    // Check if the user has reached the maximum number of team members
    if (members.length >= maxTeamMembers) {
        setSnackbarMessage("You have reached the maximum number of team members for your plan. Please contact support to upgrade your plan.");
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
    }

    // Check if the user has permissions to add more team members
    if (!userPermissions.multipleTeamMembers) {
        setOpenUpgradePlanModal(true);
        return;
    }

    try {
        setIsSending(true);

      // Generate the invitation link
      const invitationLink = await generateInvitationLink(newMemberEmail, newMemberRole);

      // Send the invitation email
      console.log("Invitation Link:", invitationLink);

      // Generate the email message HTML
      const htmlEmailMessage = "<p>You have been invited to join a PropelPosts workspace. Click the link below to accept the invitation and create your account:</p><p><a href='" + invitationLink + "'>Accept Invitation</a></p>";
      const textEmailMessage = "You have been invited to join a PropelPosts workspace. Click the link below to accept the invitation and create your account: " + invitationLink;

      // Set the recipient email address and subject
      const emailTo = newMemberEmail;
      const emailSubject = "You have been invited to join a PropelPosts workspace";

      // Call the firebase function to send the invitation email
        const sendInvitationEmail = httpsCallable(functions, process.env.REACT_APP_CLOUD_FUNCTION_SENDEMAIL);
        const result = await sendInvitationEmail({ to: emailTo, subject: emailSubject, html: htmlEmailMessage, text: textEmailMessage});

      // If the invitation email was sent successfully
        if (result.data.success) {
            // Clear the new member email and role
            setNewMemberEmail("");
            setNewMemberRole("Team Member");
            setIsSending(false);

            // Set the snackbar message to the success message
            setSnackbarMessage("Invitation sent successfully");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            setTimeout(() => setSnackbarOpen(false), 3000);
            }
        else {
            setIsSending(false);

            // Set the snackbar message to the error message
            setSnackbarMessage("Error sending invitation");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            setTimeout(() => setSnackbarOpen(false), 3000);
            }


    } catch (error) {
      console.error("Error sending invitation:", error);

        setIsSending(false);

      // Set the snackbar message to the error message
      setSnackbarMessage("Error sending invitation");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setTimeout(() => setSnackbarOpen(false), 3000);
    }
  };

  return (
    <>
      <SoftBox p={3}>
        <SoftTypography variant="h4" fontWeight="bold" mb={2}>
          Team Members
        </SoftTypography>
        <SoftTypography variant="body2" mb={3}>
          Manage members and set their access level in your workspace.
        </SoftTypography>
        <SoftBox display="flex" flexDirection="column" alignItems="center" mb={2}>
          <TextField
            placeholder="Enter an email address..."
            variant="outlined"
            value={newMemberEmail}
            onChange={(e) => setNewMemberEmail(e.target.value)}
            sx={{ width: 300, mb: 2 }}
          />
          <Select
            value={newMemberRole}
            variant='outlined'
            onChange={(e) => setNewMemberRole(e.target.value)}
            sx={{width: 150, mb: 2}}
          >
            <MenuItem value="Client">Client</MenuItem>
            <MenuItem value="Team Member">Team Member</MenuItem>
          </Select>
          <SoftButton variant="contained" color="primary" onClick={handleInviteMember} sx={{ ml: 2 }}>
            {isSending ? 'Sending...' : 'Invite'}
          </SoftButton>
        </SoftBox>
        <Grid container spacing={2}>
          {members.map((member) => (
            <Grid item xs={12} key={member.email}>
              <SoftBox display="flex" alignItems="center" p={2} bgcolor="grey.100" borderRadius="8px">
                <SoftBox>
                  <SoftTypography variant="body1">
                    {member.name}
                  </SoftTypography>
                  <SoftTypography variant="caption" color="textSecondary">
                    {member.email} - {member.role}
                  </SoftTypography>
                </SoftBox>
                <IconButton aria-label="more" sx={{ ml: 'auto' }}>
                  <MoreVertIcon />
                </IconButton>
              </SoftBox>
            </Grid>
          ))}
        </Grid>
        <SoftBox display="flex" justifyContent="center" mt={3}>
          <SoftButton variant="outlined" onClick={loadMoreMembers} disabled={isLoading || !lastVisible}>
            {isLoading ? 'Loading...' : 'Load More'}
          </SoftButton>
        </SoftBox>
      </SoftBox>
      <SoftSnackbar
        open={snackbarOpen}
        close={() => setSnackbarOpen(false)}
        dateTime={new Date().toLocaleString()}
        content={snackbarMessage}
        color={snackbarSeverity}
        icon="notifications"
        title="Notification"
      />

      {/* Upgrade Plan Modal */}
      <UpgradePlanModal open={openUpgradePlanModal} onClose={() => setOpenUpgradePlanModal(false)} />
    </>
  );
};

export default TeamMembers;
