// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

function Transaction({ id, color, icon, brand, date, description, value, last_4 }) {
  return (
    <SoftBox key={id} component="li" py={1} pr={2} mb={1}>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center">
        <SoftBox display="flex" alignItems="center">
          <SoftBox mr={2}>
            <SoftButton variant="outlined" color={color} size="small" iconOnly circular>
              <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
            </SoftButton>
          </SoftBox>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium" gutterBottom>
              {description}
            </SoftTypography>
            <SoftTypography variant="caption" color="text">
              {date}
            </SoftTypography>
            <SoftTypography variant="caption" color="text">
              Card: {brand} •••• {last_4}
            </SoftTypography>
            <SoftTypography variant="caption" color="text">
              ID: {id.substring(0, 8)}...
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftTypography variant="button" color={color} fontWeight="medium" textGradient>
          {value}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

// Typechecking props of the Transaction
Transaction.propTypes = {
  id: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]).isRequired,
  icon: PropTypes.node.isRequired,
  brand: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  last_4: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

Transaction.defaultProps = {
  color: "primary",
  icon: "arrow_upward",
  brand: "Visa",
  description: "Payment",
  value: "+ $1000",
  last_4: "1234",
};

export default Transaction;
