import { useState, useContext, useEffect } from "react";
import { PropTypes } from "prop-types";

// react-router-dom components
import { Link, useNavigate, useLocation } from "react-router-dom";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SoftAlert from "components/SoftAlert";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Images
import rocket from "assets/images/illustrations/rocket-white.png";

//Firebase Imports
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";

//Import Firebase Context
import FirebaseContext from "configs/firebasecontext";
import { httpsCallable } from "firebase/functions";

// Firebase Database
import {fetchInvitationLink} from "components/DatabaseFunctions";

function InviteSignup() {
  const [agreement, setAgreemnet] = useState(true);
  const [successfulRegistration, setSuccessfulRegistration] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation(); // Correctly initialize useLocation

  const [firebaseuid, setFirebaseuid] = useState(null);

  const handleSetAgremment = () => setAgreemnet(!agreement);

  //Firebase Auth Setup from Context
  const { auth, db, functions } = useContext(FirebaseContext);

  // State of invite from the data
  const [invite, setInvite] = useState(null);

  //States for Email and Password
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState(''); //TODO: Add name to database and sign up form

  // State for signing up
  const [signingUp, setSigningUp] = useState(false);

  // State for the workspace ID and email
  const [workspaceId, setWorkspaceId] = useState(null);

  // State for the tenant ID
  const [tenantId, setTenantId] = useState(null);

  // UseEffect to get the invitation token from the URL
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const workspaceId = queryParams.get('workspaceId');
    const tenant = queryParams.get('tenantId');

    console.log("Token from URL:", token); // Add console log to debug

    if (!token) {
      setError("Invalid invitation link");
      return;
    }
    
    setWorkspaceId(workspaceId);

    // Set the tenant ID in the state
    setTenantId(tenant);

    // Fetch the invitation link from the database
    const fetchInvite = async () => {
      try {
        const dbInvite = await fetchInvitationLink(db, workspaceId, token);
        //Console log the invitation link
        console.log("Invitation Link from Database:", dbInvite);
        // Set the invite in the state
        setInvite(dbInvite);
        // Set the email in the state
        setEmail(dbInvite?.invitedEmail || "");
        console.log("Invitation Link:", invite);
      } catch (error) {
        console.error("Error fetching invitation link:", error);
      }

    };

    fetchInvite();
  }, [location.search, db]);

  //Handle Email and Password Sign Up
  const handleSignUp = async (email, password) => {
    // Check if the user has agreed to the terms
    if (!agreement) {
      setError('Please agree to the terms and conditions');
      return;
    }

    // Check if the user has entered a name
    if (!name) {
      setError('Please enter your name');
      return;
    }

    // Check if the user has entered an email
    if (!email) {
      setError('Please enter your email');
      return;
    }

    // Check if the user has entered a password
    if (!password) {
      setError('Please enter your password');
      return;
    }

    // Set the signing up state to true
    setSigningUp(true);

    try {

        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        // Retrieve the uid of the user
        setFirebaseuid(user.uid); // Store the uid in state
        const firebaseResult = await sendEmailVerification(user);

        // Call the Firebase function to create a new user in the database
        const createUser = httpsCallable(functions, process.env.REACT_APP_CLOUD_FUNCTION_INVITEUSERCREATION);
        const dbResult = await createUser({ email: email, workspaceId: workspaceId, uid: user.uid, role: invite.userRole, name: name, tenantId: tenantId});

        if (dbResult.data.success === true) {
          console.log("User created in database:", dbResult.data.message);

            // Set the successful registration state to true
            setSuccessfulRegistration(true);
            setSigningUp(false);

            // Redirect the user to the workspace after 5 seconds
            setTimeout(() => {
            navigate(`/dashboards/default`);
            }, 5000);

        } else {

            setError('Error creating your user. Please try again or contact support.');
            setSigningUp(false);
        }

    } catch (error) {
        setSigningUp(false);
        console.log(error);
        //Create switch statement to handle different Firebase auth errors
        switch (error.code) {
            case 'auth/email-already-in-use':
            setError('Email already in use');
            break;
            case 'auth/invalid-email':
            setError('Invalid email');
            break;
            case 'auth/weak-password':
            setError('Password must be at least 6 characters');
            break;
            default:
            setError('Error signing up');
            break;
      }
    }
  };

  // Check if the invite state has settled
  if (!invite) {
    return (
      <IllustrationLayout
        title="Loading"
        description="Please wait while we load your invitation link."
        illustration={{
          image: rocket,
          title: "Your journey starts here",
          description:
            "Join and start growing your brand with better, faster, and targetted content for your audience.",
        }}
      >
        <SoftBox display="flex" justifyContent="center" alignItems="center">
          {/* <Lottie
            options={{
            loop: true,
            autoplay: true,
            animationData: require("assets/animations/loading.json"),
            }}
            height={200}
            width={200}
          /> */}
        </SoftBox>
      </IllustrationLayout>
    );
  }

  return (
    <>
      <IllustrationLayout
        title="Sign Up"
        description="You've been invited to join a PropelPosts workspace. Please sign up to get started."
        illustration={{
          image: rocket,
          title: "Your journey starts here",
          description:
            "Join and start growing your brand with better, faster, and targetted content for your audience.",
        }}
      >
        <SoftBox component="form" role="form">
          {successfulRegistration && (
            <SoftBox mb={2}>
              <SoftTypography variant="h6" fontWeight="bold" textAlign="center" color="success">
                Registration Successful! Please check for an email verification and you will now be redirected the PropelPost workspace.
              </SoftTypography>
            </SoftBox>
          )}
          {error && (
            <SoftBox mb={2}>
              <SoftTypography variant="h6" fontWeight="bold" textAlign="center" color="error">
                Error: {error}
              </SoftTypography>
            </SoftBox>
          )}
          <SoftBox mb={2}>
            <SoftInput placeholder="Name" size="large" value={name} onChange={e => setName(e.target.value)} />
          </SoftBox>
          <SoftBox mb={2}>
            <SoftInput type="email" placeholder="Email" size="large" value={email} onChange={e => setEmail(e.target.value)} />
          </SoftBox>
          <SoftBox mb={2}>
            <SoftInput type="password" placeholder="Password" size="large" value={password} onChange={e => setPassword(e.target.value)} />
          </SoftBox>
          <SoftBox display="flex" alignItems="center">
            <Checkbox checked={agreement} onChange={handleSetAgremment} />
            <SoftTypography
              variant="button"
              fontWeight="regular"
              onClick={handleSetAgremment}
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              &nbsp;&nbsp;I agree the&nbsp;
            </SoftTypography>
            <SoftTypography component="a" href="https://www.propelposts.com/terms" target="_blank" rel="noopener noreferrer" variant="button" fontWeight="bold" textGradient>
              Terms and Conditions
            </SoftTypography>
          </SoftBox>
          <SoftBox mt={4} mb={1}>
            <SoftButton variant="gradient" color="info" size="large" fullWidth onClick={() => handleSignUp(email, password)}>
                {signingUp ? "Creating Account..." : "Sign Up"}
            </SoftButton>
          </SoftBox>
          <SoftBox mt={3} textAlign="center">
            <SoftTypography variant="button" color="text" fontWeight="regular">
              Already have an account?&nbsp;
              <SoftTypography
                component={Link}
                to="/authentication/sign-in"
                variant="button"
                color="info"
                fontWeight="bold"
                textGradient
              >
                Sign in
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </IllustrationLayout>
    </>
  );
}

export default InviteSignup;
