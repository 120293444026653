// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DefaultPricingCard from "./components/DefaultPricingCard";

function PricingCards({ prices }) {
  const [starter, professional, agency] = prices;
  return (
    <SoftBox position="relative" zIndex={10} mt={-10} px={{ xs: 1, sm: 0 }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            badge={{ color: "secondary", label: "starter" }}
            price={{ currency: "$", value: starter }}
            specifications={[
              { label: "Content Inspiration", includes: true },
              { label: "AI Assisted Writing", includes: true },
              { label: "AI Content Improvements", includes: true },
              { label: "Post Commenting", includes: true },
              { label: "Content Repurposing", includes: true },
              { label: "Generate Post Ideas", includes: true },
              { label: "Generate Posts", includes: true },
              { label: "Post Styling and Preview", includes: true },
              { label: "Post Scheduling", includes: true },
              { label: "Detailed Post Analytics", includes: false },
              { label: "Engage and Grow", includes: false },
              { label: "Connect and Manage Organisation Pages", includes: false },
              { label: "Organisation Post Targetting", includes: false },
              { label: "Connect upto 10 LinkedIn Accounts", includes: false },
              { label: "Support for 10 Workspaces", includes: false },
              { label: "Invite upto 10 Team Members or Clients", includes: false },
              { label: "Priority Support", includes: false },
            ]}
            action={{
              type: "external",
              route: "https://buy.stripe.com/fZe3g45Dycku9ag8wy",
              color: "dark",
              label: "subscribe",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            badge={{ color: "secondary", label: "professional" }}
            price={{ currency: "$", value: professional }}
            specifications={[
              { label: "Content Inspiration", includes: true },
              { label: "AI Assisted Writing", includes: true },
              { label: "AI Content Improvements", includes: true },
              { label: "Post Commenting", includes: true },
              { label: "Content Repurposing", includes: true },
              { label: "Generate Post Ideas", includes: true },
              { label: "Generate Posts", includes: true },
              { label: "Post Styling and Preview", includes: true },
              { label: "Post Scheduling", includes: true },
              { label: "Detailed Post Analytics", includes: true },
              { label: "Engage and Grow", includes: true },
              { label: "Connect and Manage Organisation Pages", includes: true },
              { label: "Organisation Post Targetting", includes: true },
              { label: "Connect upto 10 LinkedIn Accounts", includes: false },
              { label: "Support for 10 Workspaces", includes: false },
              { label: "Invite upto 10 Team Members or Clients", includes: false },
              { label: "Priority Support", includes: false },
            ]}
            action={{
              type: "external",
              route: "https://buy.stripe.com/dR63g43vqdoyeuA8wz",
              color: "info",
              label: "subscribe",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            badge={{ color: "secondary", label: "agency" }}
            price={{ currency: "$", value: agency }}
            specifications={[
              { label: "Content Inspiration", includes: true },
              { label: "AI Assisted Writing", includes: true },
              { label: "AI Content Improvements", includes: true },
              { label: "Post Commenting", includes: true },
              { label: "Content Repurposing", includes: true },
              { label: "Generate Post Ideas", includes: true },
              { label: "Generate Posts", includes: true },
              { label: "Post Styling and Preview", includes: true },
              { label: "Post Scheduling", includes: true },
              { label: "Detailed Post Analytics", includes: true },
              { label: "Engage and Grow", includes: true },
              { label: "Connect and Manage Organisation Pages", includes: true },
              { label: "Organisation Post Targetting", includes: true },
              { label: "Connect upto 10 LinkedIn Accounts", includes: true },
              { label: "Support for 10 Workspaces", includes: true },
              { label: "Invite upto 10 Team Members or Clients", includes: true },
              { label: "Priority Support", includes: true },
            ]}
            action={{
              type: "external",
              route: "https://buy.stripe.com/9AQ4k84zu1FQ0DK148",
              color: "dark",
              label: "subscribe",
            }}
          />
        </Grid>
      </Grid>
    </SoftBox>
  );
}

// Typechecking props for the PricingCards
PricingCards.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PricingCards;
