
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";

function PricingCards({ prices, activePricingOption, tenantId }) {
  const [starter, professional, agency] = prices;

  // Set the payment link based on the active pricing option
  const getPaymentLink = (pricingOption) => {

        // Initialize the payment links
        let starterMonthlyPaymentLink = "";
        let professionalMonthlyPaymentLink = "";
        let agencyMonthlyPaymentLink = "";
        let starterAnnualPaymentLink = "";
        let professionalAnnualPaymentLink = "";
        let agencyAnnualPaymentLink = "";

        // Check if environment is development
        const isDevelopment = process.env.NODE_ENV === "development";

        // Payment links for the different pricing options
        if (isDevelopment) {
        starterMonthlyPaymentLink = "https://buy.stripe.com/test_4gw03Ughf0VP9JC28b?client_reference_id=" + tenantId;
        professionalMonthlyPaymentLink = "https://buy.stripe.com/test_4gw03Ughf0VP9JC28b?client_reference_id=" + tenantId;
        agencyMonthlyPaymentLink = "https://buy.stripe.com/test_4gw03Ughf0VP9JC28b?client_reference_id=" + tenantId;
        starterAnnualPaymentLink = "https://buy.stripe.com/test_4gw03Ughf0VP9JC28b?client_reference_id=" + tenantId;
        professionalAnnualPaymentLink = "https://buy.stripe.com/test_4gw03Ughf0VP9JC28b?client_reference_id=" + tenantId;
        agencyAnnualPaymentLink = "https://buy.stripe.com/test_4gw03Ughf0VP9JC28b?client_reference_id=" + tenantId;
        } else {
        starterMonthlyPaymentLink = "https://buy.stripe.com/fZe3g45Dycku9ag8wy?client_reference_id=" + tenantId;
        professionalMonthlyPaymentLink = "https://buy.stripe.com/dR63g43vqdoyeuA8wz?client_reference_id=" + tenantId;
        agencyMonthlyPaymentLink = "https://buy.stripe.com/9AQ4k84zu1FQ0DK148?client_reference_id=" + tenantId;
        starterAnnualPaymentLink = "https://buy.stripe.com/3csbMAaXSacm3PW9AF?client_reference_id=" + tenantId;
        professionalAnnualPaymentLink = "https://buy.stripe.com/dR6bMA6HCdoy2LS007?client_reference_id=" + tenantId;
        agencyAnnualPaymentLink = "https://buy.stripe.com/eVa2c03vqfwG0DKdQW?client_reference_id=" + tenantId;
        }

        if (pricingOption === "monthly") {
          return [starterMonthlyPaymentLink, professionalMonthlyPaymentLink, agencyMonthlyPaymentLink];
        } else {
          return [starterAnnualPaymentLink, professionalAnnualPaymentLink, agencyAnnualPaymentLink];
        }
  };

  return (
    <>
    <SoftBox>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            badge={{ color: "secondary", label: "starter" }}
            price={{ currency: "$", value: starter }}
            specifications={[
              { label: "Content Inspiration", includes: true },
              { label: "AI Assisted Writing", includes: true },
              { label: "AI Content Improvements", includes: true },
              { label: "Post Commenting", includes: true },
              { label: "Content Repurposing", includes: true },
              { label: "Generate Post Ideas", includes: true },
              { label: "Generate Posts", includes: true },
              { label: "Post Styling and Preview", includes: true },
              { label: "Post Scheduling", includes: true },
              { label: "Detailed Post Analytics", includes: false },
              { label: "Engage and Grow", includes: false },
              { label: "Connect and Manage Organisation Pages", includes: false },
              { label: "Organisation Post Targetting", includes: false },
              { label: "Connect upto 10 LinkedIn Accounts", includes: false },
              { label: "Support for 10 Workspaces", includes: false },
              { label: "Invite upto 10 Team Members or Clients", includes: false },
              { label: "Priority Support", includes: false },
            ]}
            action={{
              type: "external",
              route: getPaymentLink(activePricingOption)[0],
              color: "dark",
              label: "upgrade",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            badge={{ color: "secondary", label: "professional" }}
            price={{ currency: "$", value: professional }}
            specifications={[
              { label: "Content Inspiration", includes: true },
              { label: "AI Assisted Writing", includes: true },
              { label: "AI Content Improvements", includes: true },
              { label: "Post Commenting", includes: true },
              { label: "Content Repurposing", includes: true },
              { label: "Generate Post Ideas", includes: true },
              { label: "Generate Posts", includes: true },
              { label: "Post Styling and Preview", includes: true },
              { label: "Post Scheduling", includes: true },
              { label: "Detailed Post Analytics", includes: true },
              { label: "Engage and Grow", includes: true },
              { label: "Connect and Manage Organisation Pages", includes: true },
              { label: "Organisation Post Targetting", includes: false },
              { label: "Connect upto 10 LinkedIn Accounts", includes: false },
              { label: "Support for 10 Workspaces", includes: false },
              { label: "Invite upto 10 Team Members or Clients", includes: false },
              { label: "Priority Support", includes: false },
            ]}
            action={{
              type: "external",
              route: getPaymentLink(activePricingOption)[1],
              color: "primary",
              label: "upgrade",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            badge={{ color: "secondary", label: "agency" }}
            price={{ currency: "$", value: agency }}
            specifications={[
              { label: "Content Inspiration", includes: true },
              { label: "AI Assisted Writing", includes: true },
              { label: "AI Content Improvements", includes: true },
              { label: "Post Commenting", includes: true },
              { label: "Content Repurposing", includes: true },
              { label: "Generate Post Ideas", includes: true },
              { label: "Generate Posts", includes: true },
              { label: "Post Styling and Preview", includes: true },
              { label: "Post Scheduling", includes: true },
              { label: "Detailed Post Analytics", includes: true },
              { label: "Engage and Grow", includes: true },
              { label: "Connect and Manage Organisation Pages", includes: true },
              { label: "Organisation Post Targetting", includes: true },
              { label: "Connect upto 10 LinkedIn Accounts", includes: true },
              { label: "Support for 10 Workspaces", includes: true },
              { label: "Invite upto 10 Team Members or Clients", includes: true },
              { label: "Priority Support", includes: true },
            ]}
            action={{
              type: "external",
              route: getPaymentLink(activePricingOption)[2],
              color: "dark",
              label: "upgrade",
            }}
          />
        </Grid>
      </Grid>
    </SoftBox>
    </>
  );
}

// Typechecking props for the PricingCards
PricingCards.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.string).isRequired,
  activePricingOption: PropTypes.string.isRequired,
  tenantId: PropTypes.string.isRequired,
};

export default PricingCards;
