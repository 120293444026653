import React, { useEffect, useState, useContext } from 'react';

// Import Soft UI Dashboard React components
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import Table from 'examples/Tables/Table';
import SoftBox from 'components/SoftBox';
import SoftSnackbar from 'components/SoftSnackbar';

// Import Material UI components
import { Grid, Select, MenuItem, Tooltip } from '@mui/material';

// Import Firebase Context
import FirebaseContext from "configs/firebasecontext";
import { httpsCallable } from "firebase/functions";

// Import User Context
import { useUserData } from "context/userManagement";
import { getUserOrganizations } from "components/DatabaseFunctions";

// Import Upgrade Plan Modal
import UpgradePlanModal from "components/SharedFunctions/upgradePlanModal";

function ConnectedCompanies() {
  const { functions, db } = useContext(FirebaseContext);
  const { userId, currentWorkspace, setCurrentWorkspace, currentUserData } = useUserData();
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [connectingCompany, setConnectingCompany] = useState(false);

  // Variables for Upgrade Plan Modal
  const [openUpgradePlanModal, setOpenUpgradePlanModal] = useState(false);
  const userPermissions = currentUserData?.tenantData[0]?.subscriptionData[0]?.planAccess || {};

  // Console log current workspace
    console.log("Current Workspace on Organizations Page: ", currentWorkspace);

  async function fetchOrganizations() {
    if (!selectedAccount) {
      setSnackbarMessage("Please select a LinkedIn account first!");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      setTimeout(() => setSnackbarOpen(false), 3000);
      return;
    }

    const workspaceId = currentWorkspace.id;
    const getOrganizations = httpsCallable(functions, process.env.REACT_APP_CLOUD_FUNCTION_GETORGS);

    try {
      setLoading(true);
      const result = await getOrganizations({ workspaceId, linkedInId: selectedAccount });
      console.log("Organizations: ", result.data);

      if (result.data.organizations) {
        // Maintain the connected state of organizations
        const updatedOrganizations = result.data.organizations.map(org => ({
          ...org,
          isConnected: isOrganizationConnected(org.organizationURN)
        }));
        setOrganizations(updatedOrganizations);
      } else {
        setOrganizations([]);
      }
      setLoading(false);
      setSnackbarMessage("Organizations refreshed!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setTimeout(() => setSnackbarOpen(false), 3000);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching organizations:', error);
      setSnackbarMessage("Error fetching organizations!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setTimeout(() => setSnackbarOpen(false), 3000);
    }
  }

  async function handleConnectOrganization(org) {
    // Prevent multiple clicks
    if (connectingCompany) {
      return;
    }

    // Check if the user has permission to connect more organizations
    if (!userPermissions?.organisationManagement) {
      setOpenUpgradePlanModal(true);
      return;
    }

    setConnectingCompany(true);

    try {
      // Console Log for Debug
      // console.log("Connecting Organization: ", org);
      // console.log("Current Workspacce ID: ", currentWorkspace.id);
      // console.log("Organization URN: ", org.organizationURN);

      const workspaceId = currentWorkspace.id;
      const organizationURN = org.organizationURN || org.URN;

      console.log("Connecting Organization URN: ", organizationURN);
      console.log("Current Workspace ID: ", workspaceId);

      if (!organizationURN) {
        throw new Error("Invalid organization data: Missing URN");
      }

      const updateWorkspace = httpsCallable(functions, process.env.REACT_APP_CLOUD_FUNCTION_CONNECTORGANIZATION);
      const result = await updateWorkspace({ workspaceId, organizationURN: org.organizationURN });

      if (result.data.success) {
        setSnackbarMessage("Organization connected successfully!");
        setSnackbarSeverity("success");

        // Update the current workspace state
        setCurrentWorkspace(prevWorkspace => ({
          ...prevWorkspace,
          connectedOrganizations: [...(prevWorkspace.connectedOrganizations || []), org.organizationURN || org.URN]
        }));

        // Update the workspace state in local storage
        localStorage.setItem("currentWorkspace", JSON.stringify({
          ...currentWorkspace,
          connectedOrganizations: [...(currentWorkspace.connectedOrganizations || []), org.organizationURN]
        }));
        
      } else {
        throw new Error(result.data.message);
      }
    } catch (error) {
      console.error('Error connecting organization:', error);
      setSnackbarMessage("Error connecting organization! Please try again.");
      setSnackbarSeverity("error");
    }
    setSnackbarOpen(true);
    setTimeout(() => setSnackbarOpen(false), 3000);
    setConnectingCompany(false);
  }

  useEffect(() => {
    getUserOrganizations(db, userId, currentWorkspace.id).then((data) => {
      if (data) {
        setOrganizations(data);
        console.log("Organizations: ", data);
      } else {
        console.log("No organizations found!")
      }
    });
  }, [userId]);

  const renderProfileOption = (option) => (
    <MenuItem key={option.value} value={option.value}>
      <img src={option.image} alt={option.label} style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
      {option.label}
    </MenuItem>
  );

  const isOrganizationConnected = (urn) => {
    return currentWorkspace.connectedOrganizations?.some(connectedUrn => 
      connectedUrn === urn
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SoftTypography variant="h4" className="mb-3">Company Page Accounts</SoftTypography>
        </Grid>
        <Grid item xs={12}>
          <SoftTypography variant="h6" fontWeight="bold" mb={2}>
            Connected LinkedIn Accounts
          </SoftTypography>
          <SoftBox>
            <Select
              size="small"
              displayEmpty
              value={selectedAccount}
              onChange={(e) => setSelectedAccount(e.target.value)}
              renderValue={(selected) => {
                if (!selected) {
                  return <SoftTypography variant="body2">Select LinkedIn Account</SoftTypography>;
                }
                const account = currentWorkspace.linkedInAccounts[selected];
                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={account.profilePic} alt={account.name} style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
                    {account.name}
                  </div>
                );
              }}
            >
              {currentWorkspace?.linkedInAccounts && Object.values(currentWorkspace.linkedInAccounts).map((account) => (
                renderProfileOption({ value: account.id, label: account.name, image: account.profilePic })
              ))}
            </Select>
          </SoftBox>
        </Grid>
        <Grid item xs={12}>
          <SoftButton onClick={fetchOrganizations} size="small" color="dark" variant="contained">
            {loading ? "Loading..." : "Refresh Companies"}
          </SoftButton>
        </Grid>
        <Grid item xs={12}>
          <SoftBox mb={2}>
            <SoftTypography variant="h6" className="mb-3">Your Companies</SoftTypography>
          </SoftBox>
          <SoftBox mb={2}>
            <Table
              columns={[
                { name: "Company Name", align: "center" },
                { name: "Role", align: "center" },
                { name: "Status", align: "center" },
                { name: "Actions", align: "center" }
              ]}
              rows={organizations
                .filter((org) => org.role === "ADMINISTRATOR" || org.role === "DIRECT_MEMBER" && org.state === "APPROVED")
                .map((org) => ({
                "Company Name": org.name,
                Role: org.role || "Unknown",
                Status: org.state || "Unknown",
                Actions: (() => {
                  // Check if org.organizationURN or org.URN exists, default to an empty string if neither is present
                  const urn = org.organizationURN || org.URN || '';
                  
                  const isBrandShowcase = urn.toLowerCase().includes('organizationbrand');
                  const isConnected = isOrganizationConnected(urn);
                
                  if (isBrandShowcase) {
                    return (
                      <Tooltip title="Brand Showcase Pages can't be connected to PropelPosts, only Company Pages">
                        <span>
                          <SoftButton 
                            size="small" 
                            color="dark" 
                            variant="contained" 
                            disabled
                            style={{ opacity: 0.5 }}
                          >
                            Connect
                          </SoftButton>
                        </span>
                      </Tooltip>
                    );
                  }
                
                  if (isConnected) {
                    return (
                      <SoftButton size="small" color="dark" variant="contained">
                        Connected
                      </SoftButton>
                    );
                  }
                
                  return (
                    <SoftButton 
                      onClick={() => handleConnectOrganization(org)} 
                      size="small" 
                      color="primary" 
                      variant="contained"
                    >
                      {connectingCompany ? "Connecting..." : "Connect"}
                    </SoftButton>
                  );
                })()
              }))}
            />
          </SoftBox>
        </Grid>
      </Grid>

      <SoftSnackbar
        open={snackbarOpen}
        close={() => setSnackbarOpen(false)}
        dateTime={new Date().toLocaleString()}
        content={snackbarMessage}
        color={snackbarSeverity}
        icon="notifications"
        title="Notification"
      />

      <UpgradePlanModal open={openUpgradePlanModal} onClose={() => setOpenUpgradePlanModal(false)} />
    </>
  );
}

export default ConnectedCompanies;
