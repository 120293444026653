export function getNextAvailableSlot(customSchedule) {
    const currentDateTime = new Date();
    const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  
    customSchedule.sort((a, b) => {
      const dayA = weekDays.indexOf(a.day);
      const dayB = weekDays.indexOf(b.day);
      if (dayA !== dayB) return dayA - dayB;
      return a.times[0].localeCompare(b.times[0]);
    });
  
    for (const schedule of customSchedule) {
      const scheduleDay = weekDays.indexOf(schedule.day);
      const currentDay = currentDateTime.getDay();
  
      for (const time of schedule.times) {
        const [hours, minutes] = time.split(":").map(Number);
        const scheduleDateTime = new Date(currentDateTime);
        scheduleDateTime.setHours(hours, minutes, 0, 0);
        scheduleDateTime.setDate(currentDateTime.getDate() + (scheduleDay + 7 - currentDay) % 7);
  
        if (scheduleDateTime > currentDateTime) {
          return scheduleDateTime;
        }
      }
    }
  
    const [firstSchedule] = customSchedule;
    const [firstTime] = firstSchedule.times;
    const [hours, minutes] = firstTime.split(":").map(Number);
    const nextAvailableDateTime = new Date(currentDateTime);
    nextAvailableDateTime.setHours(hours, minutes, 0, 0);
    nextAvailableDateTime.setDate(currentDateTime.getDate() + (weekDays.indexOf(firstSchedule.day) + 7 - currentDay) % 7 + 7);
  
    return nextAvailableDateTime;
  }

  //Function for long form to schedule series of posts
  export const getNextAvailableTimeSlot = (lastScheduledDate, customSchedule) => {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let currentDate = lastScheduledDate ? new Date(lastScheduledDate) : new Date();
    currentDate.setMinutes(currentDate.getMinutes() + 1); // Start checking from the next minute
  
    while (true) {
      const dayName = daysOfWeek[currentDate.getDay()];
      if (customSchedule.selectedDays[dayName]) {
        const dayEvents = customSchedule.events.find(event => event.day === dayName);
        if (dayEvents) {
          for (let time of dayEvents.times) {
            const [hours, minutes] = time.split(":").map(Number);
            const potentialDate = new Date(currentDate);
            potentialDate.setHours(hours, minutes, 0, 0);
            if (potentialDate > currentDate) {
              return potentialDate;
            }
          }
        }
      }
      currentDate.setHours(24, 0, 0, 0); // Move to the start of the next day
    }
  };
  