import { useState, useContext, useEffect } from "react";
import propTypes from "prop-types";
import { useNavigate, Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import { Menu, MenuItem } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Wizard page components
import About from "layouts/applications/wizard/components/About";
import ObjectivesAndAudience from "layouts/applications/wizard/components/ObjectivesAndAudience";
import FormatStyling from "layouts/applications/wizard/components/FormatStyling";
import ExamplePosts from "layouts/applications/wizard/components/ExamplePosts";

// Context Imports
import { useUserData } from "context/userManagement";
import FirebaseContext from "configs/firebasecontext";

// Import database query functions
import { saveAiProfile, fetchAiProfile, deleteAiProfile, getUserOrganizations } from "components/DatabaseFunctions";

// Import Firebase functions
import { getFunctions, httpsCallable } from "firebase/functions";

// Import Upgrade Plan Modal
import UpgradePlanModal from "components/SharedFunctions/upgradePlanModal";

function getSteps() {
  return ["Background", "Objectives and Audience", "Formatting and Styling", "Example Posts"];
}

function getStepContent(stepIndex, onAboutChange, onObjectivesAndAudienceChange, onFormatStylingChange, onExampleChange) {
  switch (stepIndex) {
    case 0:
      return <About onAboutChange={onAboutChange} />;
    case 1:
      return <ObjectivesAndAudience onObjectivesAndAudienceChange={onObjectivesAndAudienceChange} />;
    case 2:
      return <FormatStyling onFormatStylingChange={onFormatStylingChange} />;
    case 3:
      return <ExamplePosts onExampleChange={onExampleChange} />;
    default:
      return null;
  }
}

function PersonaWizard() {
  const [aboutData, setAboutData] = useState({});
  const [objectivesAndAudienceData, setObjectivesAndAudienceData] = useState({});
  const [formatStylingData, setFormatStylingData] = useState({});
  const [examplePostsData, setExamplePostsData] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;
  const [isLoading, setIsLoading] = useState(false);
  const [AIResponse, setAIResponse] = useState("");
  const [aiModal, setAiModal] = useState(false);
  const { userId, userData, currentUserData, currentWorkspace } = useUserData();

  // State for Handling Selecting the LinkedIn Profile for AI Profile
  const [ selectedLInkedInProfile, setSelectedLinkedInProfile ] = useState(null);
  const [ profilePic, setProfilePic ] = useState("");
  const [ name, setName ] = useState("");
  const [ organizations, setOrganizations ] = useState([]);
  const [ postingProfileOptions, setPostingProfileOptions ] = useState([]);

    // Variables for Upgrade Plan Modal
    const [openUpgradePlanModal, setOpenUpgradePlanModal] = useState(false);
    const userPermissions = currentUserData?.tenantData[0]?.subscriptionData[0]?.planAccess || {};

  // Navigate to Dashboard
  const navigate = useNavigate();

  // Firebase Context
  const { db } = useContext(FirebaseContext);

  // AI Profile Restored from Database
  const [aiProfile, setAiProfile] = useState(null);

  const functions = getFunctions();
  const generateAIProfile = httpsCallable(functions, process.env.REACT_APP_CLOUD_FUNCTIONS_OPENAI_AIPROFILE);

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  // Check if the user has permissions to access the AI Profile Builder
  useEffect(() => {
    if (!userPermissions?.aiProfile) {
      setOpenUpgradePlanModal(true);
    }
  }, []);

  // Get the user's organizations and LinkedIn accounts
  useEffect(() => {
    if (!userId || !db || !currentWorkspace) {
      return;
    }
  
    const fetchData = async () => {
      const data = await getUserOrganizations(db, userId, currentWorkspace.id);
      const linkedInAccounts = currentWorkspace?.linkedInAccounts || {};
  
      const linkedInAccountsOptions = Object.values(linkedInAccounts).map((account) => ({
        value: account.profileURN,
        label: account.name,
        image: account.profilePic || "",
        type: "account", // to distinguish between accounts and organizations
      }));
  
      let connectedOrgsOptions = [];
      if (data) {
        const filteredData = data.filter((org) => currentWorkspace.connectedOrganizations.includes(org.organizationURN));
        setOrganizations(filteredData);

        console.log("Filtered Data:", filteredData);
  
        connectedOrgsOptions = filteredData.map((org) => ({
          value: org.organizationURN,
          label: org.name,
          image: org.logoUrl || "",
          type: "organization", // to distinguish between accounts and organizations
        }));
      }
  
      const combinedOptions = [...linkedInAccountsOptions, ...connectedOrgsOptions];
      setPostingProfileOptions(combinedOptions);
  
      if (combinedOptions.length > 0) {
        setSelectedLinkedInProfile(combinedOptions[0].value);
      }
    };
  
    fetchData();
  }, [userId, db, currentWorkspace]);

  // Get AI Profile from Database on Change of Selected Profile
  useEffect(() => {
    if (!db || !selectedLInkedInProfile) {
      return;
    }

    const fetchAiProfileData = async () => {
      const aiProfileData = await fetchAiProfile(db, currentWorkspace.id, selectedLInkedInProfile);
      setAiProfile(aiProfileData);
    };
    
    fetchAiProfileData();

  }, [db, selectedLInkedInProfile]);

  const handleAboutChange = (data) => {
    setAboutData(data);
  };

  const handleObjectivesAndAudienceChange = (data) => {
    setObjectivesAndAudienceData(data);
  };

  const handleFormatStylingChange = (data) => {
    setFormatStylingData(data);
  };

  const handleExamplePostsChange = (data) => {
    setExamplePostsData(data);
  };

  const handleAIProfileBuilder = async (compiledData) => {
    // Check if the user has permissions to access the AI Profile Builder
    if (!userPermissions?.aiProfile) {
      setOpenUpgradePlanModal(true);
      return;
    }

    try {
      console.log("Calling Cloud Function...");
      setIsLoading(true);
      setAiModal(true);
      
      const response = await generateAIProfile(compiledData);
      const aiResponseText = response.data.trim();

      setAIResponse(aiResponseText);
      console.log("AI Response:", aiResponseText);

      // Save generated AI profile to Firestore
      await saveAiProfile(db, aiResponseText, currentWorkspace.id, selectedLInkedInProfile);

    } catch (error) {
      console.error("Error generating AI profile:", error);
    } finally {
      setIsLoading(false);
      setAiModal(false);
    }
  };

  const handleSend = () => {
    const compiledData = {
      aboutData,
      objectivesAndAudienceData,
      formatStylingData,
      examplePostsData,
    };
    handleAIProfileBuilder(compiledData);
  };

  // Delete AI Profile
  const handleDeleteProfile = async () => {
    try {
      await deleteAiProfile(db, currentWorkspace.id, selectedLInkedInProfile);
      setAiProfile(null);
    } catch (error) {
      console.error("Error deleting AI profile:", error);
    }
  };

  const handlePostingProfileChange = (event) => {
    const selectedProfile = postingProfileOptions.find(profile => profile.value === event.target.value);
  
    setSelectedLinkedInProfile(selectedProfile?.value || "");
    setProfilePic(selectedProfile?.image || "");
    setName(selectedProfile?.label || "");

    // Console Log the selected profile
    console.log("Selected Profile:", selectedProfile);
  };   
  
  const renderProfileOption = (option) => (
    <MenuItem key={option.value} value={option.value}>
      <img src={option.image} alt={option.label} style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
      {option.label}
    </MenuItem>
  ); 

  console.log("AI Profile:", aiProfile);

  // Console Log the User Inputs
  console.log("About Data:", aboutData);
  console.log("Objectives and Audience Data:", objectivesAndAudienceData);
  console.log("Format and Styling Data:", formatStylingData);
  console.log("Example Posts Data:", examplePostsData);

  return (
    <>
      {/* <DashboardLayout> */}
        {/* <DashboardNavbar /> */}
        <SoftBox pt={3} pb={8}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={8}>
              <SoftBox mb={1} textAlign="center">
                <SoftBox mb={1}>
                  <SoftTypography variant="h3" fontWeight="bold">
                    Build Your AI Persona
                  </SoftTypography>
                </SoftBox>
                <SoftTypography variant="h6" fontWeight="regular" color="secondary">
                  This information will let us know more about you so the PropelPosts AI can generate and improve posts while maintaining your tone of voice and style.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={3} mt={2}>
                <SoftBox display="flex" justifyContent="center" alignItems="center" mb={2}>
                  <SoftTypography variant="h6" fontWeight="bold" color="dark" textAlign="center">
                    Select LinkedIn Profile to Generate AI Profile
                  </SoftTypography>
                </SoftBox>
              {postingProfileOptions.length > 0 && (
                <SoftBox display="flex" justifyContent="center" alignItems="center">
                  <Select
                    size="small"
                    displayEmpty
                    value={selectedLInkedInProfile}
                    onChange={handlePostingProfileChange}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>Select Profile</em>;
                      }

                      const selectedOption = postingProfileOptions.find(option => option.value === selected);
                      return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={selectedOption?.image || ""} alt="Profile Picture" style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
                          {selectedOption?.label || ""}
                        </div>
                      );
                    }}
                  >
                    {postingProfileOptions.map(renderProfileOption)}
                  </Select>
                </SoftBox>
              )}
            </SoftBox>

              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              {/* Display the AI warning if it exists */}
              {aiProfile && (
                <SoftBox mt={3} mb={3}>
                  <Grid container direction={"column"} spacing={2} alignItems={"center"}>
                    <Grid item xs={12}>
                      <SoftTypography mb={2} variant="h5" fontWeight="medium" color="secondary" textAlign="center">
                        Your AI profile has already been generated. You can use the profile while using the AI to generate or improve your posts.
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <SoftTypography mb={2} variant="body2" fontWeight="regular" color="text" textAlign="center">
                        If you want to generate a new AI profile, you will need to delete your current profile first.
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <SoftButton variant="contained" color="error" onClick={() => handleDeleteProfile()}>
                        Delete AI Profile
                      </SoftButton>
                    </Grid>
                    <Grid item xs={6}>
                      <Link to="/dashboards/default">
                        <SoftButton variant="contained" color="info">
                          Go to dashboard
                        </SoftButton>
                      </Link>
                    </Grid>
                  </Grid>
                </SoftBox>
              )}

              {/* Display the wizard if no AI profile exists */}
              {!aiProfile && (
                <Card>
                  <SoftBox p={2}>
                    <SoftBox>
                      {getStepContent(activeStep, handleAboutChange, handleObjectivesAndAudienceChange, handleFormatStylingChange, handleExamplePostsChange)}
                      <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
                        {activeStep === 0 ? (
                          <SoftBox />
                        ) : (
                          <SoftButton variant="gradient" color="light" onClick={handleBack}>
                            back
                          </SoftButton>
                        )}
                        <SoftButton
                          variant="gradient"
                          color="dark"
                          onClick={!isLastStep ? handleNext : handleSend}
                        >
                          {isLastStep ? "generate profile" : "next"}
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Card>
              )}
            </Grid>
          </Grid>
        </SoftBox>
        {/* <Footer /> */}
      {/* </DashboardLayout> */}

      <Modal
        open={aiModal}
        onClose={() => setAiModal(false)}
        aria-labelledby="ai-modal"
        aria-describedby="ai-modal-description"
      >
        <SoftBox
          bgColor="white"
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            padding: '20px',
            borderRadius: '8px',
            width: '600px',
          }}
        >
          <SoftTypography color="primary" fontWeight="medium" textAlign="center">Generating your AI profile...</SoftTypography>
          <img src="https://firebasestorage.googleapis.com/v0/b/contentbuddy-ai.appspot.com/o/application%2Fimages%2Fwork_success_generated.jpg?alt=media&token=05aa126b-8436-414c-b8f3-52fd2a78b6a5" alt="Loading" style={{ width: '200px', height: '200px', margin: 'auto', display: 'block' }} />
        </SoftBox>
      </Modal>

      <Modal
        open={AIResponse !== ""}
        onClose={() => setAiModal(false)}
        aria-labelledby="ai-modal"
        aria-describedby="ai-modal-description"
      >
        <SoftBox
          bgColor="white"
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            padding: '20px',
            borderRadius: '8px',
            width: '600px',
          }}
        >
          <SoftTypography color="dark" fontWeight="medium" textAlign="center" mb={2}>Your AI profile has been generated</SoftTypography>
          <SoftTypography color="dark" fontWeight="regular" textAlign="center" mb={2}>You can now use this profile when generating or improving your posts. Try it out!</SoftTypography>
          <SoftBox style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Link to="/dashboards/default">
              <SoftButton variant="contained" color="info">
                Go to dashboard
              </SoftButton>
            </Link>
          </SoftBox>
        </SoftBox>
      </Modal>

      <UpgradePlanModal open={openUpgradePlanModal} onClose={() => setOpenUpgradePlanModal(false)} />
    </>
  );
}

export default PersonaWizard;
