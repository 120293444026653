import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  Button,
  Card,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { doc, setDoc, deleteDoc } from "firebase/firestore";
import FirebaseContext from "configs/firebasecontext";
import { useUserData } from "context/userManagement";
import SoftDatePicker from "components/SoftDatePicker";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftSnackbar from "components/SoftSnackbar";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { getExistingSchedule, getUserOrganizations, saveCustomSchedule, deleteCustomSchedule } from "components/DatabaseFunctions";

// Import Icons
import InfoIcon from '@mui/icons-material/Info';
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

function CustomSchedule() {
    const navigate = useNavigate();
    const { userId, userData, currentWorkspace } = useUserData();
    const { db } = useContext(FirebaseContext);

    const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const dayAbbreviations = { "Monday": "Mon", "Tuesday": "Tue", "Wednesday": "Wed", "Thursday": "Thu", "Friday": "Fri", "Saturday": "Sat", "Sunday": "Sun" };

    const initialSelectedDays = {
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
        Sun: false
    };

    const [selectedDays, setSelectedDays] = useState(initialSelectedDays);
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const [numOfPosts, setNumOfPosts] = useState(1);
    const [events, setEvents] = useState([]);
    const [saveStatus, setSaveStatus] = useState(null); // 'success', 'error', or null
    const [saveMessage, setSaveMessage] = useState(""); // For displaying a message in the snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false); // For opening the snackbar
    const [existingScheduleId, setExistingScheduleId] = useState(null);  // New state variable
    const [reload, setReload] = useState(false);  // New state variable

    // State for handling LinkedIn profile selection
    const [selectedLinkedInProfile, setSelectedLinkedInProfile] = useState(null);
    const [profilePic, setProfilePic] = useState("");
    const [name, setName] = useState("");
    const [organizations, setOrganizations] = useState([]);
    const [postingProfileOptions, setPostingProfileOptions] = useState([]);

    // Get the user's organizations and LinkedIn accounts
    useEffect(() => {
        if (!userId || !db || !currentWorkspace) {
            return;
        }

        const fetchData = async () => {
            const data = await getUserOrganizations(db, userId, currentWorkspace.id);
            const linkedInAccounts = currentWorkspace?.linkedInAccounts || {};

            const linkedInAccountsOptions = Object.values(linkedInAccounts).map((account) => ({
                value: account.profileURN,
                label: account.name,
                image: account.profilePic || "",
                type: "account", // to distinguish between accounts and organizations
            }));

            let connectedOrgsOptions = [];
            if (data) {
                const filteredData = data.filter((org) => currentWorkspace.connectedOrganizations.includes(org.organizationURN));
                setOrganizations(filteredData);

                connectedOrgsOptions = filteredData.map((org) => ({
                    value: org.organizationURN,
                    label: org.name,
                    image: org.logoUrl || "",
                    type: "organization", // to distinguish between accounts and organizations
                }));
            }

            const combinedOptions = [...linkedInAccountsOptions, ...connectedOrgsOptions];
            setPostingProfileOptions(combinedOptions);

            if (combinedOptions.length > 0) {
                setSelectedLinkedInProfile(combinedOptions[0].value);
            }
        };

        fetchData();
    }, [userId, db, currentWorkspace]);

    const loadExistingSchedule = async () => {
        if (!currentWorkspace || !selectedLinkedInProfile) return;

        const schedule = await getExistingSchedule(db, currentWorkspace.id, selectedLinkedInProfile);  // Adjusted function to include workspace and LinkedIn profile ID
        console.log("Existing schedule", schedule)
        if (schedule) {
            setExistingScheduleId(schedule.id);
            setSelectedDays(schedule.selectedDays);
            setEvents(schedule.events);
            setNumOfPosts(schedule.numOfPosts);
        }
    };

    useEffect(() => {
        loadExistingSchedule();
    }, [db, userId, currentWorkspace, selectedLinkedInProfile, reload]);

    const randomizePosts = () => {
        let newEvents = [...events]; // Start with the current events

        daysOfWeek.forEach(day => {
            if (selectedDays[dayAbbreviations[day]]) {
                const dayPosts = [];
                for (let i = 0; i < numOfPosts; i++) {
                    const eventTime = new Date(startTime.getTime() + Math.random() * (endTime.getTime() - startTime.getTime()));
                    // Round minutes to the nearest 15
                    const minutes = eventTime.getMinutes();
                    const roundedMinutes = Math.round(minutes / 15) * 15;
                    eventTime.setMinutes(roundedMinutes);
                    dayPosts.push(eventTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })); // Format time to HH:MM
                }

                const dayIndex = newEvents.findIndex(e => e.day === dayAbbreviations[day]);
                if (dayIndex !== -1) {
                    newEvents[dayIndex].times = dayPosts; // Overwrite existing post times for the day
                } else {
                    newEvents.push({
                        day: dayAbbreviations[day],
                        times: dayPosts
                    });
                }
            }
        });

        setEvents(newEvents);
        console.log("New events", newEvents);
    };

    const handleDeleteSchedule = async () => {
        if (existingScheduleId) {
            try {
                await deleteCustomSchedule(db, currentWorkspace.id, selectedLinkedInProfile);  // Adjusted function to include workspace and LinkedIn profile ID

                setExistingScheduleId(null);
                setSelectedDays(initialSelectedDays);
                setEvents([]);
                setNumOfPosts(1);
                setSaveStatus('success');
                setSaveMessage('Schedule deleted successfully!');
                setOpenSnackbar(true);
            } catch (e) {
                console.error("Error deleting document: ", e);
                setSaveStatus('error');
                setSaveMessage('Error deleting schedule. Please try again later.');
                setOpenSnackbar(true);
            } finally {
                // Set a timeout to clear the save status after 3 seconds
                setTimeout(() => {
                    setOpenSnackbar(false);
                }, 3000);
            }
        }
    };

    const handleSaveSchedule = async () => {
        try {
            if (!currentWorkspace || !selectedLinkedInProfile) return;

            let scheduleId = existingScheduleId;

            if (!scheduleId) {
                // Generate a unique schedule ID (using current timestamp for simplicity)
                scheduleId = `schedule_${Date.now()}`;
            }

            const schedule = {
                selectedDays,
                events,
                numOfPosts,
                id: scheduleId
            };

            await saveCustomSchedule(db, schedule, currentWorkspace.id, selectedLinkedInProfile);  // Adjusted function to include workspace and LinkedIn profile ID

            setSaveStatus('success');
            setSaveMessage('Schedule saved successfully!');
            setOpenSnackbar(true);
            setReload(!reload);

            // Set a timeout to clear the save status after 3 seconds
            setTimeout(() => {
                setOpenSnackbar(false);
            }, 3000);
        } catch (e) {
            console.error("Error adding document: ", e);
            setSaveStatus('error');
            setSaveMessage('Error saving schedule. Please try again later.');
            setOpenSnackbar(true);

            // Set a timeout to clear the save status after 3 seconds
            setTimeout(() => {
                setOpenSnackbar(false);
            }, 3000);
        }
    };

    const handlePostingProfileChange = (event) => {
        const selectedProfile = postingProfileOptions.find(profile => profile.value === event.target.value);

        setSelectedLinkedInProfile(selectedProfile?.value || "");
        setProfilePic(selectedProfile?.image || "");
        setName(selectedProfile?.label || "");
    };

    const renderProfileOption = (option) => (
        <MenuItem key={option.value} value={option.value}>
            <img src={option.image} alt={option.label} style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
            {option.label}
        </MenuItem>
    );

    console.log("Signed in user on Custom Schedule", userId)

    return (
        <>
            <SoftSnackbar
                open={openSnackbar}
                close={() => setOpenSnackbar(false)}
                content={saveMessage}
                icon="notifications"
                color={saveStatus === 'success' ? 'success' : 'error'}
                dateTime={new Date().toLocaleString()}
                title="Custom Schedule"
            />

            <DashboardNavbar />
            <DashboardLayout>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SoftBox>
                            <SoftTypography variant="h4" fontWeight="bold">
                                Custom Posting Schedule
                                <Tooltip
                                    title="Create a custom posting schedule for your LinkedIn posts"
                                    placement="right-start"
                                >
                                    <IconButton size="small">
                                        <InfoIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </SoftTypography>
                            <Divider />
                        </SoftBox>
                    </Grid>

                {/* LinkedIn Profile Selector */}
                    <Grid item xs={12}>
                        <SoftBox mb={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Card
                                style={{ width: '70%', padding: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                            >
                                <SoftTypography variant="body2" fontWeight="light" color="dark" textAlign="center">
                                    Select a LinkedIn profile to generate post ideas.
                                </SoftTypography>
                                <SoftBox mt={2} width="70%">
                                    <Select
                                        size="small"
                                        displayEmpty
                                        value={selectedLinkedInProfile}
                                        onChange={handlePostingProfileChange}
                                        renderValue={(selected) => {
                                            if (!selected) {
                                                return <em>Select Profile</em>;
                                            }

                                            const selectedOption = postingProfileOptions.find(option => option.value === selected);
                                            return (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img src={selectedOption?.image || ""} alt="Profile Picture" style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
                                                    {selectedOption?.label || ""}
                                                </div>
                                            );
                                        }}
                                    >
                                        {postingProfileOptions.map(renderProfileOption)}
                                    </Select>
                                </SoftBox>
                            </Card>
                        </SoftBox>
                    </Grid>
                </Grid>

                <Grid container spacing={2} justifyContent="center">
                    {daysOfWeek.map(day => (
                        <Grid item key={day}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedDays[dayAbbreviations[day]]}
                                        onChange={(e) => setSelectedDays(prev => ({ ...prev, [dayAbbreviations[day]]: e.target.checked }))}
                                        name={dayAbbreviations[day]}
                                    />
                                }
                                label={dayAbbreviations[day]}
                            />
                        </Grid>
                    ))}
                </Grid>

                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={2} container justifyContent="center">
                        <SoftTypography variant="caption" color="dark">
                            Earliest Time To Post
                        </SoftTypography>
                        <SoftDatePicker
                            input={{ placeholder: "Start Time" }}
                            options={{ enableTime: true, noCalendar: true }}
                            onChange={(time) => setStartTime(time[0])}
                        />
                    </Grid>

                    <Grid item xs={2} container justifyContent="center">
                        <SoftTypography variant="caption" color="dark">
                            Last Time To Post
                        </SoftTypography>
                        <SoftDatePicker
                            input={{ placeholder: "End Time" }}
                            options={{ enableTime: true, noCalendar: true }}
                            onChange={(time) => setEndTime(time[0])}
                        />
                    </Grid>

                    <Grid item xs={2} container justifyContent="center">
                        <FormControl>
                            <SoftTypography variant="caption" color="dark">
                                Posts per Day
                            </SoftTypography>
                            <Select
                                value={numOfPosts}
                                onChange={(e) => setNumOfPosts(e.target.value)}
                            >
                                {[...Array(10).keys()].map(i => (
                                    <MenuItem value={i + 1} key={i + 1}>
                                        {i + 1}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} container justifyContent="center">
                        <SoftButton variant="contained" color="dark" onClick={randomizePosts} disabled={!!existingScheduleId}>
                            Generate Schedule
                        </SoftButton>
                    </Grid>

                    {!existingScheduleId && events.length > 0 && (
                        <Grid item xs={12} container justifyContent="center">
                            <SoftButton variant="contained" color="dark" onClick={handleSaveSchedule}>
                                Save Schedule
                            </SoftButton>
                        </Grid>
                    )}
                    {existingScheduleId && (
                        <Grid item xs={12} container justifyContent="center">
                            <SoftButton variant="contained" color="error" onClick={handleDeleteSchedule}>
                                Delete Schedule
                            </SoftButton>
                        </Grid>
                    )}
                </Grid>

                <Grid container spacing={2} paddingTop={'60px'} justifyContent="center">
                    {daysOfWeek.map(day => (
                        <Grid item xs={2} key={day} justifyContent="center">
                            <Card>
                                <SoftTypography variant="h6" color="dark" align="center">
                                    {day}
                                </SoftTypography>
                                {(events.find(e => e.day === dayAbbreviations[day])?.times || []).map((time, index) => (
                                    <SoftTypography key={index} variant="body2" color="dark" align="center">
                                        {time}
                                    </SoftTypography>
                                ))}
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </DashboardLayout>
        </>
    );

}

export { CustomSchedule };
