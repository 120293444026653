// customSchedule.js
import { useEffect, useState } from 'react';
import { getExistingSchedule } from 'components/DatabaseFunctions';
import { getNextAvailableSlot } from 'components/customSchedule/getNextAvailableSlot';

export function useNextAvailableTimeSlot({db, workspaceId, linkedInUID}) {
  const [nextSlot, setNextSlot] = useState(null);

  useEffect(() => {
    // Check if the states are available
    if (!db || !workspaceId || !linkedInUID) {
      return;
    }

    // Fetch the existing schedule and set the next available slot
    const fetchAndSetNextAvailableSlot = async () => {
      const schedule = await getExistingSchedule(db, workspaceId, linkedInUID);
      if (schedule) {
        const nextAvailableSlot = getNextAvailableSlot(schedule.events);
        setNextSlot(nextAvailableSlot);
      }
    };

    fetchAndSetNextAvailableSlot();
  }, [db, workspaceId, linkedInUID]);

  return nextSlot;
}
  
