
import React, { useState } from "react";

import PropTypes from 'prop-types';

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React icons
import Settings from "examples/Icons/Settings";
import Cube from "examples/Icons/Cube";
import SpaceShip from "examples/Icons/SpaceShip";
import Document from "examples/Icons/Document";
import Shop from "examples/Icons/Shop";
import Basket from "examples/Icons/Basket";
import CustomerSupport from "examples/Icons/CustomerSupport";
import Office from "examples/Icons/Office";
import CreditCard from "examples/Icons/CreditCard";
import Book from "examples/Icons/Book";
import Megaphone from "examples/Icons/Megaphone";

//import Divider from MUI
import Divider from '@mui/material/Divider';
import LightBulb from "examples/Icons/LightBulb";
import ComicSpeech from "examples/Icons/ComicSpeech";
import GroupPeople from "examples/Icons/GroupPeople";
import Compass from "examples/Icons/Compass";

function ObjectivesAndAudience({onObjectivesAndAudienceChange}) {
  const [state, setState] = useState({
    educate: false,
    network: false,
    promote: false,
    sharenews: false,
    inspire: false,
    seekopportunities: false,
    opinions: false,
    engage: false,
    professionals: false,
    recruiters: false,
    generalpublic: false,
    potentialclients: false,
    mentorsoradvisors: false,
    studentsandgraduates: false
  });

  const toggleState = (key) => {
    const newState = { ...state, [key]: !state[key] };
    setState(newState);
    onObjectivesAndAudienceChange(newState);
  };
  

  const customButtonStyles = ({
    functions: { pxToRem, rgba },
    borders: { borderWidth },
    palette: { transparent, dark, secondary },
  }) => ({
    width: pxToRem(200),
    height: pxToRem(100),
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,

    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `${borderWidth[2]} solid ${transparent.main}`,
    },

    "&:hover": {
      backgroundColor: `${transparent.main} !important`,
      border: `${borderWidth[2]} solid ${secondary.main} !important`,

      "& svg g": {
        fill: rgba(dark.main, 0.75),
      },
    },
  });

  return (
    <SoftBox>
      <SoftBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5" fontWeight="regular">
            What are your objectives and who is your audience?
          </SoftTypography>
        </SoftBox>
        <SoftTypography variant="body2" fontWeight="regular" color="text">
          Give us more details about you. What do you enjoy doing in your spare time?
        </SoftTypography>
      </SoftBox>
      <SoftTypography variant="h6" fontWeight="bold" color="text" textAlign="center">
        Objectives
      </SoftTypography>
      <SoftBox mt={2}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={6}>
          <SoftBox display="flex" justifyContent="center">
          <SoftButton
              color="secondary"
              variant={state.educate ? "contained" : "outlined"}
              onClick={() => toggleState("educate")}
              sx={customButtonStyles}
            >
              <SoftBox display="flex" flexDirection="row" alignItems="center">
                <Document size="20px" color={state.educate ? "white" : "dark"} />
                <SoftBox display="flex" flexDirection="column" ml={1}>
                  <SoftTypography variant="button" color={state.educate ? "white" : "dark"}>
                    Educate
                  </SoftTypography>
                  <SoftTypography variant="caption" fontWeight="light" color="dark">
                    Share knowledge
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </SoftButton>
            </SoftBox>
          </Grid>
          <Grid item xs={6}> 
          <SoftBox display="flex" justifyContent="center">
          <SoftButton
            color="secondary"
            variant={state.network ? "contained" : "outlined"}
            onClick={() => toggleState("network")}
            sx={customButtonStyles}
          >
            <SoftBox display="flex" flexDirection="row" alignItems="center">
              <Cube size="20px" color={state.network ? "white" : "dark"} />
              <SoftBox display="flex" flexDirection="column" ml={1}>
                <SoftTypography variant="button" color={state.network ? "white" : "dark"}>
                  Network
                </SoftTypography>
                <SoftTypography variant="caption" fontWeight="light" color="dark">
                  Connect with peers
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftButton>
          </SoftBox>
          </Grid>
          <Grid item xs={6}>
          <SoftBox display="flex" justifyContent="center">
          <SoftButton
            color="secondary"
            variant={state.promote ? "contained" : "outlined"}
            onClick={() => toggleState("promote")}
            sx={customButtonStyles}
          >
            <SoftBox display="flex" flexDirection="row" alignItems="center">
              <Shop size="20px" color={state.promote ? "white" : "dark"} />
              <SoftBox display="flex" flexDirection="column" ml={1}>
                <SoftTypography variant="button" color={state.promote ? "white" : "dark"}>
                  Promote
                </SoftTypography>
                <SoftTypography variant="caption" fontWeight="light" color="dark">
                  Product, service, or brand
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftButton>
          </SoftBox>
          </Grid>
          <Grid item xs={6}>
          <SoftBox display="flex" justifyContent="center">
          <SoftButton
            color="secondary"
            variant={state.shareNews ? "contained" : "outlined"}
            onClick={() => toggleState("shareNews")}
            sx={customButtonStyles}
          >
            <SoftBox display="flex" flexDirection="row" alignItems="center">
              <Megaphone size="20px" color={state.shareNews ? "white" : "dark"} />
              <SoftBox display="flex" flexDirection="column" ml={1}>
                <SoftTypography variant="button" color={state.shareNews ? "white" : "dark"}>
                  Share News
                </SoftTypography>
                <SoftTypography variant="caption" fontWeight="light" color="dark">
                  Update your network
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftButton>
          </SoftBox>
          </Grid>
          <Grid item xs={6}>
          <SoftBox display="flex" justifyContent="center">
          <SoftButton
                color="secondary"
                variant={state.inspire ? "contained" : "outlined"}
                onClick={() => toggleState("inspire")}
                sx={customButtonStyles}
              >
            <SoftBox display="flex" flexDirection="row" alignItems="center">
              <LightBulb size="20px" color={state.inspire ? "white" : "dark"} />
              <SoftBox display="flex" flexDirection="column" ml={1}>
                <SoftTypography variant="button" color={state.inspire ? "white" : "dark"}>
                  Inspire
                </SoftTypography>
                <SoftTypography variant="caption" fontWeight="light" color="dark">
                Share stories or quotes
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftButton>
          </SoftBox>
          </Grid>
          <Grid item xs={6}>
          <SoftBox display="flex" justifyContent="center">
          <SoftButton
                color="secondary"
                variant={state.seekopportunities ? "contained" : "outlined"}
                onClick={() => toggleState("seekopportunities")}
                sx={customButtonStyles}
              >
            <SoftBox display="flex" flexDirection="row" alignItems="center">
              <SpaceShip size="20px" color={state.seekopportunities ? "white" : "dark"} />
              <SoftBox display="flex" flexDirection="column" ml={1}>
                <SoftTypography variant="button" color={state.seekopportunities ? "white" : "dark"}>
                  Opportunities
                </SoftTypography>
                <SoftTypography variant="caption" fontWeight="light" color="dark">
                Jobs, collaborations, or investments
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftButton>
          </SoftBox>
          </Grid>
          <Grid item xs={6}>
          <SoftBox display="flex" justifyContent="center">
          <SoftButton
                color="secondary"
                variant={state.opinions ? "contained" : "outlined"}
                onClick={() => toggleState("opinions")}
                sx={customButtonStyles}
              >
            <SoftBox display="flex" flexDirection="row" alignItems="center">
              <ComicSpeech size="20px" color={state.opinions ? "white" : "dark"} />
              <SoftBox display="flex" flexDirection="column" ml={1}>
                <SoftTypography variant="button" color={state.opinions ? "white" : "dark"}>
                  Opinion
                </SoftTypography>
                <SoftTypography variant="caption" fontWeight="light" color="dark">
                Share your views and inspire
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftButton>
          </SoftBox>
          </Grid>
          <Grid item xs={6}>
          <SoftBox display="flex" justifyContent="center">
          <SoftButton
                color="secondary"
                variant={state.engage ? "contained" : "outlined"}
                onClick={() => toggleState("engage")}
                sx={customButtonStyles}
              >
            <SoftBox display="flex" flexDirection="row" alignItems="center">
              <CustomerSupport size="20px" color={state.opinions ? "white" : "dark"} />
              <SoftBox display="flex" flexDirection="column" ml={1}>
                <SoftTypography variant="button" color={state.opinions ? "white" : "dark"}>
                  Engage
                </SoftTypography>
                <SoftTypography variant="caption" fontWeight="light" color="dark">
                Discussions, polls, or feedback
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftButton>
          </SoftBox>
          </Grid>
        </Grid>
        </SoftBox>
        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
        <SoftTypography variant="h6" fontWeight="bold" color="text" textAlign="center">
          Target Audience
        </SoftTypography>
        <SoftBox mt={2}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={6}>
          <SoftBox display="flex" justifyContent="center">
          <SoftButton
                color="secondary"
                variant={state.professionals ? "contained" : "outlined"}
                onClick={() => toggleState("professionals")}
                sx={customButtonStyles}
              >
            <SoftBox display="flex" flexDirection="row" alignItems="center">
              <Settings size="20px" color={state.professionals ? "white" : "dark"} />
              <SoftBox display="flex" flexDirection="column" ml={1}>
                <SoftTypography variant="button" color={state.professionals ? "white" : "dark"}>
                  Professionals
                </SoftTypography>
                <SoftTypography variant="caption" fontWeight="light" color="dark">
                People in the same domain or industry
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftButton>
          </SoftBox>
          </Grid>
          <Grid item xs={6}>
          <SoftBox display="flex" justifyContent="center">
          <SoftButton
                color="secondary"
                variant={state.recruiters ? "contained" : "outlined"}
                onClick={() => toggleState("recruiters")}
                sx={customButtonStyles}
              >
            <SoftBox display="flex" flexDirection="row" alignItems="center">
              <Office size="20px" color={state.recruiters ? "white" : "dark"} />
              <SoftBox display="flex" flexDirection="column" ml={1}>
                <SoftTypography variant="button" color={state.recruiters ? "white" : "dark"}>
                  Recruiters
                </SoftTypography>
                <SoftTypography variant="caption" fontWeight="light" color="dark">
                Looking for talent for job openings
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftButton>
          </SoftBox>
          </Grid>
          <Grid item xs={6}>
          <SoftBox display="flex" justifyContent="center">
          <SoftButton
                color="secondary"
                variant={state.generalpublic ? "contained" : "outlined"}
                onClick={() => toggleState("generalpublic")}
                sx={customButtonStyles}
              >
            <SoftBox display="flex" flexDirection="row" alignItems="center">
              <GroupPeople size="20px" color={state.generalpublic ? "white" : "dark"} />
              <SoftBox display="flex" flexDirection="column" ml={1}>
                <SoftTypography variant="button" color={state.generalpublic ? "white" : "dark"}>
                  Public
                </SoftTypography>
                <SoftTypography variant="caption" fontWeight="light" color="dark">
                Anyone and everyone on LinkedIn
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftButton>
          </SoftBox>
          </Grid>
          <Grid item xs={6}>
          <SoftBox display="flex" justifyContent="center">
          <SoftButton
                color="secondary"
                variant={state.potentialclients ? "contained" : "outlined"}
                onClick={() => toggleState("potentialclients")}
                sx={customButtonStyles}
              >
            <SoftBox display="flex" flexDirection="row" alignItems="center">
              <CreditCard size="20px" color={state.potentialclients ? "white" : "dark"} />
              <SoftBox display="flex" flexDirection="column" ml={1}>
                <SoftTypography variant="button" color={state.potentialclients ? "white" : "dark"}>
                  Customers
                </SoftTypography>
                <SoftTypography variant="caption" fontWeight="light" color="dark">
                For your products or services
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftButton>
          </SoftBox>
          </Grid>
          <Grid item xs={6}>
          <SoftBox display="flex" justifyContent="center">
          <SoftButton
                color="secondary"
                variant={state.mentorsoradvisors ? "contained" : "outlined"}
                onClick={() => toggleState("mentorsoradvisors")}
                sx={customButtonStyles}
              >
            <SoftBox display="flex" flexDirection="row" alignItems="center">
              <Compass size="20px" color={state.potentialclients ? "white" : "dark"} />
              <SoftBox display="flex" flexDirection="column" ml={1}>
                <SoftTypography variant="button" color={state.potentialclients ? "white" : "dark"}>
                  Mentors
                </SoftTypography>
                <SoftTypography variant="caption" fontWeight="light" color="dark">
                Seeking guidance or feedback
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftButton>
          </SoftBox>
          </Grid>
          <Grid item xs={6}>
          <SoftBox display="flex" justifyContent="center">
          <SoftButton
                color="secondary"
                variant={state.studentsandgraduates ? "contained" : "outlined"}
                onClick={() => toggleState("studentsandgraduates")}
                sx={customButtonStyles}
              >
            <SoftBox display="flex" flexDirection="row" alignItems="center">
              <Book size="20px" color={state.studentsandgraduates ? "white" : "dark"} />
              <SoftBox display="flex" flexDirection="column" ml={1}>
                <SoftTypography variant="button" color={state.studentsandgraduates ? "white" : "dark"}>
                  Students
                </SoftTypography>
                <SoftTypography variant="caption" fontWeight="light" color="dark">
                Looking for guidance or opportunities
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftButton>
          </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

ObjectivesAndAudience.propTypes = {
  onObjectivesAndAudienceChange: () => {},
};

export default ObjectivesAndAudience;
