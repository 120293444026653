import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import SoftBox from 'components/SoftBox';
import SoftInput from 'components/SoftInput';

//Import tooltip
import Tooltip from '@mui/material/Tooltip';

// Emoji Picker
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';

function TextArea({ postContent, setPostContent, placeholderText, showEmojiPicker }) {
  const [showPicker, setShowPicker] = useState(false);

  const addEmoji = (emoji) => {
    setPostContent(prev => prev + emoji.native);
    setShowPicker(false);
  };

  return (
    <SoftBox>
      <SoftBox mb={2}> {/* Add some margin at the top for spacing */}
        {showEmojiPicker && (
          <>
            <Tooltip title="Add Emoji">
              <InsertEmoticonIcon onClick={() => setShowPicker(!showPicker)} />
            </Tooltip>
            {showPicker && (
              <Picker data={data} onEmojiSelect={addEmoji} />
            )}
          </>
        )}
      </SoftBox>
      <SoftBox>
        <SoftInput
          multiline rows={15}
          value={postContent}
          onChange={(e) => setPostContent(e.target.value)}
          placeholder={placeholderText}
          style={{ width: '100%', padding: '5px', fontFamily: 'Arial, sans-serif', fontSize: '16px' }}
        />
      </SoftBox>
    </SoftBox>
  );
  
  
}

TextArea.propTypes = {
  postContent: PropTypes.string.isRequired,
  setPostContent: PropTypes.func.isRequired,
  placeholderText: PropTypes.string.isRequired,
  showEmojiPicker: PropTypes.bool
};

TextArea.defaultProps = {
  showEmojiPicker: true
};

export default TextArea;
