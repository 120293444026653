import React, { useEffect, useState, useContext } from 'react';

// @mui material components
import { Grid, Switch, Card } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Billing page components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Invoices from "layouts/pages/account/billing/components/Invoices";
import Transactions from "layouts/pages/account/billing/components/Transactions";
import SubscriptionInformation from './components/SubscriptionInformation';

//Firebase
import FirebaseContext from "configs/firebasecontext";
import { useUserData } from "context/userManagement";
import SoftTypography from 'components/SoftTypography';
import SoftAlert from 'components/SoftAlert';

// Get auth from firebase
import { getAuth } from "firebase/auth";

//Import Add New Card Component
import PricingCards from "./components/PricingCards";

//Import Invoices Database Query
import { getInvoiceHistory, getTransactionHistory, getSubscriptionDetails } from "components/DatabaseFunctions";

function Billing() {
  const { currentUserData } = useUserData();
  const { db } = useContext(FirebaseContext);
  const [subscriptions, setSubscriptions] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [transactions, setTransactions] = useState([]);

  //Free Trial States
  const [freeTrialActive, setFreeTrialActive] = useState(false);
  const [freeTrialExpiration, setFreeTrialExpiration] = useState(null);

  // Pricing
  const [activePricingOption, setActivePricingOption] = useState("monthly");
  const [prices, setPrices] = useState(["19", "25", "90"]);

  // State to show the billing history if user is authenticated
  const [showBillingHistory, setShowBillingHistory] = useState(false);

  // Check if there is a free trial active
  useEffect(() => {
    console.log("Current User Data in main component:", currentUserData);
    if (currentUserData && currentUserData?.tenantData && currentUserData?.tenantData[0]?.subscription?.freeTrial === true) {
      console.log("Free Trial State:", currentUserData?.tenantData[0]?.subscription?.freeTrial);
      const currentDate = new Date().getTime();
      const expirationDate = new Date(currentUserData?.tenantData[0]?.subscription?.freeTrialEndDate._seconds * 1000).getTime();
      const daysRemaining = Math.floor((expirationDate - currentDate) / (1000 * 60 * 60 * 24));
      setFreeTrialExpiration(expirationDate);
      console.log("Free Trial Expiration Date:", expirationDate);
      console.log("Days Remaining in Free Trial:", daysRemaining);
      setFreeTrialActive(true);
    }
  }, [currentUserData]);

  //Fetch invoices from Firestore and set them to the state 
  useEffect(() => {
    const fetchBillingHistory = async () => {
      try {
        // Fetch the invoices from the backend
        const invoiceHistoryData = await getInvoiceHistory(db, currentUserData?.tenantData[0]?.tenantId);
        setInvoices(invoiceHistoryData);
        console.log("Invoice History Data:", invoices);
        const transactionHistoryData = await getTransactionHistory(db, currentUserData?.tenantData[0]?.tenantId);
        setTransactions(transactionHistoryData);
        console.log("Transaction History Data:", transactions);
        const subscriptionDetailsData = await getSubscriptionDetails(db, currentUserData?.tenantData[0]?.tenantId);
        setSubscriptions(subscriptionDetailsData);
        console.log("Subscription Details Data:", subscriptions);
      } catch (error) {
        console.error("Error fetching billing history:", error);
      }
    };

    fetchBillingHistory();
  }, [db, currentUserData]);

  // Check if the signed-in user is the primaryUser in the tenant data
  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    // Ensure user and currentUserData are defined before proceeding
    if (user && currentUserData && currentUserData.tenantData && currentUserData.tenantData.length > 0) {
      const tenantData = currentUserData.tenantData[0];
      if (user.uid === tenantData.primaryUser) {
        setShowBillingHistory(true);
      } else {
        setShowBillingHistory(false);
      }
    } else {
      setShowBillingHistory(false);
    }
  }, [currentUserData]);

  // Function to handle the billing period change
  const handleBillingPeriodChange = () => {

    if (activePricingOption === "monthly") {
      setActivePricingOption("annual");
        setPrices(["190", "250", "900"]);
    } else {
      setActivePricingOption("monthly");
        setPrices(["19", "25", "90"]);
    }
  };

    console.log("Free Trial Active:", freeTrialActive);
    
  return (
    <>
    <DashboardLayout>
    <DashboardNavbar />
      <SoftBox mt={4}>
        <SoftBox mb={3}>

          {freeTrialActive && (
            <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
              <SoftBox mb={2}>
                <SoftAlert
                  color="warning"
                >
                  Your free trial ends on {freeTrialExpiration ? new Date(freeTrialExpiration).toLocaleDateString() : null}
                </SoftAlert>
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox mb={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <SoftTypography variant="body2" fontWeight="medium" style={{ marginRight: '8px' }}>Monthly</SoftTypography>
                    <Switch
                        checked={activePricingOption === "annual"}
                        onChange={handleBillingPeriodChange}
                        name="modalSwitch"
                    />
                  <SoftTypography variant="body2" fontWeight="medium" style={{ marginLeft: '8px' }}>Yearly - Save 20%</SoftTypography>
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox mb={2}>
                <PricingCards prices={prices} activePricingOption={activePricingOption} tenantId={currentUserData?.tenantData[0]?.tenantId} />
                </SoftBox>
              </Grid>
            </Grid>
            </>
          )}

          {!freeTrialActive && showBillingHistory && (
            <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  {subscriptions && subscriptions.length > 0 && (
                  <SubscriptionInformation subscriptions={subscriptions} />
                  )}
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  {invoices && (
                  <Invoices invoices={invoices} />
                  )}
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                  {transactions && (
                  <Transactions transactions={transactions} />
                  )}
                </Card>
              </Grid>
            </Grid>
            </>
          )}

          {!freeTrialActive && !showBillingHistory && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
              <SoftBox>
                <SoftTypography variant="h6" fontWeight="medium" textAlign="center">
                  You are not authorized to view this page. Please contact the primary user of your account.
                </SoftTypography>
              </SoftBox>
              </Grid>
            </Grid>
          )  
          }

        </SoftBox>
      </SoftBox>
    </DashboardLayout>

    </>
  );
}

export default Billing;

