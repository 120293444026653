import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";

// Soft UI Dashboard PRO React example components
import NotificationItem from "examples/Items/NotificationItem";

//Import Firebase Context
import FirebaseContext from "configs/firebasecontext";

//Import Firebase Auth
import { signOut } from "firebase/auth";
import { useUserData } from "context/userManagement";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard PRO React context
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Import the NewWorkspaceDrawer component
import NewWorkspaceDrawer from "examples/Navbars/DashboardNavbar/components/NewWorkspaceDrawer";

// Import Loading Overlay
import LoadingOverlay from "components/SharedFunctions/loadingOverlay";

// Import Upgrade Plan Modal
import UpgradePlanModal from "components/SharedFunctions/upgradePlanModal";

function DashboardNavbar({ absolute, light, isMini, scheduledNavPosts }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openNewWorkspaceDrawer, setOpenNewWorkspaceDrawer] = useState(false);
  const [openUpgradePlanModal, setOpenUpgradePlanModal] = useState(false);

  const route = useLocation().pathname.split("/").slice(1);
  const navigate = useNavigate();

  const { 
    userId, 
    currentUserData, 
    currentWorkspace, 
    setCurrentWorkspace, 
    isLoading, 
    error 
  } = useUserData();

  const { auth } = useContext(FirebaseContext);

  const lastFivePosts = scheduledNavPosts.slice(-5);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  let userPermissions = {};
  let maximumWorkspaces = 1;
  
  if (currentUserData) {
    userPermissions = currentUserData?.tenantData[0]?.subscriptionData[0]?.planAccess ?? {};
    maximumWorkspaces = currentUserData?.tenantData[0]?.subscriptionData[0]?.workspaceLimit ?? 1;
  }

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleWorkspaceChange = async (event) => {
    if (event.value === "new") {
      if (currentUserData.workspaceData.length >= maximumWorkspaces) {
        setOpenUpgradePlanModal(true);
        return;
      }

      if (!userPermissions?.multipleWorkspaces) {
        setOpenUpgradePlanModal(true);
        return;
      }

      setOpenNewWorkspaceDrawer(true);
      return;
    }

    const workspaceId = event?.value || "0";
    const workspaceData = currentUserData.workspaceData.find(workspace => workspace.id === workspaceId);
    setCurrentWorkspace(workspaceData);
    localStorage.setItem("currentWorkspace", JSON.stringify(workspaceData));
  };

  const workspaceOptions = currentUserData?.workspaceData
    ? currentUserData.workspaceData
        .filter(workspace => workspace.members.includes(userId))
        .map(workspace => ({
          value: workspace.id,
          label: workspace.workspaceName,
        }))
    : [];

  if (currentUserData?.tenantData[0]?.primaryUser === userId) {
    workspaceOptions.push({ value: "new", label: "Create New Workspace" });
  }

  const notificationItems = lastFivePosts.map((post) => (
    <NotificationItem
      key={post.id}
      color="info"
      image={<Icon>notifications</Icon>}
      title={[post.title.substring(0, 25) + (post.title.length > 25 ? "..." : "")]}
      date={post.start.toLocaleString()}
      onClick={handleCloseMenu}
    />
  ));

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {notificationItems}
    </Menu>
  );

  if (isLoading) {
    return <LoadingOverlay />;
  }

  if (error) {
    console.error("Error loading user data:", error);
    return <div>Error loading user data. Please try again.</div>;
  }

  return (
    <>
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <SoftBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
            <Tooltip title="Change Side Menu Size">
              <Icon fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}>
                {miniSidenav ? "menu_open" : "menu"}
              </Icon>
            </Tooltip>
          </SoftBox>

          {!isMini && (
            <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
              <SoftBox color={light ? "white" : "inherit"}>
                <SoftSelect
                  size="small"
                  placeholder={currentWorkspace?.workspaceName || "Select Workspace"}
                  value={currentWorkspace?.id || "0"}
                  onChange={(event) => handleWorkspaceChange(event)}
                  options={workspaceOptions}
                />
                <IconButton
                  size="small"
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <Icon className={light ? "text-white" : "text-dark"}>
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </IconButton>
              </SoftBox>
            </SoftBox>
          )}
        </Toolbar>
      </AppBar>

      <NewWorkspaceDrawer
        open={openNewWorkspaceDrawer}
        onClose={() => setOpenNewWorkspaceDrawer(false)}
      />

      <UpgradePlanModal 
        open={openUpgradePlanModal} 
        onClose={() => setOpenUpgradePlanModal(false)} 
      />
    </>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  scheduledNavPosts: [],
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  scheduledNavPosts: PropTypes.arrayOf(PropTypes.object),
};

export default DashboardNavbar;