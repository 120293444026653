// postFunctions.js
import { collection, query, where, addDoc, deleteDoc, getDocs, Timestamp } from "firebase/firestore";
import { httpsCallable } from "@firebase/functions";

//Import Database Functions
import { schedulePost, storeDraft, deletePost, fetchScheduledPosts, fetchDrafts, getExistingSchedule } from "components/DatabaseFunctions";

export const handleConfirmSchedule = async (scheduleMode, selectedTime, postContent, userId, db, postImageUrl, postImageMetaData, initialComment, postingProfile, customSchedule, nextSlot, currentWorkspaceId, linkedInUID) => {
  console.log("Running handleConfirmSchedule function");

  console.log("LinkedIn UID in Scheduled Post Function:", linkedInUID);

  console.log("Schedule Mode:", scheduleMode);
  console.log("Selected Time:", selectedTime);

  let scheduleStatus = "";
  let scheduleComplete = false;

  // Convert selectedTime to a Date object if it's not already
  let dateTime;
  if (scheduleMode === 'manual') {
    // Ensure selectedTime is a Date object
    dateTime = new Date(selectedTime[0]);
  } else if (scheduleMode === 'custom' && customSchedule) {
    // Get the next available timeslot based on the custom schedule
    dateTime = nextSlot;
  }

  // Log the Date objects for debugging
  console.log("Current Time:", new Date(Date.now()));
  console.log("Selected DateTime:", dateTime);

  // Check if the selected date and time is in the past
  if (scheduleMode === 'manual' && dateTime.getTime() < Date.now()) {
    scheduleStatus = "Please select a future date and time.";
    scheduleComplete = false;
    return { scheduleStatus, scheduleComplete };
  }

  // Check if post content is empty
  if (postContent === "") {
    scheduleStatus = "Please enter some content.";
    scheduleComplete = false;
    return { scheduleStatus, scheduleComplete };
  }

  if (scheduleMode === 'manual' && !selectedTime) {
    scheduleStatus = "Please select a date and time.";
    scheduleComplete = false;
    return { scheduleStatus, scheduleComplete };
  }

  if (scheduleMode === 'custom' && !dateTime) {
    scheduleStatus = "No available time slots. Please try again later.";
    scheduleComplete = false;
    return { scheduleStatus, scheduleComplete };
  }

  if (scheduleMode !== 'manual' && scheduleMode !== 'custom') {
    scheduleStatus = "Please set up a custom schedule.";
    scheduleComplete = false;
    return { scheduleStatus, scheduleComplete };
  }

  try {
    const status = "Scheduled";
    console.log("Running storePost function");

    // Call the storePost function
    await schedulePost(postContent, status, dateTime, userId, db, postImageUrl, postImageMetaData, initialComment, postingProfile, currentWorkspaceId, linkedInUID);

    // If StorePost is successful, show success message
    console.log("storePost function ran successfully");

    scheduleStatus = "Post scheduled successfully!";
    scheduleComplete = true;

    return { scheduleStatus, scheduleComplete };

  } catch (error) {
    // If storePost fails, show error message
    console.error("Error scheduling post:", error);
    scheduleStatus = "Error scheduling post!";
    scheduleComplete = false;
    return { scheduleStatus, scheduleComplete };
  }
};


export const handleStoreDraft = async (postingProfile, postContent, userId, db, postImageUrl, postImageMetaData, initialComment, currentWorkspaceId, linkedInUID) => {
        let saveStatus = ""; // Variable to store the status of the draft for return
        let saveComplete = false; // Variable to store the status of the draft for return

        // Check if Posting Profile is empty
        if (postingProfile === "") {
          saveStatus = "Please select a posting profile.";
          saveComplete = false;
          return { saveStatus, saveComplete };
        }
    
        // Check if post content is empty
        if (postContent === "") {
          saveStatus = "Please enter some content.";
          saveComplete = false;
          return { saveStatus, saveComplete };
        }
    
        try {
          console.log("Running storeDraft function");

          const postStatus = "Draft"
          const dateTime = null;

          // Call the storeDraft function to save the draft and set saveStatus to "Draft saved successfully!"
          await storeDraft(postContent, postStatus, dateTime, userId, db, postImageUrl, postImageMetaData, initialComment, postingProfile, currentWorkspaceId, linkedInUID);

          saveStatus = "Draft saved successfully!";
          saveComplete = true;

          return { saveStatus, saveComplete };

        } catch (error) {

          // If storeDraft fails, set saveStatus to "Error saving draft!"
          saveStatus = "Error saving draft!";
          saveComplete = false;

          return { saveStatus, saveComplete };

        }
      }

export const handleDeleteDraft = async (postId, db, setModalPostResultsMessage, setPostNowSuccess, setShowPostResultsModal, getDrafts) => {
        try {
          console.log("Draft ID to Delete:", postId)
          await deletePost(postId, db, setPostDetailsModalOpen);
          // If deleteDraft is successful, show success message
          setModalPostResultsMessage("Draft deleted successfully!");
          setPostNowSuccess(true);
          setShowPostResultsModal(true);
          getDrafts();
          setTimeout(() => {
            setShowPostResultsModal(false);
          }
          , 5000);
        }
        catch (error) {
          // If deleteDraft fails, show error message
          setModalPostResultsMessage("Error deleting draft!");
          setPostNowSuccess(false);
          setShowPostResultsModal(true);
          setTimeout(() => {
            setShowPostResultsModal(false);
          }
          , 5000);
        }
      }

export const handlePostNow = async (postContent, postImageUrl, postImageMetaData, initialComment, postingProfile, functions, userId, linkedInPostingAccount, currentWorkspaceId) => {

  // Console log the received data for debugging
  console.log("Post Content in PostNow Function:", postContent);
  console.log("Post Image URL in PostNow Function:", postImageUrl);
  console.log("Post Image MetaData in PostNow Function:", postImageMetaData);
  console.log("Initial Comment in PostNow Function:", initialComment);
  console.log("Posting Profile in PostNow Function:", postingProfile);
  console.log("User ID in PostNow Function:", userId);
  console.log("LinkedIn ID in PostNow Function:", linkedInPostingAccount);
  console.log("Current Workspace ID in PostNow Function:", currentWorkspaceId);

    let postStatus = "";
    let postComplete = false;

    // Check if Posting Profile is empty
    if (postingProfile === "") {
      postStatus = "Please select a posting profile.";
      postComplete = false;
      return { postStatus, postComplete };
    }

    //Check if post content is empty
    if (postContent === "") {
      postStatus = "Please enter some content.";
      postComplete = false;
      return { postStatus, postComplete };
    }

    // Check if brand account
    if (linkedInPostingAccount.includes("urn:li:organizationBrand")) {
      postStatus = "Unfortunately you can't post from a brand account. Please select different account.";
      postComplete = false;
      return { postStatus, postComplete };
    }

    try {
      let firebaseUID = userId;

      console.log("Post Image URL in PostNow Function:", postImageUrl);
      console.log("Post Image MetaData in PostNow Function:", postImageMetaData);

      const postNowFunction = httpsCallable(functions, process.env.REACT_APP_CLOUD_FUNCTION_POSTNOW);
      const result = await postNowFunction({ postContent, postImageUrl, linkedInPostingAccount, postImageMetaData, firebaseUID, initialComment, postingProfile, currentWorkspaceId });

      // console.log("Post now result:", result.data);
      if (result.data.success) {
        postStatus = "Post successful!";
        postComplete = true;
        return { postStatus, postComplete };
      } else {
        postStatus = "Error posting!";
        postComplete = false;
        return { postStatus, postComplete };
      }

    } catch (error) {
      console.error("Error posting now:", error);
      postStatus = "Error posting!";
      postComplete = false;
      return { postStatus, postComplete };
    }
  };

// Additional helper functions as needed
