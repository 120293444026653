import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";

function LightBulb({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>custom-icon-3</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M480-80q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM320-200v-80h320v80H320Zm10-120q-69-41-109.5-110T180-580q0-125 87.5-212.5T480-880q125 0 212.5 87.5T780-580q0 81-40.5 150T630-320H330Zm24-80h252q45-32 69.5-79T700-580q0-92-64-156t-156-64q-92 0-156 64t-64 156q0 54 24.5 101t69.5 79Zm126 0Z"
          fill={colors[color] ? colors[color].main : colors.dark.main}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

// Setting default values for the props of CustomSVG3
LightBulb.defaultProps = {
  color: "dark",
  size: "16px",
};

// Typechecking props for the CustomSVG3
LightBulb.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "white",
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default LightBulb;
