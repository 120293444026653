import { useState, useEffect } from "react";

import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import colors from "assets/theme/base/colors";

//Import LinkedIn Social Icons
const commentButton = "/assets/images/linkedin-post-preview/CommentButton.png";
const likeButton = "/assets/images/linkedin-post-preview/LikeButton.png";
const repostButton = "/assets/images/linkedin-post-preview/RepostButton.png";
const sendButton = "/assets/images/linkedin-post-preview/SendButton.png";
const reactionIcons = "/assets/images/linkedin-post-preview/LinkedInReactions.png";

function LinkedInProfileCard({ image, name, headline, postContent, postURL, postImage, postImageMetaData, setPostImageUrl, shouldTruncateText, setShouldTruncateText, showReactions }) {

    //State to hold type of image (image or video)
    const [postImageType, setPostImageType] = useState("image");
    const [showPostImage, setShowPostImage] = useState(true);
    // const [truncatedPostContent, setTruncatedPostContent] = useState("");

    // Function to remove the post image
    function removeImage() {
      setPostImageUrl(null);
      setShowPostImage(false);
    }
  
    // Maximum length for the post preview text
    const maxLength = 200;

    // Effect to set the postImageType based on the postImage URL
    useEffect(() => {
      if (postImage) {
        if (postImageMetaData===("video/mp4")) {
          setPostImageType("video");
        } else {
          setPostImageType("image");
        }
      }
    }, [postImage]);

    // Function to show the full post content
    const showFullContent = () => {
      setShouldTruncateText(false);
    };
  
    return (
      <>
      <SoftBox p={3} boxShadow={3} style={{ backgroundColor: 'white' }}>
        <Grid container>
          <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: '10px' }}>
            <SoftAvatar src={image} alt={name} size="lg" />
          </Grid>
          <Grid item xs={10} style={{ display: 'flex', flexDirection: 'column', paddingLeft: '30px' }}>
            <SoftTypography variant="h6" fontWeight="medium">
              {name}
            </SoftTypography>
            <SoftTypography variant="caption" fontWeight="light" color="dark">
              {headline}
            </SoftTypography>
          </Grid>
        </Grid>

  
        <SoftBox mt={2} style={{ backgroundColor: 'white', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
        <SoftTypography variant="body2" fontWeight="light" color="dark">
          {shouldTruncateText 
          ? (postContent && postContent.length > maxLength ? postContent.substring(0, maxLength) + "  " : postContent)
          : postContent}
          <br /><br />
          {postURL}
        </SoftTypography>

          {shouldTruncateText && (
                <SoftTypography 
                  variant="caption" 
                  fontWeight="light" 
                  color="dark" 
                  onClick={showFullContent}
                  style={{ cursor: 'pointer' }}
                >
                  ...see more
                </SoftTypography>
          )}
        </SoftBox>

        <SoftBox mt={2} style={{ backgroundColor: 'white', position: 'relative' }}>
        {showPostImage && postImage && postImageType === "image" && (
          <>
            <img src={postImage} alt="Post" style={{ width: '100%', height: 'auto' }} />
            <Tooltip title="Remove Image" placement="top">
            <Button 
              style={{ 
                position: 'absolute', 
                top: 0, 
                right: 0, 
                backgroundColor: 'red',  // Set to a bright color for visibility
                color: 'white', 
                minWidth: 'auto', 
                padding: '5px 10px',
                zIndex: 1000  // Set a high z-index
              }}
              onClick={() => removeImage(false)}
            >
              X
            </Button>
            </Tooltip>
          </>
        )}
        {showPostImage && postImage && postImageType === "video" && (
          <video width="100%" height="auto" controls>
            <source src={postImage} type="video/mp4" />
            Your browser does not support video playback.
          </video>
        )}
        </SoftBox>
        {showReactions &&
        <SoftBox>
        <SoftBox mt={2} display="flex" justifyContent="space-between" style={{ backgroundColor: 'white' }}>
          <img src={reactionIcons} alt="Reactions" style={{ width: '176px', height: '41px' }} />
          </SoftBox>
        <SoftBox mt={2} display="flex" justifyContent="space-between" style={{ backgroundColor: 'white' }}>
          <Button size="small" startIcon={<img src={likeButton} alt="Like" style={{ width: '63px', height: '34px' }} />}>
          </Button>
          <Button size="small" startIcon={<img src={commentButton} alt="Comment" style={{ width: '110px', height: '33px' }} />}>
          </Button>
        </SoftBox>
        </SoftBox>
      }
      </SoftBox>
      </>
    );
  }
  

  
  LinkedInProfileCard.defaultProps = {
    name: "",
    headline: "",
    postContent: "",
    postURL: "",
    image: "",
    postImage: "",
    postImageMetaData: "",
    setPostImageUrl: () => {},
    shouldTruncateText: true,
    setShouldTruncateText: () => {},
    showReactions: true,
  };
  
  LinkedInProfileCard.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    headline: PropTypes.string,
    postContent: PropTypes.string,
    postURL: PropTypes.string,
    postImage: PropTypes.string,
    postImageMetaData: PropTypes.string,
    setPostImageUrl: PropTypes.func,
    shouldTruncateText: PropTypes.bool,
    setShouldTruncateText: PropTypes.func,
    showReactions: PropTypes.bool,
  };
  
  export default LinkedInProfileCard;
