
import React, { useState } from "react";

import propTypes from 'prop-types';

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftDropzone from "components/SoftDropzone";
import SoftSelect from "components/SoftSelect";

//Import Divider from MUI
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React icons
import Settings from "examples/Icons/Settings";
import Cube from "examples/Icons/Cube";
import SpaceShip from "examples/Icons/SpaceShip";
import Document from "examples/Icons/Document";
import Shop from "examples/Icons/Shop";
import Basket from "examples/Icons/Basket";
import CustomerSupport from "examples/Icons/CustomerSupport";
import Office from "examples/Icons/Office";
import CreditCard from "examples/Icons/CreditCard";
import Book from "examples/Icons/Book";
import Megaphone from "examples/Icons/Megaphone";
import LightBulb from "examples/Icons/LightBulb";

//Context Imports
import { useUserData } from "context/userManagement";


function FormatStyling({onFormatStylingChange}) {
  const [toneOfVoice, setToneOfVoice] = useState([]);
  const [storytelling, setStorytelling] = useState(false);
  const [bulletpoints, setBulletPoints] = useState(false);
  const [questions, setQuestions] = useState(false);
  const [calltoaction, setCallToAction] = useState(false);
  const [shortform, setShortForm] = useState(false);
  const [emoji, setEmoji] = useState([]);
  const [postLength, setPostLength] = useState([]);

  const { userId, userData } = useUserData();

  const [state, setState] = useState({
    toneOfVoice: false,
    storytelling: false,
    bulletpoints: false,
    questions: false,
    calltoaction: false,
    shortform: false,
  });
  
  const handleInputChange = (field, value) => {
    switch (field) {
      case "toneOfVoice":
        setToneOfVoice(value);
        break;
      case "emoji":
        setEmoji(value);
        break;
      case "postLength":
        setPostLength(value);
        break;
      default:
        break;
    }

    // Call the onFormatStylingChange function after updating the state
    onFormatStylingChange({
      toneOfVoice,
      storytelling,
      bulletpoints,
      questions,
      calltoaction,
      shortform,
      emoji,
      postLength,
      [field]: value  // Update the changed value
    });
  };
  
  const handleButtonToggle = (field) => {
    switch (field) {
      case "storytelling":
        setStorytelling(!storytelling);
        break;
      case "bulletpoints":
        setBulletPoints(!bulletpoints);
        break;
      case "questions":
        setQuestions(!questions);
        break;
      case "calltoaction":
        setCallToAction(!calltoaction);
        break;
      case "shortform":
        setShortForm(!shortform);
        break;
      default:
        break;
    }

    // Call the onFormatStylingChange function after updating the state
    onFormatStylingChange({
      toneOfVoice,
      storytelling,
      bulletpoints,
      questions,
      calltoaction,
      shortform,
      emoji,
      postLength,
      [field]: !eval(field)  // Update the changed value
    });
  };
  
  
  const toneOfVoiceOptions = [
    { value: "Formal", label: "Formal" },
    { value: "Informal", label: "Informal" },
    { value: "Friendly", label: "Friendly" },
    { value: "Inspirational", label: "Inspirational" },
    { value: "Informative", label: "Informative" },
    { value: "Persuasive", label: "Persuasive" },
    { value: "Other", label: "Other" },
  ];

  const emojiOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const postLengthOptions = [
    { value: "Short and Crisp", label: "Short and Crisp" },
    { value: "Moderate", label: "Moderate" },
    { value: "Long and Detailed", label: "Long and Detailed" },
  ];

  const customButtonStyles = ({
    functions: { pxToRem, rgba },
    borders: { borderWidth },
    palette: { transparent, dark, secondary },
  }) => ({
    width: pxToRem(200),
    height: pxToRem(100),
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,

    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `${borderWidth[2]} solid ${transparent.main}`,
    },

    "&:hover": {
      backgroundColor: `${transparent.main} !important`,
      border: `${borderWidth[2]} solid ${secondary.main} !important`,

      "& svg g": {
        fill: rgba(dark.main, 0.75),
      },
    },
  });

  return (
    <SoftBox>
      <SoftBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5" fontWeight="regular">
            A few questions on format and styling
          </SoftTypography>
        </SoftBox>
        <SoftTypography variant="body2" fontWeight="regular" color="text">
          Providing us with a bit of information on how you like to format your posts will help us to tailor our AI to your style.
          <br></br>
          <br></br>
          Remember, you can always change these settings later or adjust them on a post by post basis.
        </SoftTypography>
      </SoftBox>
      <SoftTypography variant="h6" fontWeight="bold" color="text" textAlign="center">
        Tone of Voice Preference
      </SoftTypography>
      <SoftBox mt={2}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <SoftBox textAlign="center">
              <SoftSelect
                menuPortalTarget={document.body}
                isMulti={true}
                color="secondary"
                options={toneOfVoiceOptions}
                onChange={(value) => handleInputChange("toneOfVoice", value)} 
              >
              </SoftSelect>
            </SoftBox>
          </Grid>
        </Grid>
        </SoftBox>
        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
        <SoftTypography variant="h6" fontWeight="bold" color="text" textAlign="center">
          Do you use emojis?
        </SoftTypography>
            <SoftBox textAlign="center" mb={3}>
              <SoftSelect
                menuPortalTarget={document.body}
                color="secondary"
                options={emojiOptions}
                onChange={(value) => handleInputChange("emoji", value)}
              >
              </SoftSelect>
            </SoftBox>
          </Grid>
          </Grid>
          <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
          <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
        <SoftTypography variant="h6" fontWeight="bold" color="text" textAlign="center">
          Post Length
        </SoftTypography>
            <SoftBox textAlign="center" mb={3}>
              <SoftSelect
                color="secondary"
                options={postLengthOptions}
                onChange={(value) => handleInputChange("postLength", value)}
              >
              </SoftSelect>
            </SoftBox>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
        <SoftTypography variant="h6" fontWeight="bold" color="text" textAlign="center">
          Preferred Format
        </SoftTypography>
        <SoftBox mt={2}>
        <Grid container spacing={3} justifyContent="center">
        <Grid item xs={6} md={4} lg={3}>
        <SoftBox display="flex" justifyContent="center">
        <SoftButton
                color="secondary"
                variant={storytelling ? "contained" : "outlined"}
                onClick={() => handleButtonToggle("storytelling", !storytelling)}
                sx={customButtonStyles}
              >
            <SoftBox display="flex" flexDirection="row" alignItems="center">
              <Book size="20px" color={state.storytelling ? "white" : "dark"} />
              <SoftBox display="flex" flexDirection="column" ml={1}>
                <SoftTypography variant="button" color={state.storytelling ? "white" : "dark"}>
                  Storytelling
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftButton>
          </SoftBox>
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
          <SoftBox display="flex" justifyContent="center">
          <SoftButton
                color="secondary"
                variant={questions ? "contained" : "outlined"}
                onClick={() => handleButtonToggle("questions", !questions)}
                sx={customButtonStyles}
              >
            <SoftBox display="flex" flexDirection="row" alignItems="center">
              <LightBulb size="20px" color={state.questions ? "white" : "dark"} />
              <SoftBox display="flex" flexDirection="column" ml={1}>
                <SoftTypography variant="button" color={state.questions ? "white" : "dark"}>
                  Questions
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftButton>
          </SoftBox>
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
          <SoftBox display="flex" justifyContent="center">
          <SoftButton
                color="secondary"
                variant={calltoaction ? "contained" : "outlined"}
                onClick={() => handleButtonToggle("calltoaction", !calltoaction)}
                sx={customButtonStyles}
              >
            <SoftBox display="flex" flexDirection="row" alignItems="center">
              <Megaphone size="25px" color={state.calltoaction ? "white" : "dark"} />
              <SoftBox display="flex" flexDirection="column" ml={1}>
                <SoftTypography variant="button" color={state.calltoaction ? "white" : "dark"}>
                  Call To Action
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftButton>
          </SoftBox>
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
          <SoftBox display="flex" justifyContent="center">
              <SoftButton
                color="secondary"
                variant={shortform ? "contained" : "outlined"}
                onClick={() => handleButtonToggle("shortform", !shortform)}
                sx={customButtonStyles}
              >
            <SoftBox display="flex" flexDirection="row" alignItems="center">
              <Document size="20px" color={state.shortform ? "white" : "dark"} />
              <SoftBox display="flex" flexDirection="column" ml={1}>
                <SoftTypography variant="button" color={state.shortform ? "white" : "dark"}>
                  Short Form
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftButton>
          </SoftBox>
          </Grid>
        </Grid>
        </SoftBox>
        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />

      </SoftBox>
  );
}

FormatStyling.propTypes = {
  onFormatStylingChange: () => {},
};


export default FormatStyling;
