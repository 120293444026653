import React from "react";

import { Modal, Divider } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import PropTypes from "prop-types";

const UpgradePlanModal = ({ open, onClose }) => {

    const stripePortalURL = process.env.REACT_APP_STRIPE_PORTAL_URL;

    return (
        <Modal open={open} onClose={onClose}>
          <SoftBox sx={{ ...modalStyle }}>
            <SoftTypography variant="h4" fontWeight="bold" mb={2}>
              Upgrade Plan
            </SoftTypography>
            <Divider sx={{ mb: 2 }} />
            <SoftBox display="flex" alignItems="center" mb={2}>
              <SoftTypography variant="body2" fontWeight="regular" ml={2}>
                To access this feature or add additional LinkedIn Accounts, Workspaces and Team Members, you need to upgrade your plan.
              </SoftTypography>
            </SoftBox>
            <SoftBox mt={2} display="flex" justifyContent="flex-end">
              <SoftButton variant="outlined" color="dark" onClick={onClose} sx={{ mr: 1 }}>
                Close
              </SoftButton>
              <SoftButton variant="contained" color="primary" href={stripePortalURL} target="_blank">
                Upgrade Plan
            </SoftButton>
            </SoftBox>
          </SoftBox>
        </Modal>
      );
    };
    
    const modalStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      minWidth: '40%',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: 1,
    };

UpgradePlanModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default UpgradePlanModal;
