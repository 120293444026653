import { useState, useContext } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftAlert from "components/SoftAlert";
import SoftTypography from "components/SoftTypography";

//Firebase Imports
import { sendPasswordResetEmail } from "firebase/auth";

//Import Firebase Context
import FirebaseContext from "configs/firebasecontext";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import lock from "assets/images/illustrations/lock.png";

function PasswordReset() {
  const [email, setEmail] = useState(null);
  const [alert, setAlert] = useState(null);

  //Firebase Auth Setup from Context
  const { auth } = useContext(FirebaseContext);

  function sendResetEmail(email) {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setAlert("Reset email sent!");
      })
      .catch((error) => {
        setAlert("Error sending reset email.");
      });
  }


  return (
    <IllustrationLayout
      title="Reset Password"
      description="Complete this form to reset your password. You'll receive an email with a link to set a new password."
      illustration={{
        image: lock,
        title: "PropelPosts.ai",
        description:
          "Just as it takes a company to sustain a product, it takes a community to sustain a protocol.",
      }}
    >
      {alert && (
        <SoftBox mb={2}>
          <SoftAlert severity="success">{alert}</SoftAlert>
        </SoftBox>
      )}
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftInput type="email" placeholder="Email" size="large"  value={email} onChange={e => setEmail(e.target.value)}  />
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="info" size="large" fullWidth onClick={() => sendResetEmail(email)}> 
            send
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Already have an account?&nbsp;
            <SoftTypography
              component={Link}
              to="/authentication/sign-in"
              variant="button"
              color="info"
              fontWeight="bold"
              textGradient
            >
              Sign in
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </IllustrationLayout>
  );
}

export default PasswordReset;
