import React, { useContext, useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

//Firebase
import FirebaseContext from "configs/firebasecontext";
import { useUserData } from "context/userManagement";

//Email Form
import { FeedbackForm } from "components/EmailForms";


function ContactUs() {

  return (
    <>
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container direction="column" spacing={2} alignItems="center">
        <Grid item>
        <SoftBox mb={2}>
          <SoftTypography variant="h5">Contact Us</SoftTypography>
        </SoftBox>
        <SoftBox mb={2}>
          <SoftTypography variant="body2" color="text">
            We always like to hear from our users. Please fill out the form below to give us any feedback, suggestion new features 
            or better ways we could help you with your LinkedIn outreach.
          </SoftTypography>
        </SoftBox>
        </Grid>
        <Grid item>
        <SoftBox mb={2}>
          <FeedbackForm />
        </SoftBox>
        </Grid>
        </Grid>
    </DashboardLayout>
    <Footer />
    </>
  );  
}

export default ContactUs;