import React, { useState } from 'react';
import { Modal, Grid, FormControlLabel, Switch, Divider, Button, Typography, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftDatePicker from 'components/SoftDatePicker';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import PropTypes from 'prop-types';

const SchedulePostModal = ({
  open,
  onClose,
  scheduleMode,
  setScheduleMode,
  selectedTime,
  setSelectedTime,
  handleConfirmSchedule,
  customSchedule,
  initialComment,
  setInitialComment,
  nextSlot,
  isScheduling,
  isScheduled,
}) => {
  const [isInitialCommentEnabled, setIsInitialCommentEnabled] = useState(false);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="schedule-post-modal"
      aria-describedby="schedule-post-modal-description"
    >
      <SoftBox
        sx={{
          ...modalStyle,
        }}
      >
        {isScheduling || !isScheduled ? (
          <>
            <SoftTypography variant="h4" fontWeight="bold" mb={2}>
              Schedule Post
            </SoftTypography>
            <Divider sx={{ mb: 2 }} />
            <Grid container direction="column" spacing={2} alignItems="center">

              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <Grid item xs={5}>
                  <SoftTypography variant="body2" mb={1}>
                    Manually Schedule
                  </SoftTypography>
                  <SoftDatePicker
                    input={{ placeholder: "Select date and time" }}
                    options={{ enableTime: true }}
                    onChange={(time) => setSelectedTime(time)}
                  />
                </Grid>

                <Grid item xs={2} container justifyContent="center">
                  <Typography variant="body2" align="center">
                    OR
                  </Typography>
                </Grid>

                <Grid item xs={5}>
                  <SoftTypography variant="body2" mb={1}>
                    Add to Queue
                  </SoftTypography>
                  <Button variant="outlined" disabled>
                    {nextSlot ? nextSlot.toLocaleString() : 'No slots available'}
                  </Button>
                </Grid>
              </Grid>

              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <Grid item xs={5}>
                  <SoftButton variant="contained" color="primary" size="small" onClick={handleConfirmSchedule}>
                    {isScheduling ? 'Scheduling...' : 'Schedule'}
                  </SoftButton>
                </Grid>

                <Grid item xs={5}>
                  <SoftButton variant="contained" color="primary" size="small" onClick={handleConfirmSchedule}>
                    {isScheduling ? 'Scheduling...' : 'Add to queue'}
                  </SoftButton>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ width: '100%' }} />
              </Grid>

              <Grid item xs={12}>
                <SoftBox display="flex" flexDirection="column" alignItems="flex-start" mb={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isInitialCommentEnabled}
                        onChange={() => setIsInitialCommentEnabled(!isInitialCommentEnabled)}
                        name="initialCommentEnabled"
                        color="primary"
                      />
                    }
                    label={
                      <SoftTypography variant="body2" fontWeight="bold">
                        Auto plug (comment)
                        <Tooltip 
                          title="Automatically add an initial comment to the post"
                          position="start-right"
                        >
                          <IconButton size="small">
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </SoftTypography>
                    }
                  />
                  {isInitialCommentEnabled && (
                    <SoftInput
                      placeholder="Initial Comment"
                      value={initialComment}
                      onChange={(e) => setInitialComment(e.target.value)}
                      sx={{ mt: 2 }}
                    />
                  )}
                </SoftBox>
              </Grid>
            </Grid>
          </>
        ) : (
          <SoftBox textAlign="center">
            <img src="https://firebasestorage.googleapis.com/v0/b/contentbuddy-ai.appspot.com/o/application%2Fimages%2Fwork_success_generated.jpg?alt=media&token=05aa126b-8436-414c-b8f3-52fd2a78b6a5" alt="Post scheduled" style={{ width: '100px', marginBottom: '20px' }} />
            <SoftTypography variant="h4" fontWeight="bold" mb={2}>
              Post Scheduled!
            </SoftTypography>
            <SoftTypography variant="body1" mb={2}>
              Your post has been scheduled successfully.
            </SoftTypography>
            <SoftBox display="flex" justifyContent="center" gap={2} mt={2}>
              <SoftButton
                variant="contained"
                color="info"
                onClick={onClose}
              >
                Create New Post
              </SoftButton>
            </SoftBox>
          </SoftBox>
        )}
      </SoftBox>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  maxWidth: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

SchedulePostModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  scheduleMode: PropTypes.string.isRequired,
  setScheduleMode: PropTypes.func.isRequired,
  selectedTime: PropTypes.instanceOf(Date),
  setSelectedTime: PropTypes.func.isRequired,
  handleConfirmSchedule: PropTypes.func.isRequired,
  customSchedule: PropTypes.object,
  initialComment: PropTypes.string.isRequired,
  setInitialComment: PropTypes.func.isRequired,
  nextSlot: PropTypes.instanceOf(Date),
  isScheduling: PropTypes.bool.isRequired,
  isScheduled: PropTypes.bool.isRequired,
};

export default SchedulePostModal;
