import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { Tooltip, Dialog, LinearProgress, Drawer } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import DataTable from "examples/Tables/DataTable";

function DetailedInformationDialog({ isOpen, onClose, actorDetails, reactions }) {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saveToLeadsList, setSaveToLeadsList] = useState([]);

  // Function to handle adding a user to the leads list
  const handleAddToLeadsList = (actor) => {
    setSaveToLeadsList([...saveToLeadsList, actor]);
  };

  // Use Effect to console log the leads list
  useEffect(() => {
    console.log("Leads List: ", saveToLeadsList);
  }, [saveToLeadsList]);

  // Normalize the actor list
  const normalizeActorList = async (actorList) => {

    const person = actorList?.persons?.[0] || {};
    const organization = actorList?.organizations?.[0] || {};
    const impersonator = actorList?.impersonators?.[0] || {};

    // Check if any of the types have valid data
    if (Object.keys(person).length === 0 && Object.keys(organization).length === 0 && Object.keys(impersonator).length === 0) {
      return null;
    }

    // Determine type based on presence of data
    const type = Object.keys(person).length > 0
      ? "persons"
      : Object.keys(organization).length > 0
        ? "organizations"
        : "impersonators";

    switch (type) {
      case "persons":
        return {
          name: `${person?.localizedFirstName} ${person?.localizedLastName}` || "N/A",
          profileDetails: person?.localizedHeadline || "N/A",
          vanityName: person?.vanityName || "N/A",
          profileId: person?.id || "N/A",
          profileLink: `https://www.linkedin.com/in/${person?.vanityName}`,
          profilePic: person?.profilePicture?.displayImage || "N/A",
        };
      case "organizations":
        return {
          name: organization?.localizedName || "N/A",
          profileDetails: organization?.localizedWebsite || "N/A",
          vanityName: organization?.vanityName || "N/A",
          profileId: organization?.id || "N/A",
          profileLink: `https://www.linkedin.com/company/${organization?.vanityName}`,
          profilePic: organization?.logoV2?.original || "N/A",
          impersonatorName: `${impersonator?.localizedFirstName} ${impersonator?.localizedLastName}` || "N/A",
          impersonatorId: impersonator?.id || "N/A",
          impersonatorProfileLink: `https://www.linkedin.com/in/${impersonator?.vanityName}`,
        };
      default:
        return null;
    }
  };

  // Async Function to Find the Details from ID Keys
  const findDetailsFromLists = async (personList, organizationList, personId, impersonatorId, organizationId) => {
    // Use the correct key to access person profiles
    // Ensures the difference in keys for organisations and persons are handled correctly
    const personKey = Object.keys(personList).find(key => key.includes(personId));
    const impersonatorKey = Object.keys(personList).find(key => key.includes(impersonatorId));

    const person = personList[personKey] || {};
    const organization = organizationList[organizationId] || {};
    const impersonator = personList[impersonatorKey] || {};

    return { person, organization, impersonator };
  }

  // Fetch the normalized actor list
  useEffect(() => {
    const fetchNormalizedActors = async () => {
      const personList = actorDetails?.persons?.results || {};
      const organizationList = actorDetails?.organizations?.results || {};

      const reactionPromises = reactions.map(async (reaction) => {
        const personId = reaction?.created?.actor.split(":").pop();
        const organizationId = reaction?.created?.actor.includes("urn:li:organization:") 
          ? reaction?.created?.actor.split(":").pop() 
          : null;
        const impersonatorId = reaction?.created?.impersonator 
          ? reaction?.created?.impersonator.split(":").pop() 
          : null;

        const { person, organization, impersonator } = await findDetailsFromLists(personList, organizationList, personId, impersonatorId, organizationId);

        const profileList = await normalizeActorList({ persons: [person], organizations: [organization], impersonators: [impersonator] });

        // Check if profileList is empty
        if (!profileList) {
            return {
                name: "N/A",
                profileDetails: "N/A",
                profileLink: "N/A",
                reactionType: reaction.reactionType,
                impersonator: impersonatorId ? "N/A" : "N/A",
                addToLeadsList: "N/A",
            };
            }

        return {
          name: <a href={profileList?.profileLink} target="_blank" rel="noreferrer">{profileList?.name}</a>,
          profileDetails: profileList?.profileDetails?.substring(0, 60) + "..." || "N/A",
          reactionType: reaction?.reactionType,
          impersonator: profileList?.impersonatorId ? <a href={profileList?.impersonatorProfileLink} target="_blank" rel="noreferrer">{profileList?.impersonatorName}</a> : "N/A",
          // addToLeadsList: <Tooltip title="Add to Leads List">
          //                     <SoftButton variant="contained" size="small" color="primary" onClick={() => handleAddToLeadsList(profileList)}>Add</SoftButton>
          //                 </Tooltip>,
        };
      });

      const resultRows = await Promise.all(reactionPromises);
      setRows(resultRows);
      setLoading(false);
    };

    fetchNormalizedActors();
  }, [actorDetails, reactions]);

  if (loading) {
    return <LinearProgress />;
  }

  const drawerStyle = {
    padding: 2, // Add padding to the drawer
    width: "85%", // Default width for larger screens
    height: "95%", // Set a maximum height
    overflowY: "auto", // Set vertical overflow to auto
    bgcolor: '#FFFFFF', // Set the background color to white
    '@media (max-width: 768px)': {
        width: '100%',  // Full width on mobile screens
    },
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: drawerStyle
      }}
    >
      <SoftBox mb={2} p={2} display="flex" justifyContent="left" alignItems="left">
        <SoftTypography variant="h5" fontWeight="medium">Detailed Information</SoftTypography>
      </SoftBox>
      
      <SoftBox mb={2}>
        <DataTable
          table={{
            columns: [
              { Header: "Name", accessor: "name", width: "20%" },
              { Header: "Headline", accessor: "profileDetails", width: "50%" },
              { Header: "Reaction", accessor: "reactionType", width: "10%" },
              { Header: "Impersonator", accessor: "impersonator", width: "10%" },
            ],
            rows,
          }}
        />
      </SoftBox>

      <SoftBox style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <SoftButton variant="contained" size="small" color="dark" onClick={onClose}>Close</SoftButton>
      </SoftBox>
    </Drawer>
  );
}

DetailedInformationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  actorDetails: PropTypes.object.isRequired,
  reactions: PropTypes.array.isRequired,
};

export default DetailedInformationDialog;
