import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";

function Compass({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>custom-icon-6</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="m260-260 300-140 140-300-300 140-140 300Zm220-180q-17 0-28.5-11.5T440-480q0-17 11.5-28.5T480-520q17 0 28.5 11.5T520-480q0 17-11.5 28.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          fill={colors[color] ? colors[color].main : colors.dark.main}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

// Setting default values for the props of CustomSVG6
Compass.defaultProps = {
  color: "dark",
  size: "16px",
};

// Typechecking props for the CustomSVG6
Compass.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "white",
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Compass;
