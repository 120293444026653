import React, { useContext, useState, useEffect, useCallback } from "react";
import { useLocation } from 'react-router-dom';

// Core components
import { Grid, IconButton, Divider, Tooltip, MenuItem, Select } from "@mui/material";
import { EmojiEmotions, InsertPhoto, GifBox, Videocam, Build, AutoAwesome } from "@mui/icons-material"; // Import Build icon

// Import Styled Components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftSnackbar from "components/SoftSnackbar";

// Soft components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// LinkedIn Profile Card
import LinkedInProfileCard from "examples/Cards/ProfileCards/LinkedInProfileCard";

// Import Firebase Context
import FirebaseContext from "configs/firebasecontext";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

// Import User Context
import { useUserData } from "context/userManagement";

// CSS
import "index.css";

// Import Dropzone
import { useDropzone } from 'react-dropzone';

// Import Page Components
import { GiphySearch } from "./components/GiphySearch";
import { FreeTrialModal } from "./components/FreeTrialModal";
import SoftInput from "components/SoftInput";

// Import Database Functions
import { getUserOrganizations } from "components/DatabaseFunctions";

// Import Icons
import InfoIcon from '@mui/icons-material/Info';

// Emoji Picker
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

// Import Shared Functions
import { handleConfirmSchedule, handleStoreDraft, handlePostNow } from "components/SharedFunctions/postFunctions";
import { useNextAvailableTimeSlot } from "components/customSchedule";

// Import PostConfirmationModal
import PostConfirmationModal from "./components/PostConfirmationModal";

// Import SchedulePostModal
import SchedulePostModal from "./components/SchedulePostsModal";

// Import Database Functions
import { getExistingSchedule } from "components/DatabaseFunctions";

// Import OpenAICompletions Component
import { OpenAICompletions } from "./components/OpenAICompletions"; // Update the path as needed

const WritePost = () => {
  console.log("Write Post Component"); //

  const { db, auth, storage, functions } = useContext(FirebaseContext);
  const { userId, currentUserData, currentWorkspace } = useUserData();

  // Location for getting the post content from a redirection
  const location = useLocation();

  const [showImageUploadResultsModal, setShowImageUploadResultsModal] = useState(false);
  const [imageUploadResultMessage, setImageUploadResultModal] = useState("");
  const [showDropZone, setShowDropZone] = useState(false);
  const [postImageUrl, setPostImageUrl] = useState("");
  const [scheduledNavPosts, setScheduledNavPosts] = useState([]);
  const [postContent, setPostContent] = useState("");
  const [initialComment, setInitialComment] = useState("");
  const [imageMetaData, setImageMetaData] = useState("");
  const [displayGifSearch, setDisplayGifSearch] = useState(false);
  const [postingProfile, setPostingProfile] = useState("Personal");
  const [profilePic, setProfilePic] = useState("");
  const [name, setName] = useState("");
  const [headline, setHeadline] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [shouldTruncateText, setShouldTruncateText] = useState(true);
  const [showPicker, setShowPicker] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [showAITools, setShowAITools] = useState(false); // State for AI Tools drawer

  // Posting Status Variables
  const [isPosting, setIsPosting] = useState(false);
  const [isPostSuccess, setIsPostSuccess] = useState(false);

  const [scheduleMode, setScheduleMode] = useState('manual');
  const [customSchedule, setCustomSchedule] = useState(null);
  const [showVerificationBox, setShowVerificationBox] = useState(false);

  // Schedule Status Variables
  const [isScheduling, setIsScheduling] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);

  // Snackbar Variables
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  // Post Confirmation Modal
  const [postConfirmationModal, setPostConfirmationModal] = useState(false);

  // Schedule Post Modal
  const [postScheduleModal, setPostScheduleModal] = useState(false);

  // Free Trial Variables
  const [freeTrialModal, setFreeTrialModal] = useState(false);
  const [showFreeTrialNotice, setFreeTrialNotice] = useState(false);
  const [freeTrialRemaining, setFreeTrialRemaining] = useState(false);

  // Set Posting Profile Options
  const [postingProfileOptions, setPostingProfileOptions] = useState([]);
  const [linkedInPostingAccount, setLinkedInPostingAccount] = useState("");

  // Get next available slot for scheduling
  const nextSlot = useNextAvailableTimeSlot({
    db,
    workspaceId: currentWorkspace?.id,
    linkedInUID: linkedInPostingAccount,
  });

  // Get the post content from the location state when redirected from draft or scheduled posts
  useEffect(() => {
    if (location.state) {
      // Console Log for Debugging
      console.log("Location State:", location.state);

      const { post } = location.state;
      setPostContent(post.content);
      setPostImageUrl(post.postImage);
      setImageMetaData(post.postImageMetadata);
      setLinkedInPostingAccount(post.linkedInUID);
      setPostingProfile(post.postingProfile);
      setInitialComment(post.initialComment);
    }
  }, [location.state]);

  // Get the custom schedule for the user
  useEffect(() => {
    if (!currentWorkspace || !linkedInPostingAccount || !db) {
      return;
    }

    // Console Log the LinkedIn Posting Account
    console.log("Getting Custom Schedule for LinkedIn Posting Account:");
    console.log("LinkedIn Posting Account:", linkedInPostingAccount);

    async function fetchExistingSchedule() {
      const schedule = await getExistingSchedule(db, currentWorkspace.id, linkedInPostingAccount);
      setCustomSchedule(schedule);
    }
    fetchExistingSchedule();

  }, [currentWorkspace, linkedInPostingAccount, db]);

  const addEmoji = (emoji) => {
    setPostContent(prev => prev + emoji.native);
    setShowPicker(false);
  };

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    const file = acceptedFiles[0];
    if (file && validateFile(file)) {
      handleFileUpload(file);
    } else {
      console.error("File validation failed:", fileRejections);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  // Get the user's organizations and LinkedIn accounts
  useEffect(() => {
    if (!userId || !db || !currentWorkspace) {
      return;
    }
  
    const fetchOrganizationsData = async () => {
      const data = await getUserOrganizations(db, userId, currentWorkspace.id);
      const linkedInAccounts = currentWorkspace?.linkedInAccounts || {};
  
      const linkedInAccountsOptions = Object.values(linkedInAccounts).map((account) => ({
        value: account.profileURN,
        label: account.name,
        image: account.profilePic || "",
        type: "account", // to distinguish between accounts and organizations
      }));
  
      let connectedOrgsOptions = [];
      if (data) {
        const filteredData = data.filter((org) => currentWorkspace.connectedOrganizations.includes(org.organizationURN));
        setOrganizations(filteredData);

        console.log("Filtered Data:", filteredData);
  
        connectedOrgsOptions = filteredData.map((org) => ({
          value: org.organizationURN,
          label: org.name,
          image: org.logoUrl || "",
          type: "organization", // to distinguish between accounts and organizations
        }));
      }
  
      const combinedOptions = [...linkedInAccountsOptions, ...connectedOrgsOptions];
      setPostingProfileOptions(combinedOptions);
  
      if (combinedOptions.length > 0) {
        setLinkedInPostingAccount(combinedOptions[0].value);
        setPostingProfile(combinedOptions[0].type === 'organization' ? 'Organisation' : 'Personal');
        setProfilePic(combinedOptions[0].image);
        setName(combinedOptions[0].label);
        setHeadline(linkedInAccounts[combinedOptions[0].value]?.headline || "A PropelPosts User");
      }
    };
  
    fetchOrganizationsData();
  }, [userId, db, currentWorkspace]);
  
  useEffect(() => {
    console.log("Current User Data in main component:", currentUserData);
    if (currentUserData && currentUserData?.tenantData && currentUserData?.tenantData[0]?.subscription?.freeTrial === true) {
      console.log("Free Trial State:", currentUserData?.tenantData[0]?.subscription?.freeTrial);
      const currentDate = new Date().getTime();
      const expirationDate = new Date(currentUserData?.tenantData[0]?.subscription?.freeTrialEndDate._seconds * 1000).getTime();
      const daysRemaining = Math.floor((expirationDate - currentDate) / (1000 * 60 * 60 * 24));
      setFreeTrialRemaining(daysRemaining);
      setFreeTrialNotice(true);
      if (currentUserData?.tenantData[0]?.subscription?.freeTrial && expirationDate < currentDate) {
        setFreeTrialModal(true);
      }
    }
  }, [currentUserData]);

  const validateFile = (file) => {
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const validVideoTypes = ['video/mp4'];

    if (validImageTypes.includes(file.type) && file.size < 36152320) {
      return true;
    } else if (validVideoTypes.includes(file.type) && file.size >= 75 * 1024 && file.size <= 200 * 1024 * 1024) {
      return true;
    }

    setSnackbarMessage("Invalid file type or file size too large");
    setSnackbarSeverity("error");
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 5000);
  };

  const handleFileUpload = (file) => {
    if (!currentUserData || !file) {
      console.error("UserId or file is null");
      return;
    }

    const storageRef = ref(storage, `${currentUserData?.userData?.id}/uploads/${file.name}`);
    const metadata = { contentType: file.type };
    setImageMetaData(file.type);

    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        console.error("File upload error:", error);

        setSnackbarMessage("File upload failed: " + error.message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
        }, 5000);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          insertImageIntoEditor(downloadURL);

          setSnackbarMessage("File uploaded successfully");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          setTimeout(() => {
            setSnackbarOpen(false);
          }, 5000);

        });
      }
    );
  };

  const insertImageIntoEditor = (url) => {
    setPostImageUrl(url);
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      user.reload().then(() => {
        if (!user.emailVerified) {
          setSnackbarMessage("Please verify your email address to post");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setTimeout(() => {
            setSnackbarOpen(false);
          }, 5000);

          setShowVerificationBox(true);
          setTimeout(() => {
            setShowVerificationBox(false);
          }, 5000);
        } else {
          setShowVerificationBox(false);
        }
      });
    }
  }, [auth, userId]);

  // Character Count
  const text = postContent;
  const characterCount = text.length;
  let characterCountColor = 'dark';

  if (characterCount > 3000) {
    characterCountColor = 'error';
  } else if (characterCount > 2500) {
    characterCountColor = 'warning';
  } else {
    characterCountColor = 'dark';
  }

  const handlePostingProfileChange = (event) => {
    const selectedProfile = postingProfileOptions.find(profile => profile.value === event.target.value);
  
    setLinkedInPostingAccount(selectedProfile?.value || "");
    setProfilePic(selectedProfile?.image || "");
    setName(selectedProfile?.label || "");

    // Console Log the selected profile
    console.log("Selected Profile:", selectedProfile);
  
    if (selectedProfile?.type === 'organization') {
      setPostingProfile('Organisation');
    } else {
      setPostingProfile('Personal');
    }
  
    const linkedInAccounts = currentWorkspace?.linkedInAccounts || {};
    setHeadline(linkedInAccounts[selectedProfile?.value]?.headline || "A PropelPosts User");
  };   
  
  const handleSaveDraft = async () => {
    const linkedInUID = linkedInPostingAccount;

    const { saveStatus, saveComplete } = await handleStoreDraft(
      postingProfile,
      postContent,
      userId,
      db,
      postImageUrl,
      imageMetaData,
      initialComment,
      currentWorkspace.id,
      linkedInUID
    );

    if (saveStatus) {
      setSnackbarMessage(saveStatus);
      setSnackbarSeverity(saveComplete ? "success" : "error");
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 5000);
    }
  };

  const handleSchedulePost = () => {
    setPostScheduleModal(true);
  };

  const handleSchedulePostFromModal = async () => {
    setIsScheduling(true);

    const linkedInUID = linkedInPostingAccount;

    // Console Log the LinkedIn Posting Account and LinkedIn UID
    console.log("Schedule Post");
    console.log("LinkedIn Posting Account:", linkedInPostingAccount);
    console.log("LinkedIn UID:", linkedInUID);

    const { scheduleStatus, scheduleComplete } = await handleConfirmSchedule(
      scheduleMode, selectedTime, postContent, userId, db, postImageUrl, imageMetaData, initialComment, postingProfile, customSchedule, nextSlot, currentWorkspace.id, linkedInUID
    );

    if (scheduleStatus) {
      setIsScheduling(false);
      setSnackbarMessage(scheduleStatus);
      setSnackbarSeverity(scheduleComplete ? "success" : "error");
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 5000);
    }

    if (scheduleComplete) {
      setIsScheduled(true);
    }
  };

  const handlePublishPost = () => {
    setPostConfirmationModal(true);
  };

  const handlePostNowFromModal = async () => {
    setIsPosting(true);

    let currentWorkspaceId = currentWorkspace.id;

    // Console Log the Workspace and LinkedIn Account
    console.log("Current Workspace ID:", currentWorkspaceId);
    console.log("LinkedIn Posting Account:", linkedInPostingAccount);
    console.log("User Id:", userId);

    const { postStatus, postComplete } = await handlePostNow(
      postContent, postImageUrl, imageMetaData, initialComment, postingProfile, functions, userId, linkedInPostingAccount, currentWorkspaceId
    );

    if (postComplete) {
      setIsPosting(false);
      setIsPostSuccess(true);
    } else {
      setIsPosting(false);
      setIsPostSuccess(false);
    }

    if (postStatus) {
      setSnackbarMessage(postStatus);
      setSnackbarSeverity(postComplete ? "success" : "error");
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 5000);
    }
  };

  // Handle clearing the post content
  const handleClearPostContent = () => {
    setPostContent("");
    setPostImageUrl("");
    setInitialComment("");
  };

  // Handle Closing Post Confirmation Modal
  const handleClosePostConfirmationModal = () => {
    setPostConfirmationModal(false);

    if (isPostSuccess) {
      handleClearPostContent();
      setIsPostSuccess(false);
    }

  };

  // Handle Closing Post Schedule Modal
  const handleClosePostScheduleModal = () => {
    setPostScheduleModal(false);

    if (isScheduled) {
      handleClearPostContent();
      setIsScheduled(false);
    }

  };

  const renderProfileOption = (option) => (
    <MenuItem key={option.value} value={option.value}>
      <img src={option.image} alt={option.label} style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
      {option.label}
    </MenuItem>
  );  

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar scheduledNavPosts={scheduledNavPosts} />

        <FreeTrialModal open={freeTrialModal} currentUserData={currentUserData} />

        <SoftBox py={3}>
          <Grid container spacing={3}>

            <SoftSnackbar
              open={snackbarOpen}
              close={() => setSnackbarOpen(false)}
              dateTime={new Date().toLocaleString()}
              content={snackbarMessage}
              color={snackbarSeverity}
              icon="notifications"
              title="Notification"
            />

            {showFreeTrialNotice && (
              <Grid item xs={12}>
                <SoftBox display="flex" justifyContent="center" alignItems="center" mb={2}>
                  <SoftTypography variant="h6" fontWeight="bold" color="error">
                    {freeTrialRemaining} days remaining in your free trial
                  </SoftTypography>
                </SoftBox>
              </Grid>
            )}

            <Grid item xs={12}>
              <SoftBox mb={2}>
                <SoftTypography variant="h4" fontWeight="bold">
                  Create a Post
                  <Tooltip title="Create a post for LinkedIn from scratch" placement="right-start">
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </SoftTypography>
              </SoftBox>
              <Divider />
            </Grid>

            <Grid item xs={12} md={8} lg={8}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={() => setShowPicker(!showPicker)}>
                  <Tooltip title="Add Emoji">
                    <EmojiEmotions />
                  </Tooltip>
                </IconButton>
                <IconButton onClick={() => setShowAITools(true)}>
                  <Tooltip title="AI Post Improvement and Writing">
                    <AutoAwesome />
                  </Tooltip>
                </IconButton>
              </div>
              {postingProfileOptions.length > 0 && (
                <SoftBox>
                  <Select
                    size="small"
                    displayEmpty
                    value={linkedInPostingAccount}
                    onChange={handlePostingProfileChange}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>Select Profile</em>;
                      }

                      const selectedOption = postingProfileOptions.find(option => option.value === selected);
                      return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={selectedOption?.image || ""} alt="Profile Picture" style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
                          {selectedOption?.label || ""}
                        </div>
                      );
                    }}
                  >
                    {postingProfileOptions.map(renderProfileOption)}
                  </Select>
                </SoftBox>
              )}
            </SoftBox>

              {showPicker && (
                <Picker data={data} onEmojiSelect={addEmoji} />
              )}
              <SoftInput
                multiline rows={15}
                value={postContent}
                onChange={(e) => setPostContent(e.target.value)}
                placeholder="Add your content..."
                style={{ width: '100%', padding: '5px', fontFamily: 'Arial, sans-serif', fontSize: '16px' }}
              />
              {characterCount > 2500 && (
                <SoftBox mt={1} mb={1} style={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
                  <SoftTypography variant="caption" color={characterCountColor}>
                    {characterCount} / 3000 characters
                  </SoftTypography>
                </SoftBox>
              )}

              {showDropZone && (
                <SoftBox mt={2}>
                  <div {...getRootProps()} style={{ border: '2px dashed gray', padding: '20px', textAlign: 'center' }}>
                    <input {...getInputProps()} />
                    <p>Drag & drop a file here, or click to select one</p>
                  </div>
                </SoftBox>
              )}

              {displayGifSearch && (
                <SoftBox mt={2}>
                  <GiphySearch
                    setPostImageUrl={setPostImageUrl}
                    setImageMetaData={setImageMetaData}
                    insertImageIntoEditor={insertImageIntoEditor}
                    displayGifSearch={displayGifSearch}
                    setDisplayGifSearch={setDisplayGifSearch}
                  />
                </SoftBox>
              )}

              <SoftBox mt={2} display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Upload Image">
                    <IconButton onClick={() => setShowDropZone(!showDropZone)}><InsertPhoto /></IconButton>
                  </Tooltip>
                  <Tooltip title="Upload Video - Max 200MB">
                    <IconButton onClick={() => setShowDropZone(!showDropZone)}><Videocam /></IconButton>
                  </Tooltip>
                  <Tooltip title="Add GIF">
                    <IconButton onClick={() => setDisplayGifSearch(!displayGifSearch)}><GifBox /></IconButton>
                  </Tooltip>
                </SoftBox>
                <SoftBox>
                  <SoftButton 
                    variant="outlined" 
                    color="primary"
                    onClick={handleSaveDraft}
                  >
                    Save as Draft
                  </SoftButton>
                  <SoftButton 
                    variant="outlined" 
                    color="primary" 
                    style={{ marginLeft: '10px' }}
                    onClick={handleSchedulePost}
                  >
                    Schedule
                  </SoftButton>
                  <SoftButton 
                    variant="contained" 
                    color="primary" 
                    style={{ marginLeft: '10px' }}
                    onClick={handlePublishPost}
                  >
                    Publish
                  </SoftButton>
                </SoftBox>
              </SoftBox>

              <SoftBox mt={2} mb={2}>
                <SoftInput
                  size="large"
                  placeholder="Add an initial comment to your post"
                  value={initialComment}
                  onChange={(e) => setInitialComment(e.target.value)}
                />
              </SoftBox>
            </Grid>

            {linkedInPostingAccount && (
            <Grid item xs={12} md={4} lg={4}>
              <SoftTypography variant="h6" fontWeight="bold" mb={3}>
                LinkedIn Preview
              </SoftTypography>

              <LinkedInProfileCard
                key={`${postContent}-${postImageUrl}`}
                image={profilePic}
                name={name}
                headline={headline}
                postContent={postContent}
                postImage={postImageUrl}
                postImageMetaData={imageMetaData}
                setPostImageUrl={setPostImageUrl}
                shouldTruncateText={shouldTruncateText}
                setShouldTruncateText={setShouldTruncateText}
              />
            </Grid>
            )}
          </Grid>
        </SoftBox>
        <Footer />
      </DashboardLayout>

      <PostConfirmationModal
        open={postConfirmationModal}
        onClose={() => handleClosePostConfirmationModal()}
        onPostNow={handlePostNowFromModal}
        initialComment={initialComment}
        setInitialComment={setInitialComment}
        isPosting={isPosting}
        isPostSuccess={isPostSuccess}
      />

      <SchedulePostModal
        open={postScheduleModal}
        onClose={() => handleClosePostScheduleModal()}
        scheduleMode={scheduleMode}
        setScheduleMode={setScheduleMode}
        setSelectedTime={setSelectedTime}
        handleConfirmSchedule={handleSchedulePostFromModal}
        customSchedule={customSchedule}
        initialComment={initialComment}
        setInitialComment={setInitialComment}
        nextSlot={nextSlot}
        isScheduling={isScheduling}
        isScheduled={isScheduled}
      />

      {/* OpenAICompletions Component */}
      <OpenAICompletions
        postContent={postContent}
        setPostContent={setPostContent}
        setPostImageUrl={setPostImageUrl}
        setImageMetaData={setImageMetaData}
        setInitialComment={setInitialComment}
        open={showAITools} // Pass the state
        onClose={() => setShowAITools(false)} // Handle close action
        linkedInPostingAccount={linkedInPostingAccount} // Pass the LinkedIn Posting Account
        workspaceId={currentWorkspace?.id} // Pass the Workspace ID
      />
    </>
  );
};

export default WritePost;
