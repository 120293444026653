import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';
import SoftAvatar from 'components/SoftAvatar';

const PostTemplateCard = ({ image, label, title, description, action, authors, click }) => {
  const renderAuthors = authors.map(({ image: media, name }) => (
    <Tooltip key={name} title={name} placement="bottom">
      {/* <SoftAvatar
        src={media}
        alt={name}
        size="xs"
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          cursor: 'pointer',
          position: 'relative',
          ml: -1.25,
          '&:hover, &:focus': {
            zIndex: '10',
          },
        })}
      /> */}
    </Tooltip>
  ));

  const handleClick = () => {
    click(action.template);
  };

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'visible',
      }}
      onClick={handleClick}
    >
      {/* <SoftBox position="relative" width="100.25%" shadow="xl" borderRadius="xl">
        <CardMedia
          src={image}
          component="img"
          title={title}
          sx={{
            maxWidth: '100%',
            margin: 0,
            boxShadow: ({ boxShadows: { md } }) => md,
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
      </SoftBox> */}
      <SoftBox pt={3} px={0.5}>
        <SoftBox mb={1}>
          <SoftTypography variant="button" fontWeight="regular" textTransform="capitalize" textGradient>
            {label}
          </SoftTypography>
        </SoftBox>
        <SoftBox mb={1}>
          <SoftTypography variant="h5" textTransform="capitalize">
            {title}
          </SoftTypography>
        </SoftBox>
        <SoftBox mb={3} lineHeight={0}>
          <SoftTypography variant="button" fontWeight="regular" color="text">
            {description}
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center">
          <SoftButton variant="outlined" size="small" color={action.color}>
            {action.label}
          </SoftButton>
          <SoftBox display="flex">{renderAuthors}</SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
};

PostTemplateCard.defaultProps = {
  authors: [],
};

PostTemplateCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(['external', 'internal']),
    template: PropTypes.object.isRequired,
    color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'light', 'dark', 'white']).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  authors: PropTypes.arrayOf(PropTypes.object),
  click: PropTypes.func.isRequired,
};

export default PostTemplateCard;
