import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

// Import Styled Components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftSnackbar from "components/SoftSnackbar";
import SoftProgress from "components/SoftProgress";

// Soft components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import TimelineList from "./components/Timeline/TimelineList";
import TimelineItem from "./components/Timeline/TimelineItem";

// Import Firebase Context
import FirebaseContext from "configs/firebasecontext";

// Import User Context
import { useUserData } from "context/userManagement";

// Import Page Components
import { FreeTrialModal } from "./components/FreeTrialModal";

// Import Fetch Posts Database Function
import { fetchPosts } from "components/DatabaseFunctions";

// Import Onboarding Complete Modal
import OnBoardingComplete from "./components/onBoardingCompleteModal";

const Dashboard = () => {
  console.log("Dashboard Component");

  const { db, auth } = useContext(FirebaseContext);
  const { currentUserData, currentWorkspace } = useUserData();

  // Snackbar Variables
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  // Free Trial Variables
  const [freeTrialModal, setFreeTrialModal] = useState(false);
  const [showFreeTrialNotice, setFreeTrialNotice] = useState(false);
  const [freeTrialRemaining, setFreeTrialRemaining] = useState(false);

  // Onboarding Complete Modal
  const [onboardingCompleteModal, setOnboardingCompleteModal] = useState(false);

  // Posts in Workspace
  const [posts, setPosts] = useState([]);

  // Progress of Onboarding
  const [onboardingProgress, setOnboardingProgress] = useState("25");

  // Navigation
  const navigate = useNavigate();

  // Check free trial status  
  useEffect(() => {
    console.log("Current User Data in main component:", currentUserData);
    if (currentUserData && currentUserData?.tenantData && currentUserData?.tenantData[0]?.subscription?.freeTrial === true) {
      console.log("Free Trial State:", currentUserData?.tenantData[0]?.subscription?.freeTrial);
      const currentDate = new Date().getTime();
      const expirationDate = new Date(currentUserData?.tenantData[0]?.subscription?.freeTrialEndDate._seconds * 1000).getTime();
      const daysRemaining = Math.floor((expirationDate - currentDate) / (1000 * 60 * 60 * 24));
      setFreeTrialRemaining(daysRemaining);
      setFreeTrialNotice(true);
      if (currentUserData?.tenantData[0]?.subscription?.freeTrial && expirationDate < currentDate) {
        setFreeTrialModal(true);
      }
    }
  }, [currentUserData]);

  // Get the current posts for the workspace
  useEffect(() => {
    if (currentWorkspace) {
      // Call the fetchPosts function as a promise and set the return to the posts state
      fetchPosts(db, currentWorkspace.id).then((data) => {
        setPosts(data);
      });
    }
  }, [currentWorkspace]);

  // Verify email address
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      user.reload().then(() => {
        if (!user.emailVerified) {
          setSnackbarMessage("Please verify your email address to post");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setTimeout(() => {
            setSnackbarOpen(false);
          }, 5000);
        } 
      });
    }
  }, [auth]);

  // Placeholder Timeline Data
  const timelineData = [
    {
      color: "info",
      icon: "campaign",
      title: "Account Created",
      dateTime: "",
      description:
        "You've created your account, and are ready to get started!",
      complete: true,
    },
    {
      color: "success",
      icon: "vpn_key",
      title: "Connect your LinkedIn Account",
      dateTime: "",
      description:
        "Connect your LinkedIn account to start posting and getting more engagement",
      buttonText: "Connect LinkedIn",
      buttonAction: () => {
        navigate("/app/pages/account/workspace");
      },
      complete: currentWorkspace?.linkedInAccounts ? true : false,
    },
    {
      color: "dark",
      icon: "edit",
      title: "Publish your first post",
      dateTime: "",
      description:
        "Use our powerful AI to create your first post, and get more engagement",
      buttonText: "Create Post",
      buttonAction: () => {
        navigate("/app/pages/write-post");
      },
      complete: posts.length > 0 ? true : false,
    },
  ];

  // Render Timeline Items
  const renderTimelineItems = [
    ...timelineData.map(
      ({ color, icon, title, dateTime, description, badges, lastItem, buttonText, buttonAction, complete }) => (
        <TimelineItem
          key={title + color}
          color={color}
          icon={icon}
          title={title}
          dateTime={dateTime}
          description={description}
          badges={badges}
          lastItem={lastItem}
          buttonText={buttonText}
          buttonAction={buttonAction}
          complete={complete}
        />
      )
    ),
    <TimelineItem
      key="Unlock your free gift"
      color="primary"
      icon="lockopen"
      title="Unlock your free gift"
      dateTime=""
      description="Something special is waiting for you. Complete the steps to unlock it!"
      // complete={timelineData.every(item => item.complete)}
      buttonText="Get Your Free Gift"
      buttonAction={() => setOnboardingCompleteModal(true)}
    />
  ];

  // UseEffect to Update Progress as Onboarding Steps are Completed
  useEffect(() => {
    const completedSteps = timelineData.filter((item) => item.complete).length;
    const totalSteps = timelineData.length;
    const progress = (completedSteps / totalSteps) * 100;
    setOnboardingProgress(progress);
  }
  , [timelineData]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox py={3}>
          <Grid container spacing={3}>

            {showFreeTrialNotice && (
              <Grid item xs={12}>
                <SoftBox display="flex" justifyContent="center" alignItems="center" mb={2}>
                  <SoftTypography variant="h6" fontWeight="bold" color="error">
                    {freeTrialRemaining} days remaining in your free trial
                  </SoftTypography>
                </SoftBox>
              </Grid>
            )}

            <Grid item xs={12}>
              <SoftBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={2}>
                <SoftBox display="flex" mb={2}>
                  <SoftTypography variant="h4" fontWeight="bold">
                    Welcome to PropelPosts
                  </SoftTypography>
                </SoftBox>
                <SoftBox display="flex" mb={2}>
                  <SoftTypography variant="body2" fontWeight="regular">
                    Complete the welcome steps, and unlock your free gift!
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </Grid>

            <Grid item xs={12}>
              <SoftBox mb={2}>
                <SoftProgress variant="contained" value={Math.round(onboardingProgress)} label />
              </SoftBox>
            </Grid>

            <Grid item xs={12}>
            <SoftBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={2}>
              <TimelineList title="Get started">{renderTimelineItems}</TimelineList>
            </SoftBox>
            </Grid>

          </Grid>
        </SoftBox>
        <Footer />
      </DashboardLayout>

      <SoftSnackbar
        open={snackbarOpen}
        close={() => setSnackbarOpen(false)}
        dateTime={new Date().toLocaleString()}
        content={snackbarMessage}
        color={snackbarSeverity}
        icon="notifications"
        title="Notification"
      />

      <FreeTrialModal open={freeTrialModal} currentUserData={currentUserData} />

      <OnBoardingComplete open={onboardingCompleteModal} onClose={() => setOnboardingCompleteModal(false)} />
    </>
  );
};

export default Dashboard;
