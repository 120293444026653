import React, { useContext, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

// @mui material components
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftSnackbar from "components/SoftSnackbar";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Firebase
import FirebaseContext from "configs/firebasecontext";
import { useUserData } from "context/userManagement";

// Import Page Components
import TextArea from "components/TextEntry";
import PostGrid from "./components/PostGrid";

// Import Required Database Functions
import { fetchAiProfile, getUserOrganizations, storeDraft, schedulePost, getExistingSchedule } from "components/DatabaseFunctions";

// Import HTTPSCallable
import { httpsCallable } from "@firebase/functions";

// Import Custom Schedule Next Available Slot Function
import { getNextAvailableTimeSlot } from "components/customSchedule/getNextAvailableSlot";

// Import Icons
import InfoIcon from '@mui/icons-material/Info';

function LongFormToPost() {
    const { db, functions } = useContext(FirebaseContext);
    const { userId, userData, currentWorkspace, currentUserData } = useUserData();
    const navigate = useNavigate();

    const [postContent, setPostContent] = useState('');
    const [postAudience, setPostAudience] = useState('');
    const [postStyle, setPostStyle] = useState('');
    const [numberOfPosts, setNumberOfPosts] = useState(0);
    const [aiProfile, setAiProfile] = useState('');
    const [useAiProfile, setUseAIProfile] = useState(false);
    const [aiResponseText, setAiResponseText] = useState('');
    const [postsArray, setPostsArray] = useState([]);
    const [openScheduleModal, setOpenScheduledModal] = useState(false);
    const [customSchedule, setCustomSchedule] = useState(null);
    const [scheduledDates, setScheduledDates] = useState([]);

    // State for the Snackbar
    const [postNowSuccess, setPostNowSuccess] = useState(false);
    const [modalPostResultsMessage, setModalPostResultsMessage] = useState('');
    const [showPostResultsModal, setShowPostResultsModal] = useState(false);

    // State for URL scraping for content
    const [urlInput, setUrlInput] = useState('');
    const [isScraping, setIsScraping] = useState(false);

    // State for the PostGrids
    const [openPostGrid, setOpenPostGrid] = useState(false);

    // State for the Lottie Animation while Waiting for OpenAI Response
    const [lottieState, setLottieState] = useState(false);

    // State for handling LinkedIn profile selection
    const [selectedLinkedInProfile, setSelectedLinkedInProfile] = useState(null);
    const [profilePic, setProfilePic] = useState("");
    const [name, setName] = useState("");
    const [organizations, setOrganizations] = useState([]);
    const [postingProfileOptions, setPostingProfileOptions] = useState([]);

    // Console Log
    console.log("Current User Data:", currentUserData);

    // Get AI Profile from Database on load - this is not currently used
    // useEffect(() => {
    //     if (!currentWorkspace || !selectedLinkedInProfile) return;

    //     const fetchAiProfileData = async () => {
    //         console.log("Fetching AI Profile...");
    //         try {
    //             const aiProfileData = await fetchAiProfile(db, currentWorkspace.id, selectedLinkedInProfile);
    //             setAiProfile(aiProfileData);
    //         } catch (error) {
    //             console.error("Error fetching AI Profile:", error);
    //         }
    //     };

    //     fetchAiProfileData();
    // }, [db, userId, currentWorkspace, selectedLinkedInProfile]);

    // Get the custom schedule for the selected LinkedIn profile - this is not currently used
    // useEffect(() => {
    //     if (!db || !currentWorkspace || !selectedLinkedInProfile) {
    //         return;
    //     }

    //     const fetchCustomSchedule = async () => {
    //         const schedule = await getExistingSchedule(db, currentWorkspace.id, selectedLinkedInProfile);
    //         if (schedule) {
    //             setCustomSchedule(schedule);
    //         }
    //     };

    //     fetchCustomSchedule();

    // }, [db, currentWorkspace, selectedLinkedInProfile]);

    // Function to scrape content from a URL
    const handleScrape = async () => {
        console.log("Scraping Content...");
        setIsScraping(true);

        try {
            // Call the Google Cloud function to scrape the content
            console.log("Scraping Content...");
            console.log("URL Input:", urlInput);
            const scrapedContent = await httpsCallable(functions, process.env.REACT_APP_CLOUD_FUNCTION_SCRAPECONTENT)({ urlInput });
            setPostContent(scrapedContent.data.content);
            setIsScraping(false);
            setPostNowSuccess(true);
            setModalPostResultsMessage("Content retrieved successfully!");
            setShowPostResultsModal(true);
            setTimeout(() => {
                setShowPostResultsModal(false);
            }, 5000);
        } catch (error) {
            console.error("Error scraping content:", error);
            setIsScraping(false);
            setPostNowSuccess(false);
            setModalPostResultsMessage("Error retrieving content!");
            setShowPostResultsModal(true);
            setTimeout(() => {
                setShowPostResultsModal(false);
            }, 5000);
        }
    };

    // Function to call the OpenAI handleLongformConversion function
    const onSubmit = async () => {
        console.log("Submitting Longform Content to be Converted to Posts...");
        if (!postContent || !postAudience || !postStyle || !numberOfPosts) {
            setModalPostResultsMessage("Please fill in all fields!");
            setPostNowSuccess(false);
            setShowPostResultsModal(true);
            setTimeout(() => {
                setShowPostResultsModal(false);
            }, 5000);
            return;
        }

        // Set the Lottie Animation to true
        setLottieState(true);

        const tenantId = currentUserData?.tenantData[0]?.id;

        // Set AI Profile to False Until Built Into Render
        setUseAIProfile(false);

        try {
            // Call the OpenAI function to convert the longform content to posts
            const response = await httpsCallable(functions, process.env.REACT_APP_CLOUD_FUNCTION_OPENAI_LONGFORM)({
                tenantId,
                postContent,
                postAudience,
                postStyle,
                numberOfPosts,
                aiProfile,
                useAiProfile,
            });

            console.log("AI Response Data:", response.data);

            // Set the posts array to the response data
            setPostsArray(response.data);

            // Set the Lottie Animation to false
            setLottieState(false);

            // Open the PostGrid
            setOpenPostGrid(true);

        } catch (error) {
            console.error("Error converting longform content to posts:", error);
            setModalPostResultsMessage("Error converting longform content to posts!");
            setPostNowSuccess(false);
            setShowPostResultsModal(true);
            setTimeout(() => {
                setShowPostResultsModal(false);
            }, 5000);
        }
    };


    // Handle style change for the SoftSelect component
    const handleStyleChange = (selectedOptions) => {
        // Assuming the SoftSelect returns an array of { value, label } objects
        const selectedStyles = selectedOptions.map(option => option.value);
        setPostStyle(selectedStyles);
    };


    // Console Log The Text Box Input
    useEffect(() => {
        console.log("Post Content:", postContent);
    }, [postContent]);

    return (
        <>
            <SoftSnackbar
                open={showPostResultsModal}
                close={() => setShowPostResultsModal(false)}
                content={modalPostResultsMessage}
                dateTime={new Date().toLocaleString()}
                title="Notification"
                color={postNowSuccess ? 'success' : 'error'}
                icon="notifications"
            />
            <DashboardLayout>
                <DashboardNavbar />
                {/* Remove the conditional rendering block here */}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SoftBox>
                            <SoftTypography variant="h4" fontWeight="bold">
                                Convert Longform Content to Posts
                                <Tooltip
                                    title="Convert longform content such as blogs and articles into multiple posts for LinkedIn"
                                    placement="right-start"
                                >
                                    <IconButton size="small">
                                        <InfoIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                    <Grid item xs={6}>
                        <SoftBox pt={2} pb={1} px={2}>
                            <SoftInput
                                placeholder="Enter URL to get content from"
                                value={urlInput}
                                onChange={(e) => setUrlInput(e.target.value)}
                            />
                        </SoftBox>
                        <SoftBox pt={1} pb={2} px={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <SoftButton size="small" variant="contained" color="primary" onClick={handleScrape}>
                                {isScraping ? <CircularProgress size={20} color="inherit" /> : "Get Content"}
                            </SoftButton>
                        </SoftBox>
                        <SoftBox pb={2} px={2}>
                            <TextArea
                                postContent={postContent}
                                setPostContent={setPostContent}
                                placeholderText="Paste longform content here..."
                                showEmojiPicker={false}
                            />
                        </SoftBox>
                    </Grid>
                    <Grid item xs={6}>
                        <SoftBox pt={1} pb={2} px={2}>
                            <Card>
                                <SoftBox pt={3} px={2}>
                                    <SoftTypography variant="h6" fontWeight="medium">
                                        Who is the audience for this post?
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox pt={1} pb={2} px={2}>
                                    <SoftInput
                                        placeholder="Audience"
                                        style={{ width: "100%" }}
                                        onChange={(event) => setPostAudience(event.target.value)}
                                    />
                                </SoftBox>
                            </Card>
                        </SoftBox>
                        <SoftBox pt={1} pb={2} px={2}>
                            <Card>
                                <SoftBox pt={3} px={2}>
                                    <SoftTypography variant="h6" fontWeight="medium">
                                        Style of post (select all that apply)
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox pt={1} pb={2} px={2}>
                                    <SoftSelect
                                        menuPortalTarget={document.body}
                                        isMulti={true}
                                        color="secondary"
                                        options={[
                                            { value: 'informative', label: 'Informative' },
                                            { value: 'educational', label: 'Educational' },
                                            { value: 'entertaining', label: 'Entertaining' },
                                            { value: 'inspirational', label: 'Inspirational' },
                                            { value: 'motivational', label: 'Motivational' },
                                            { value: 'thought-provoking', label: 'Thought-Provoking' },
                                            { value: 'persuasive', label: 'Persuasive' },
                                            { value: 'professional', label: 'Professional' },
                                            { value: 'casual', label: 'Casual' },
                                            { value: 'formal', label: 'Formal' },
                                            { value: 'humorous', label: 'Humorous' },
                                            { value: 'emotional', label: 'Emotional' },
                                        ]}
                                        onChange={handleStyleChange}
                                    />
                                </SoftBox>
                            </Card>
                        </SoftBox>
                        <SoftBox pt={1} pb={2} px={2}>
                            <Card>
                                <SoftBox pt={3} px={2}>
                                    <SoftTypography variant="h6" fontWeight="medium">
                                        How many posts should be created?
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox pt={1} pb={2} px={2}>
                                    <SoftSelect
                                        menuPortalTarget={document.body}
                                        options={[
                                            { value: 1, label: "1" },
                                            { value: 2, label: "2" },
                                            { value: 3, label: "3" },
                                            { value: 4, label: "4" },
                                            { value: 5, label: "5" },
                                            { value: 6, label: "6" },
                                        ]}
                                        onChange={(selectedOption) => setNumberOfPosts(selectedOption.value)}
                                    />
                                </SoftBox>
                            </Card>
                        </SoftBox>
                        {/* <SoftBox pt={1} pb={2} px={2}>
                            <Card>
                                <SoftBox pt={3} px={2}>
                                    <SoftTypography variant="h6" fontWeight="medium">
                                        Do you want to use your AI Profile for writing these posts?
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox pt={1} pb={2} px={2}>
                                    <SoftSelect
                                        menuPortalTarget={document.body}
                                        options={aiProfile ?
                                            [{ value: true, label: "Yes" }, { value: false, label: "No" }] :
                                            [{ value: false, label: "No AI Profile Setup" }]
                                        }
                                        onChange={(selectedOption) => setUseAIProfile(selectedOption.value)}
                                    />
                                </SoftBox>
                            </Card>
                        </SoftBox> */}
                        <SoftBox pt={1} px={2} pb={2}>
                            <Card>
                                <SoftButton
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={onSubmit}
                                >
                                    {lottieState ? <CircularProgress size={20} color="inherit" /> : "Convert to Posts"}
                                </SoftButton>
                            </Card>
                        </SoftBox>
                    </Grid>
                </Grid>
                <PostGrid
                    posts={postsArray}
                    userData={userData}
                    onOpen={openPostGrid}
                    onClose={() => setOpenPostGrid(false)}
                    currentWorkspace={currentWorkspace}
                    db={db}
                    userId={userId}
                />
                <Footer />
            </DashboardLayout>
        </>
    );
}

export default LongFormToPost;
