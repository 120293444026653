import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import App from "App";
import LandingPage from "landing";
import { app, db, functions, auth, storage } from "./configs/firebase";
import FirebaseContext from "configs/firebasecontext";
import { UserDataProvider } from "context/userManagement";
import { SoftUIControllerProvider } from "context";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// Import React Query
import { QueryClient, QueryClientProvider } from "react-query";

// Import Sentry
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV === 'production') {
  console.log = function () {};
}

Sentry.init({
  dsn: "https://39db802cb97edd14f12b3095222bef7d@o4506616207310848.ingest.us.sentry.io/4507546123173888",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "https://app.propelposts.com", "https://app.propelposts.com/app", "https://propelposts.com/app"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();

root.render(
  <Router>
    <SoftUIControllerProvider>
      <FirebaseContext.Provider value={{ app, db, functions, auth, storage }}>
        <QueryClientProvider client={queryClient}>
        <UserDataProvider>
          <Elements stripe={stripePromise}>

              <App />

          </Elements>
        </UserDataProvider>
        </QueryClientProvider>
      </FirebaseContext.Provider>
    </SoftUIControllerProvider>
  </Router>
);
