import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// Import Soft UI
import SoftButton from 'components/SoftButton';

const ViewPostModal = ({ open, onClose, post }) => {
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate("../pages/write-post/", { state: { post } });
  };

  const renderMedia = () => {
    if (post.postImage) {
      // Console log the post image URL and metadata for debugging
      console.log("Post Image URL in ViewPostModal:", post.postImage);
      console.log("Post Image Metadata in ViewPostModal:", post.postImageMetadata);
      // Check if the post image is an image or video
      if (post.postImageMetadata.startsWith('image/')) {
        return <img src={post.postImage} alt="Post Media" style={{ width: '100%', marginTop: '16px' }} />;
      } else if (post.postImageMetadata.startsWith('video/')) {
        return (
          <video controls style={{ width: '100%', marginTop: '16px' }}>
            <source src={post.postImage} type={post.postImageMetadata} />
            Your browser does not support the video tag.
          </video>
        );
      }
    }
    return null;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="view-post-modal"
      aria-describedby="view-post-modal-description"
    >
      <Box sx={{ ...modalStyle }}>
        <Typography variant="body2" fontWeight="regular" mb={2} sx={{ whiteSpace: 'pre-line' }}>
          {post.content}
        </Typography>
        <Typography variant="caption" color="dark" fontWeight="bold" gutterBottom>
          {post.postedTimestamp
            ? `Published on ${new Date(post.postedTimestamp.seconds * 1000).toLocaleString()}`
            : post.scheduledTimestamp
            ? `Scheduled for ${new Date(post.scheduledTimestamp.seconds * 1000).toLocaleString()}`
            : 'Draft'}
        </Typography>
        {renderMedia()}
        <Box mt={2} textAlign="right">
          <SoftButton variant="contained" color="primary" onClick={handleEdit}>
            Edit
          </SoftButton>
        </Box>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
  overflowY: 'auto',
  maxHeight: '80vh',
};

ViewPostModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
};

export default ViewPostModal;
