import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

// Import MUI Components
import { Grid, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { BookmarkAdd, OpenInNew } from '@mui/icons-material';

// Import Soft UI Components
import SoftBox from 'components/SoftBox';
import SoftSelect from 'components/SoftSelect';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';
import SoftInput from 'components/SoftInput';
import SoftSnackbar from 'components/SoftSnackbar';
import SoftBadge from 'components/SoftBadge';

// PropTypes
import PropTypes from 'prop-types';

// Lodash
import _ from 'lodash';

const EngageProfiles = ({ linkedInProfiles, saveProfileToList, getLists, createList, db, currentWorkspace }) => {
    const [lists, setLists] = useState([]);
    const [profilesToSave, setProfilesToSave] = useState([]);
    const [selectedList, setSelectedList] = useState(null);
    const [listOptions, setListOptions] = useState([]);
    const [newListName, setNewListName] = useState("");
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    // Snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    // Get all lists
    useEffect(() => {
        getLists(db, currentWorkspace.id).then((returnedLists) => {
            if (!returnedLists) {
                return;
            }
            setLists(returnedLists);

            // Check if the returned lists are already in the list options
            const listOptions = returnedLists.map((list) => ({ value: list.id, label: list.name }));
            setListOptions(listOptions);
        });
    }, [getLists, db, currentWorkspace.id]);

    // Handle opening and closing dialog
    const handleDialogOpen = () => {
        setIsDialogOpen(true);
    };

    // Using lodash debounce to limit the frequency of state updates
    const debouncedSetNewListName = useCallback(
        _.debounce((value) => {
            setNewListName(value);
        }, 300),
        []
    );

    const handleChange = useCallback((event) => {
        debouncedSetNewListName(event.target.value);
    }, [debouncedSetNewListName]);

    // Handle create new list
    const handleCreateList = ({ listName }) => {
        if (!listName) {
            return;
        }

        // Check if the list name already exists
        if (lists.some((list) => list.name === listName)) {
            setSnackbarMessage("List already exists!");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
            setTimeout(() => {
                setOpenSnackbar(false);
            }, 3000);
            return;
        }

        // Create a new list and set the returned id and list name
        createList(db, currentWorkspace.id, listName).then((newList) => {
            setLists((prevState) => [...prevState, newList]);
            setListOptions((prevState) => [...prevState, { value: newList.id, label: newList.name }]);
            setSnackbarMessage("List created successfully!");
            setSnackbarSeverity("success");
            setOpenSnackbar(true);
            setTimeout(() => {
                setOpenSnackbar(false);
            }, 3000);
            setIsDialogOpen(false);
        });
    };

    // Handle save profile to list when bookmark is clicked
    const handleSaveProfileToList = ({ profile }) => {
        if (!selectedList) {
            setSnackbarMessage("Please select a list to save profiles to!");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
            setTimeout(() => {
                setOpenSnackbar(false);
            }, 2000);
            return;
        }

        if (selectedList && profile) {
            // Save the profile to the selected list
            saveProfileToList(db, currentWorkspace.id, profile, selectedList.value).then(() => {
                setSnackbarMessage("Profile saved successfully!");
                setSnackbarSeverity("success");
                setOpenSnackbar(true);
                setTimeout(() => {
                    setOpenSnackbar(false);
                }, 2000);

                // Add the profile to the profiles to save
                setProfilesToSave((prevState) => [...prevState, profile]);
            });
        }
    };

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                        <SoftButton onClick={handleDialogOpen} variant="outlined" color="primary" size="small">
                            Create New List
                        </SoftButton>
                        <SoftSelect
                            placeholder="Select List"
                            options={listOptions}
                            value={selectedList}
                            onChange={(newValue) => setSelectedList(newValue)}
                        />
                    </SoftBox>
                </Grid>

                <Grid item xs={12}>
                    <SoftBox display="flex" justifyContent="center" alignItems="center">
                        <SoftTypography variant="body2" fontWeight="regular" textAlign="center">
                            See all the profiles that have engaged with your content below.
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox display="flex" justifyContent="center" alignItems="center" mt={2}>
                        <SoftTypography variant="body2" fontWeight="regular" textAlign="center">
                            Directly view the profile by clicking on the link icon. Or save the profile to a list by clicking on the bookmark icon. 
                            You can export the profiles to a CSV file by clicking on the export button for the list you want to export under &quot;List Management&quot;.
                        </SoftTypography>
                    </SoftBox>
                </Grid>

                {linkedInProfiles?.map((profile) => (
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        key={profile?.id + Math.random()}
                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
                    >
                        <Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexGrow: 1 }}>
                            <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexGrow: 1 }}>
                                <SoftBox style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexGrow: 1 }}>
                                    <SoftBox style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        {profilesToSave.some((profileToSave) => profileToSave.id === profile.id) ||
                                        lists?.some((list) =>
                                            list?.profile?.some((profileInList) => profileInList.id === profile.id)
                                        ) ? (
                                            <SoftBadge color="success" badgeContent="Saved" />
                                        ) : null}
                                    </SoftBox>

                                    <SoftBox ml={1} mt={1}>
                                        <SoftTypography variant="h6" fontWeight="bold">
                                            {profile?.localizedFirstName || profile.localizedName} {profile?.localizedLastName || ""}
                                        </SoftTypography>
                                        <SoftTypography variant="body2" fontWeight="regular">
                                            {profile?.localizedHeadline || profile.localizedWebsite}
                                        </SoftTypography>
                                    </SoftBox>
                                </SoftBox>
                                <SoftBox mt={2}>
                                    <Link to={`https://www.linkedin.com/in/${profile?.vanityName}`} target="_blank" rel="noopener noreferrer">
                                        <OpenInNew fontSize="small" />
                                    </Link>

                                    <BookmarkAdd
                                        fontSize="small"
                                        onClick={() => handleSaveProfileToList({ profile })}
                                        sx={{ cursor: 'pointer', marginLeft: '8px' }}
                                    />
                                </SoftBox>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogTitle>Create New List</DialogTitle>
                <DialogContent>
                    <DialogContentText>Create a new list to save profiles to:</DialogContentText>
                    <SoftBox>
                        <SoftInput placeholder="List Name" defaultValue={newListName} onChange={handleChange} controlled />
                    </SoftBox>
                </DialogContent>
                <DialogActions>
                    <SoftButton onClick={() => setIsDialogOpen(false)} color="primary">
                        Cancel
                    </SoftButton>
                    <SoftButton onClick={() => handleCreateList({ listName: newListName })} color="primary">
                        Create List
                    </SoftButton>
                </DialogActions>
            </Dialog>

            <SoftSnackbar
                open={openSnackbar}
                close={() => setOpenSnackbar(false)}
                dateTime={new Date().toLocaleString()}
                title="Notification"
                content={snackbarMessage}
                color={snackbarSeverity}
                icon="notifications"
            />
        </>
    );
};

EngageProfiles.propTypes = {
    linkedInProfiles: PropTypes.array.isRequired,
    saveProfileToList: PropTypes.func.isRequired,
    getLists: PropTypes.func.isRequired,
    createList: PropTypes.func.isRequired,
    db: PropTypes.object.isRequired,
    currentWorkspace: PropTypes.object.isRequired,
};

export default EngageProfiles;
