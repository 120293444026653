import React, { useContext, useState, useEffect, useCallback } from "react";

// Import Mui Components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

// Import Soft UI Components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftSnackbar from "components/SoftSnackbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Import Firebase Context
import FirebaseContext from "configs/firebasecontext";
import { useUserData } from "context/userManagement";
import { httpsCallable } from "@firebase/functions";

// Database functions
import { fetchPublishedPosts, getUserOrganizations, getPostReactions  } from "components/DatabaseFunctions";

// Import the useFetchPosts hook
import useFetchPosts from './components/PostGrid/useFetchingPosts';

// Import Page Components
import LinkedInProfileCard from 'examples/Cards/ProfileCards/LinkedInProfileCard';
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import RefreshIcon from '@mui/icons-material/Refresh';
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";

// Import the DetailedInformationDialog component
import DetailedInformationDialog from './components/DetailedModals/detailedReactionProfiles';

// Import Upgrade Plan Modal
import UpgradePlanModal from "components/SharedFunctions/upgradePlanModal";

// Helper function to get the best time of day to post
const getBestTimeOfDay = (posts, allReactions, allComments) => {
  const engagementByHour = Array(24).fill(0);

  posts.forEach(post => {
    const postId = post.postId;
    const reactionsCount = allReactions[postId]?.length || 0;
    const commentsCount = allComments[postId]?.length || 0;
    const engagement = reactionsCount + commentsCount;
    const postDate = new Date(post?.postedTime * 1000);
    const hour = postDate.getHours();

    engagementByHour[hour] += engagement;
  });

  let bestHour = 0;
  let maxEngagement = -1;

  for (let hour = 0; hour < 24; hour++) {
    if (engagementByHour[hour] > maxEngagement) {
      maxEngagement = engagementByHour[hour];
      bestHour = hour;
    }
  }

  return { bestHour, maxEngagement, engagementByHour };
};

// Helper function to get the best day to post
const getBestDayToPost = (posts, allReactions, allComments) => {
  const engagementByDay = Array(7).fill(0);

  posts.forEach(post => {
    const postId = post.postId;
    const reactionsCount = allReactions[postId]?.length || 0;
    const commentsCount = allComments[postId]?.length || 0;
    const engagement = reactionsCount + commentsCount;
    const postDate = new Date(post?.postedTime * 1000);
    const day = postDate.getDay();

    engagementByDay[day] += engagement;
  } );

  let bestDay = 0;

  for (let day = 0; day < 7; day++) {
    if (engagementByDay[day] > engagementByDay[bestDay]) {
      bestDay = day;
    }
  }

  return bestDay;

};

function PostHistory() {
  const { userId, userData, currentWorkspace, currentUserData } = useUserData();
  const { db, functions } = useContext(FirebaseContext);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  const { allReactions, allComments } = useFetchPosts(posts);

  const [topPostReactions, setTopPostReactions] = useState(null);
  const [topPostComments, setTopPostComments] = useState(null);
  const [bestTimeOfDay, setBestTimeOfDay] = useState(null);
  const [maxEngagement, setMaxEngagement] = useState(0);
  const [engagementByHour, setEngagementByHour] = useState([]);
  const [totalFollowerCount, setTotalFollowerCount] = useState(0);
  const [followerCountChartData, setFollowerCountChartData] = useState({ labels: [], datasets: [] });
  const [bestDayToPost, setBestDayToPost] = useState(null);

  // State for button timeout to prevent multiple clicks
  const [refreshTimeout, setRefreshTimeout] = useState(false);

  //Snackbar Variables
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  // Detailed Information Dialog Variables
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [postReactions, setPostReactions] = useState([]);
  const [reactionData, setReactionData] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [actorDetails, setActorDetails] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [organizations, setOrganizations] = useState([]);

  // Variables for Upgrade Plan Modal
  const [openUpgradePlanModal, setOpenUpgradePlanModal] = useState(false);
  const userPermissions = currentUserData?.tenantData[0]?.subscriptionData[0]?.planAccess || {};

  // Helper function to get the week number of a date
  function getWeekNumber(d) {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return weekNo;
  }

  useEffect(() => {
    // Check if the states are settled
    if (userId === null || userData === null) {
      return;
    }

    // Async function to get the posts
    const getPosts = async () => {
        // Fetch the posts
        const publishedPosts = await fetchPublishedPosts(db, currentWorkspace.id);

        // Set the posts in date order with the newest first
        publishedPosts.sort((a, b) => b.postedTime - a.postedTime);

        setPosts(publishedPosts);
    };

    // Call the getPosts function
    getPosts();
  }, [userId, userData, currentWorkspace]);

  // 
  useEffect(() => {
    const setAnalytics = () => {
      if (posts.length > 0) {
        let maxReactions = -1;
        let maxComments = -1;
        let topPostByReactions = null;
        let topPostByComments = null;

        posts.forEach(post => {
          const reactionsCount = allReactions[post.postId]?.length || 0;
          const commentsCount = allComments[post.postId]?.length || 0;

          if (reactionsCount > maxReactions) {
            maxReactions = reactionsCount;
            topPostByReactions = post;
          }

          if (commentsCount > maxComments) {
            maxComments = commentsCount;
            topPostByComments = post;
          }
        });

        setTopPostReactions(topPostByReactions);
        setTopPostComments(topPostByComments);

        // Get the best day to post
        const { bestHour, maxEngagement, engagementByHour } = getBestTimeOfDay(posts, allReactions, allComments);

        // Get the best day to post
        const bestDay = getBestDayToPost(posts, allReactions, allComments);

        setBestDayToPost(bestDay);
        setBestTimeOfDay(bestHour);
        setMaxEngagement(maxEngagement);
        setEngagementByHour(engagementByHour);
      }
    };

    // Call the setAnalytics function
    setAnalytics();

  }, [posts, allReactions, allComments]);

  // Function to fetch reactions
  const fetchReactions = async () => {
    try {

      setLoading(true);

      // Get reactions from LinkedIN
      const getReactions = httpsCallable(functions, process.env.REACT_APP_CLOUD_FUNCTION_GETALLREACTIONS);
      const reactionsResults = await getReactions({workspaceId: currentWorkspace.id});

      // Call the cloud function to fetch comments and pass the workspace ID
      const fetchComments = httpsCallable(functions, process.env.REACT_APP_CLOUD_FUNCTION_GETCOMMENTS);
      const commentsResults = await fetchComments({workspaceId: currentWorkspace.id});

      setOpenSnackbar(true);
      setSnackbarMessage("Reactions fetched successfully");
      setSnackbarSeverity("success");

      setTimeout(() => {
        setOpenSnackbar(false);
      }, 3000);

      // Set timeout for refresh button to 120 seconds
      setRefreshTimeout(true);
      // If environment is development, set timeout to 10 seconds otherwise 120 seconds
      setTimeout(() => {
        setRefreshTimeout(false);
      }, process.env.NODE_ENV === "development" ? 10000 : 120000);

      // Set loading to false
      setLoading(false);

      // Refresh posts - Done as promise
      fetchPublishedPosts(db, currentWorkspace.id).then((data) => {
        setPosts(data);
      }
      );

      return [];
    } catch (error) {
      console.log("Error: ", error);
      setOpenSnackbar(true);
      setSnackbarMessage("Error fetching reactions");
      setSnackbarSeverity("error");

      setTimeout(() => {
        setOpenSnackbar(false);
      }, 3000);
      setLoading(false);
      return [];
    }
  };

  // Function to get the profile information
  useEffect(() => {
    // Check if states are settled
    if (!userId || !db || !currentWorkspace) return;
    
    getUserOrganizations(db, userId, currentWorkspace.id).then((data) => {
      if (data) {
        setOrganizations(data);
      }
    });
  }, [userId, db, currentWorkspace]);

  // Function to get the profile information
  const getProfileInfo = ({postingProfile, linkedInUID}) => {
    if (!postingProfile && !linkedInUID) {
      return {};
    }

    if (postingProfile === "Personal") {
      
      const linkedInAccountsArray = Object.values(currentWorkspace?.linkedInAccounts || {});
      const selectedPerson = linkedInAccountsArray.find((account) => account.profileURN === linkedInUID);
      
      if (selectedPerson) {
        return {
          profilePic: selectedPerson.profilePic || "",
          name: selectedPerson.name || "",
          headline: selectedPerson.headline || "",
          profileURN: selectedPerson.profileURN || "",
        };
      }      

    } else {
      // Get the selected organization
      const selectedOrg = organizations.find((org) => org.organizationURN === linkedInUID);
      if (selectedOrg) {
        return {
          profilePic: selectedOrg?.logoUrl || "",
          name: selectedOrg?.name || "",
          headline: selectedOrg?.headline || "Page Administrator",
        };
      }
    }
    return {};
  };

  // Function to handle the opening of the dialog
  const handleOpenDialog = async () => {
    // Fetch detailed statistics for the post reactions
    // Check the states are settled
    if (!functions || !currentWorkspace) return;

    // Fetch detailed statistics for the post reactions
    try {
      setFetching(true);
      const getReactionProfiles = httpsCallable(functions, process.env.REACT_APP_CLOUD_FUNCTION_POSTREACTIONPROFILES);
      const details = await getReactionProfiles({ reactions: postReactions, workspaceId: currentWorkspace.id, linkedInUID: selectedPostId?.linkedInUID, postingProfile: selectedPostId?.postingProfile});

      setFetching(false);
      setActorDetails(details?.data || {});
      setIsDialogOpen(true);
    } catch (error) {
      setFetching(false);
      setOpenSnackbar(true);
      setSnackbarMessage("Error fetching detailed statistics");
      setSnackbarSeverity("error");
      setTimeout(() => setOpenSnackbar(false), 3000);
    }
  };

  // Function to process the reactions
  const processReactions = (reactions) => {
    let reactionTypes = {};
    let actorTypes = { organization: 0, person: 0 };
  
    reactions.forEach(reaction => {
      if (reaction.reactionType) {
        reactionTypes[reaction.reactionType] = (reactionTypes[reaction.reactionType] || 0) + 1;
      }
      if (reaction.created.actor.includes("urn:li:organization:")) {
        actorTypes.organization += 1;
      } else if (reaction.created.actor.includes("urn:li:person:")) {
        actorTypes.person += 1;
      }
    });
  
    return { reactionTypes, actorTypes };
  };  

  // Function to handle the click of a post
  const handlePostClick = async (post) => {
    // Check if the user has the required permissions
    if (!userPermissions?.detailedAnalytics) {
      setOpenUpgradePlanModal(true);
      return;
    }

    const profileInfo = getProfileInfo({ postingProfile: post.postingProfile, linkedInUID: post.linkedInUID });

    setSelectedPostId({ ...post, ...profileInfo });
  
    const reactionsFromDb = await getPostReactions(db, post.postId, currentWorkspace.id);
    setPostReactions(reactionsFromDb);
  
    const { reactionTypes, actorTypes } = processReactions(reactionsFromDb);
    setReactionData({
      reactionLabels: Object.keys(reactionTypes),
      reactionData: Object.values(reactionTypes),
      actorLabels: Object.keys(actorTypes),
      actorData: Object.values(actorTypes),
    });
  };  

  // Close the post details dialog
  const handleClosePostDetails = () => {
    setSelectedPostId(null);
  };

  // Function to generate the chart data for the follower count line chart
  const followerCountLineChart = () => {
    try {
      if (!currentWorkspace || !currentWorkspace.linkedInAccounts) {
        console.error("currentWorkspace or linkedInAccounts is undefined.");
        return { labels: [], datasets: [] };
      }
  
      const linkedInAccountsArray = Object.values(currentWorkspace.linkedInAccounts);
  
      // Initialize a map to store follower counts per month for each account and total counts
      const accountFollowerCounts = {};
      const totalFollowerCounts = new Map();
  
      // Helper function to initialize or update counts in a map
      const updateCounts = (map, key, count) => {
        if (map.has(key)) {
          map.set(key, map.get(key) + count);
        } else {
          map.set(key, count);
        }
      };
  
      linkedInAccountsArray.forEach((account) => {
        const accountKey = `${account.id}`;
        accountFollowerCounts[accountKey] = new Map();
  
        if (account.connectionSize) {
          Object.entries(account.connectionSize).forEach(([timestamp, count]) => {
            const date = new Date(timestamp);
            const monthYear = `${date.getMonth() + 1}/${date.getFullYear()}`;
  
            // Update the account's follower counts
            updateCounts(accountFollowerCounts[accountKey], monthYear, count);
  
            // Update the total follower counts
            updateCounts(totalFollowerCounts, monthYear, count);
  
            // Debugging logs for each update
            console.log(`Updated ${accountKey}:`, accountFollowerCounts[accountKey]);
            console.log(`Updated Total:`, totalFollowerCounts);
          });
        }
      });
  
      // Final debugging logs
      console.log("Final Account Follower Counts:", JSON.stringify(accountFollowerCounts, null, 2));
      console.log("Final Total Follower Counts:", JSON.stringify([...totalFollowerCounts], null, 2));
  
      // Array of colors for the chart datasets
      const colors = [
        "black", "info", "success", "warning", "error", "secondary", "primary", "grey"
      ];
  
      // Generate the labels for the last 12 months
      const labels = Array.from({ length: 12 }, (_, i) => {
        const date = new Date();
        date.setMonth(date.getMonth() - i);
        return date.toLocaleString("default", { month: "short" });
      }).reverse();
  
      // Extract data for each account based on the labels
      const datasets = Object.keys(accountFollowerCounts).map((accountKey, index) => ({
        label: linkedInAccountsArray.find(account => account.id === accountKey)?.name || "Account",
        data: labels.map((label, i) => {
          const date = new Date();
          date.setMonth(date.getMonth() - (11 - i)); // Reverse to match labels order
          const monthYear = `${date.getMonth() + 1}/${date.getFullYear()}`;
          return accountFollowerCounts[accountKey].get(monthYear) || 0;
        }),
        color: colors[index % colors.length],
      }));
  
      // Add the dataset for the total follower counts
      datasets.push({
        label: "Total",
        data: labels.map((label, i) => {
          const date = new Date();
          date.setMonth(date.getMonth() - (11 - i)); // Reverse to match labels order
          const monthYear = `${date.getMonth() + 1}/${date.getFullYear()}`;
          return totalFollowerCounts.get(monthYear) || 0;
        }),
        color: "info",
      });

      // Set Total Follower Count
      setTotalFollowerCount(totalFollowerCounts.get(`${new Date().getMonth() + 1}/${new Date().getFullYear()}`) || 0);
  
      console.log("Generated chart data:", { labels, datasets });
  
      return { labels, datasets };
    } catch (error) {
      console.error("Error generating chart data:", error);
      return { labels: [], datasets: [] };
    }
  };
  
  // Set the chart data for the follower count line chart
  useEffect(() => {
    const chart = followerCountLineChart();
    // Returned Chart Data
    console.log("Chart Data Returned: ", chart);
    setFollowerCountChartData(chart);
  }, [currentWorkspace]);
 
  if (!selectedPostId && posts.length === 0) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SoftBox>
              <SoftTypography variant="h4" fontWeight="bold">
                Post Analytics
              </SoftTypography>
            </SoftBox>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <SoftBox style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} mb={2}>
              <SoftTypography variant="body2" fontWeight="regular" textAlign="center">
                You have not published any posts yet through PropelPosts yet. Once you publish a post, you will be able to view analytics here.
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
        <Footer />
      </DashboardLayout>
    );
  }

  if (!selectedPostId && posts.length > 0) {
    return (
      <>
        <SoftSnackbar
          open={openSnackbar}
          close={() => setOpenSnackbar(false)}
          dateTime={new Date().toLocaleString()}
          title="Notification"
          content={snackbarMessage}
          color={snackbarSeverity}
          icon="notifications"
        />
        <DashboardLayout>
          <DashboardNavbar />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SoftBox>
                  <SoftTypography variant="h4" fontWeight="bold">
                    Post Analytics
                    <Tooltip
                      title="View analytics for your posts. This will help you understand how your posts are performing. Only posts made through PropelPosts are included in the analytics."
                      placement="right-start"
                    >
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </SoftTypography>
              </SoftBox>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <SoftBox style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <SoftButton
                  color="info"
                  variant="contained"
                  onClick={() => fetchReactions()}
                  size="small"
                  disabled={refreshTimeout}
                >
                  {loading ? "Fetching Reactions..." : "Fetch Latest Reactions"}
                </SoftButton>
                <Tooltip
                      title="Refresh the last 7 days of reactions and comments for your posts. You can refresh individual posts under 'View Stats'"
                      placement="right-start"
                    >
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                  </Tooltip>
              </SoftBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <SoftBox>
                <DefaultCounterCard
                  title="Total Posts"
                  count={posts.length}
                  suffix=""
                  description="Published with PropelPosts"
                />
              </SoftBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <SoftBox>
                <DefaultCounterCard
                  title="Highest Reaction Post"
                  count={topPostReactions ? allReactions[topPostReactions.postId]?.length || 0 : 0}
                  suffix=""
                  description="Most reactions on a post"
                />
              </SoftBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <SoftBox>
                <DefaultCounterCard
                  title="Highest Commented Post"
                  count={topPostComments ? allComments[topPostComments.postId]?.length || 0 : 0}
                  suffix=""
                  description="Most comments on a post"
                />
              </SoftBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <SoftBox>
                <DefaultCounterCard
                  title="Best time of day to post"
                  count={bestTimeOfDay}
                  suffix=":00"
                  description="for maximum engagement"
                />
              </SoftBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <SoftBox>
                <DefaultCounterCard
                  title="Total Reactions"
                  count={Object.values(allReactions).flat().length}
                  suffix=""
                  description="On all posts"
                />
                {/* <DefaultCounterCard
                  title="Best day to post"
                  count={bestDayToPost}
                  suffix=""
                  description="for maximum engagement"
                /> */}
              </SoftBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <SoftBox>
                <DefaultCounterCard
                  title="Total Followers"
                  count={totalFollowerCount}
                  suffix=""
                  description="All Connected Accounts"
                />
              </SoftBox>
            </Grid>

            <Grid item xs={12}>
              <SoftBox>
                <DefaultLineChart
                  title="Follower Count - Last 12 Months"
                  chart={followerCountChartData}
                />
              </SoftBox>
            </Grid>

            <Grid item xs={12}>
              <SoftBox>
                <SoftTypography variant="h4" fontWeight="bold">
                  Detailed Post Analytics
                </SoftTypography>
                <SoftTypography variant="caption" fontWeight="light">
                View details of all your posts. See breakdown of reactions, comments and person information for each post.
                </SoftTypography>
              </SoftBox>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={3} justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                    <DataTable
                      canSearch={true}
                      isSorted={true}
                      table={{
                        columns: [
                          { Header: "Date", accessor: "date", width: "10%" },
                          { Header: "Posted By", accessor: "postedBy", width: "10%" },
                          { Header: "Post Content", accessor: "postContent", width: "40%" },
                          { Header: "Reactions", accessor: "reactionCount", width: "15%" },
                          { Header: "Comments", accessor: "commentCount", width: "15%" },
                          { Header: "Detailed Stats", accessor: "viewStats", width: "10%" },
                        ],
                        rows: posts.map(post => ({
                          date: post.postedTime?.toDate().toLocaleDateString(),
                          postedBy: getProfileInfo({ postingProfile: post.postingProfile, linkedInUID: post.linkedInUID }).name,
                          postContent: post?.content ? post.content.substring(0, 40) : "",
                          reactionCount: allReactions[post.postId]?.length || 0,
                          commentCount: allComments[post.postId]?.length || 0,
                          viewStats: (
                            <SoftButton onClick={() => handlePostClick(post)} variant="contained" color="info" ml={2} mt={3}>
                              View Stats
                            </SoftButton>
                          ),
                        })),
                      }}
                    />
                  </Grid>
                </Grid>
            </Grid>
          </Grid>
          
          <Footer />
        </DashboardLayout>

        <DetailedInformationDialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} actorDetails={actorDetails} reactions={postReactions} />
      </>
    );
  }

  if (selectedPostId && posts.length > 0) {
    return (
      <>
        <SoftSnackbar
          open={openSnackbar}
          close={() => setOpenSnackbar(false)}
          dateTime={new Date().toLocaleString()}
          title="Notification"
          content={snackbarMessage}
          color={snackbarSeverity}
          icon="notifications"
        />
        <DashboardLayout>
          <DashboardNavbar />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SoftBox>
                  <SoftTypography variant="h4" fontWeight="bold">
                    Post Analytics
                    <Tooltip
                      title="View analytics for your posts. This will help you understand how your posts are performing. Only posts made through PropelPosts are included in the analytics."
                      placement="right-start"
                    >
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </SoftTypography>
              </SoftBox>
              <Divider />
            </Grid>
            <Grid item xs={12}>
                <SoftBox mb={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <LinkedInProfileCard
                        image={selectedPostId.profilePic}
                        name={selectedPostId.name}
                        headline={selectedPostId.headline}
                        postContent={selectedPostId.content}
                        postImage={selectedPostId.postImageUrl}
                        truncateText={false}
                      />
                      <SoftBox mt={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <SoftButton onClick={handleClosePostDetails} variant="contained" color="info" ml={2} mt={3}>
                          Close
                        </SoftButton>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={6}>
                      {reactionData && (
                        <Grid container spacing={2}>
                          <Grid item xs={10}>
                            <SoftTypography variant="body2" fontWeight="medium" color="dark">
                              Total Reactions: {postReactions?.length || "0"}
                            </SoftTypography>
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip title="Refresh Post Reactions" placement="top">
                              <RefreshIcon style={{ cursor: 'pointer' }} onClick={() => fetchReactions(selectedPostId)} />
                            </Tooltip>
                          </Grid>
                          <Grid item xs={12}>
                            <SoftBox style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <SoftButton onClick={handleOpenDialog} variant="contained" color="info" ml={2} mt={3}>
                                {fetching ? "Loading..." : "See Who Reacted"}
                              </SoftButton>
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12}>
                            <DefaultDoughnutChart
                              title="Reaction Types"
                              chart={{
                                labels: reactionData.reactionLabels,
                                datasets: {
                                  label: "Reactions",
                                  backgroundColors: ["info", "dark", "error", "secondary", "primary"],
                                  data: reactionData.reactionData,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <DefaultDoughnutChart
                              title="Actor Types"
                              chart={{
                                labels: reactionData.actorLabels,
                                datasets: {
                                  label: "Actors",
                                  backgroundColors: ["info", "dark"],
                                  data: reactionData.actorData,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </SoftBox>
            </Grid>
          </Grid>
          <Footer />
        </DashboardLayout>

        <DetailedInformationDialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} actorDetails={actorDetails} reactions={postReactions} />
      
        <UpgradePlanModal open={openUpgradePlanModal} onClose={() => setOpenUpgradePlanModal(false)} />
      </>
    );
  }
}

export default PostHistory;
