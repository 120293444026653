import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';

//Import SoftUI Components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';
import SoftInput from 'components/SoftInput';
import { set } from 'date-fns';

export function FeedbackForm() {
  const formRef = useRef(); // Create a ref to the form element
  const emailJSServiceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const emailJSTemplateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const emailJSPublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(emailJSServiceID, emailJSTemplateID, formRef.current, emailJSPublicKey)
      .then((result) => {
        setIsSubmitted(true);
      }, (error) => {
        setIsError(true);
      });
  };

  return (
    <>
    {!isSubmitted && (
    <form onSubmit={handleSubmit} ref={formRef}> {/* Attach the ref to the form element */}
      <SoftBox textAlign="center" mb={2}>
        <SoftTypography variant="h6" fontWeight="regular">
          Complete the form below to send us your feedback or get support:
        </SoftTypography>
      </SoftBox>
      <SoftBox mb={2}>
        <SoftTypography variant="caption" fontWeight="regular">
          Your Name:
        </SoftTypography>
        <SoftInput 
          type="text" 
          name="name" 
          label="Name"
        />
      </SoftBox>
      <SoftBox mb={2}>
        <SoftTypography variant="caption" fontWeight="regular">
          Your Email:
        </SoftTypography>
        <SoftInput 
          type="text" 
          name="email" 
          label="Email"
        />
      </SoftBox>
      <SoftBox mb={2}>
        <SoftTypography variant="caption" fontWeight="regular">
          Your Feedback:
        </SoftTypography>
        <SoftInput 
          type="text" 
          name="feedback" 
          label="Feedback"
        />
      </SoftBox>
      <SoftBox mb={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <SoftButton variant="contained" color="info" type="submit">Submit</SoftButton>
      </SoftBox>
    </form>
    )}

    {isSubmitted && (
      <SoftBox textAlign="center" mb={2}>
        <SoftTypography variant="h6" fontWeight="regular">
          Thank you for your feedback!
        </SoftTypography>
      </SoftBox>
    )}

    {isError && (
      <SoftBox textAlign="center" mb={2}>
        <SoftTypography variant="h6" fontWeight="regular">
          Sorry, there was an error sending your feedback. Please try again later.
        </SoftTypography>
      </SoftBox>
    )}
    </>
  );
}
