import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField, Divider, Alert, AlertTitle, Switch } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftInput from 'components/SoftInput';
import PropTypes from 'prop-types';

const PostConfirmationModal = ({ open, onClose, onPostNow, initialComment, setInitialComment, isPosting, isPostSuccess }) => {
  const currentDay = new Date().getDay();
  const isWeekend = currentDay === 0 || currentDay === 6;

  const [isInitialCommentEnabled, setIsInitialCommentEnabled] = useState(false);

  return (
    <Modal open={open} onClose={onClose}>
      <SoftBox sx={{ ...modalStyle }}>
        {isPosting || !isPostSuccess ? (
          <>
            <SoftTypography variant="h4" fontWeight="bold" mb={2}>
              Post Confirmation
            </SoftTypography>
            <Divider sx={{ mb: 2 }} />
            <SoftBox display="flex" alignItems="center" mb={2}>
              <Switch
                checked={isInitialCommentEnabled}
                onChange={() => setIsInitialCommentEnabled(!isInitialCommentEnabled)}
              />
              <SoftTypography variant="body2" fontWeight="bold" ml={2}>
                Auto-plug
                <Tooltip 
                  title="Automatically add an initial comment to the post"
                  position="start-right"
                >
                  <IconButton size="small">
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </SoftTypography>
            </SoftBox>
            {isInitialCommentEnabled && (
              <SoftInput
                placeholder="Initial Comment"
                value={initialComment}
                onChange={(e) => setInitialComment(e.target.value)}
                sx={{ mb: 2 }}
              />
            )}
            {isWeekend && (
              <Alert severity="warning" icon={<WarningIcon />} sx={{ mb: 2 }}>
                <AlertTitle>Weekend Posting</AlertTitle>
                Posting on weekends may result in lower engagement. Are you sure you want to post now?
              </Alert>
            )}
            <SoftBox mt={2} display="flex" justifyContent="flex-end">
              <SoftButton onClick={onClose} sx={{ mr: 1 }}>
                Cancel
              </SoftButton>
              <SoftButton variant="contained" color="info" onClick={onPostNow}>
                {isPosting ? 'Posting...' : 'Post Now'}
              </SoftButton>
            </SoftBox>
          </>
        ) : (
          <SoftBox textAlign="center">
            <img src="https://firebasestorage.googleapis.com/v0/b/contentbuddy-ai.appspot.com/o/application%2Fimages%2Fwork_success_generated.jpg?alt=media&token=05aa126b-8436-414c-b8f3-52fd2a78b6a5" alt="Post published" style={{ width: '100px', marginBottom: '20px' }} />
            <SoftTypography variant="h4" fontWeight="bold" mb={2}>
              Post published!
            </SoftTypography>
            <SoftTypography variant="body1" mb={2}>
              Your post has been published to LinkedIn.
            </SoftTypography>
            <SoftBox display="flex" justifyContent="center" gap={2} mt={2}>
              <SoftButton
                variant="outlined"
                color="info"
                onClick={() => window.open('https://www.linkedin.com', '_blank')}
              >
                View Post on LinkedIn
              </SoftButton>
              <SoftButton
                variant="contained"
                color="info"
                onClick={() => {
                  setInitialComment('');
                  onClose();
                }}
              >
                Create New Post
              </SoftButton>
            </SoftBox>
          </SoftBox>
        )}
      </SoftBox>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  minWidth: '40%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

PostConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPostNow: PropTypes.func.isRequired,
  initialComment: PropTypes.string.isRequired,
  setInitialComment: PropTypes.func.isRequired,
  isPosting: PropTypes.bool.isRequired,
  isPostSuccess: PropTypes.bool.isRequired,
};

export default PostConfirmationModal;
