import React, { useState, useEffect, useContext } from 'react';

//Import Soft UI Dashboard React components
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftSnackbar from 'components/SoftSnackbar';
import SoftInput from 'components/SoftInput';

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

//Import Material UI components
import {
    Grid,
    Card,
    CircularProgress
} from '@mui/material';

//Import LinkedIn Profile Card
import LinkedInProfileCard from 'examples/Cards/ProfileCards/LinkedInProfileCard';

//Import Firebase Context
import FirebaseContext from "configs/firebasecontext";

//Import User Context
import { useUserData } from "context/userManagement";

//Import database query function for getting AI profile
import { fetchAiProfile, storeDraft } from "components/DatabaseFunctions";
import { httpsCallable } from "@firebase/functions";

//Import Axios
import axios from 'axios';

// Import Icons
import InfoIcon from '@mui/icons-material/Info';
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

function NewsGenerator() {
        //Get Firebase Context
        const {db, functions, auth } = useContext(FirebaseContext);

        //Get User Context
        const { userId, userData } = useUserData();
    
        //States for snackbard
        const [snackbarOpen, setSnackbarOpen] = useState(false);
        const [snackbarMessage, setSnackbarMessage] = useState("");
        const [snackbarSeverity, setSnackbarSeverity] = useState("success");
        const [loading, setLoading] = useState(false);
    
        //States for AI profile
        const [aiProfile, setAiProfile] = useState(null);
    
        //Array of generated post content
        const [postContent, setPostContent] = useState([]);
    
        //State for ideas content
        const [ideasContent, setIdeasContent] = useState("");
    
        //OpenAI API key
        const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

        //News API key
        const NEWS_API_KEY = process.env.REACT_APP_NEWSAPI_KEY;

        //Aync function to fetch news articles and generate post content
        const fetchNews = async (searchTerm) => {
            // Set loading state to true
            setLoading(true);
        
            // Set post content to an empty array
            const allPostObjects = [];

            const promises = []; // This array will hold all our promises

            const fetchNewsArticles = httpsCallable(functions, process.env.REACT_APP_CLOUD_FUNCTION_GETNEWSAPI);

            console.log("Search Term:", searchTerm)
        
            try {
                const result = await fetchNewsArticles({ searchTerm });

                console.log("Result:", result);

                const articles = result.data.articles;
                const maxArticles = Math.min(6, articles.length);
                const allPostObjects = [];

                console.log("Articles:", articles);

        
            for (let i = 0; i < maxArticles; i++) {
                const articleURL = articles[i].url;
        
                let messages = [
                    {
                        "role": "system",
                        "content": "Act as if you are a freelance writer creating LinkedIn posts for a client. You need to generate posts for your client based on the request provided."
                    },
                    {
                        "role": "assistant",
                        "content": `Your client would like you to write a LinkedIn post about this news article:\n\nNews Article Title: ${articles[i].title}\n\nNews Article Description: ${articles[i].description}\n\nThe link to the article is:${articleURL}`
                    },
                    {
                        "role": "user",
                        "content": "Generate a post based on the news article provided. You must include the link to the article in the post as text if it is provided."
                    }
                ];

                console.log("Messages:", messages);
        
                const promise = axios.post("https://api.openai.com/v1/chat/completions", {
                    messages: messages,
                    max_tokens: 200,
                    model: "gpt-3.5-turbo",
                    temperature: 0.5,
                }, {
                    headers: {
                        'Authorization': `Bearer ${OPENAI_API_KEY}`,
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    const postObject = {
                        content: response.data.choices[0].message.content.trim(),
                        url: articleURL
                    };
                    allPostObjects.push(postObject);
                }).catch(error => {
                    console.error("Error while fetching content from OpenAI:", error);
                });
        
                promises.push(promise);
            }
        
            // Wait for all promises to complete
            await Promise.all(promises);
        
            // Update post content state
            setPostContent(allPostObjects);
        
            // Set loading state to false
            setLoading(false);
        
            //If no articles are found set snackbar message and severity
            if (articles.length === 0) {
                setSnackbarMessage("No articles found for search term.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);

                // Timeout to close snackbar
                setTimeout(() => {
                    setSnackbarOpen(false);
                }, 3000);
            }

            // Set snackbar message
            setSnackbarMessage("News articles fetched and post content generated");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        
            // Timeout to close snackbar
            setTimeout(() => {
                setSnackbarOpen(false);
            }, 3000);
            }  catch (error) {
                console.error("Error while fetching news articles:", error);
            };    
        };           

        //Function to Handle Save to Drafts Button
        const handleSaveToDrafts = async (post) => {
            try {
                // const postStatus = "draft";
                const postingProfile = "Personal";
                //Set loading state to true
                setLoading(true);
                //Set snackbar message
                setSnackbarMessage("Saving post to drafts...");
                setSnackbarSeverity("info");
                setSnackbarOpen(true);
                //Snackbar Timeout
                setTimeout(() => {
                    setSnackbarOpen(false);
                }, 2000);
                
                // Combine the content and the URL into a single formatted string
                const formattedContent = `${post.content}\n\n${post.url}`;
                
                //Store post to database
                await storeDraft(formattedContent, userId, db, postingProfile);
                //Set loading state to false
                setLoading(false);
                //Set snackbar message
                setSnackbarMessage("Post saved to drafts successfully.");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
                //Snackbar Timeout
                setTimeout(() => {
                    setSnackbarOpen(false);
                }, 3000);
            } catch (error) {
                console.log(error);
                //Set loading state to false
                setLoading(false);
                //Set snackbar message
                setSnackbarMessage("Error saving post to drafts.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                //Snackbar Timeout
                setTimeout(() => {
                    setSnackbarOpen(false);
                }, 3000);
            }
        }


    return (
        <>
          <SoftSnackbar
            open={snackbarOpen}
            close={() => setSnackbarOpen(false)}
            dateTime={new Date().toLocaleString()}
            content={snackbarMessage}
            color={snackbarSeverity}
            icon="notifications"
            title="Notification"
          />
            <DashboardLayout>
                <DashboardNavbar />
                    <Grid container padding={3}>
                        <Grid item xs={12}>
                            <SoftBox mb={2}>
                                <SoftTypography variant="h4" fontWeight="bold">
                                    Create Posts from News Articles
                                    <Tooltip
                                        title="This tool will generate LinkedIn posts based on news articles. Enter a search term below and click the button to generate posts."
                                        placement="right-start"
                                    >
                                        <IconButton size="small">
                                            <InfoIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>


                                </SoftTypography>
                            </SoftBox>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            <SoftBox mb={3} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <SoftBox mb={2} width="70%">
                                    <SoftInput
                                        fullWidth
                                        value={ideasContent}
                                        onChange={(e) => setIdeasContent(e.target.value)}
                                        placeholder="What news would you like to search and generate posts for?"
                                    />
                                </SoftBox>
                                    <SoftButton
                                        variant="contained"
                                        color="info"
                                        size="small"
                                        onClick={() => fetchNews(ideasContent)}
                                    >
                                        {loading ? "Loading..." : "Generate News Posts"}
                                    </SoftButton>
                            </SoftBox>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        {loading && (
                            
                            <Grid item xs={12}>
                                <SoftBox mb={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <CircularProgress />
                                </SoftBox>
                            </Grid>
                        )}
                        {postContent.length > 0 && !loading && (
                            postContent.map((post, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                        <LinkedInProfileCard
                                            name={userData.name}
                                            headline={userData.headline}
                                            image={userData.profilePic}
                                            postContent={post.content}
                                            postURL={post.url}
                                            shouldTruncateText={false}
                                            showReactions={false} 
                                        />
                                    <SoftBox mt={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <SoftBox mr={2}>
                                            <SoftButton color="dark" variant="contained" size="small" onClick={() => handleSaveToDrafts(post)}>
                                                Save to Drafts
                                            </SoftButton>
                                        </SoftBox>
                                        {/* <SoftBox>
                                        <SoftButton color="dark" variant="contained" size="small">
                                            Edit and Post
                                        </SoftButton>
                                        </SoftBox> */}
                                    </SoftBox>
                                </Grid>
                            ))
                        )}


                    </Grid>

                <Footer />
            </DashboardLayout>
        </>
    );
}

export default NewsGenerator;