
import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import PageLayout from "../../components/PageLayout";

// Pricing page components
import Header from "./components/Header";
import Footer from "./components/Footer";
import PricingCards from "./components/PricingCards";
import TrustedBrands from "./components/TrustedBrands";
import Faq from "./components/Faq";

function PricingPage() {
  const [tabValue, setTabValue] = useState(0);
  const [prices, setPrices] = useState(["59", "89", "99"]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);

    if (event.currentTarget.id === "annual") {
      setPrices(["190", "250", "900"]);
    } else {
      setPrices(["19", "25", "90"]);
    }
  };

  return (
    <PageLayout>
      <Header tabValue={tabValue} tabHandler={handleSetTabValue} />
      <Container>
        <PricingCards prices={prices} />
        <TrustedBrands />
        <Faq />
      </Container>
      <SoftBox mt={8}>
        <Footer />
      </SoftBox>
    </PageLayout>
  );
}

export default PricingPage;
