import React, { useState } from 'react';

// Import Page Layout
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

// Import MUI components
import { Tabs, Tab, Divider } from '@mui/material';

// Import Workspace Settings and Team Members components
import WorkspaceSettings from './components/workspaceSettings'; // Adjust the import path as necessary
import TeamMembers from './components/teamMembers'; // Adjust the import path as necessary
import ConnectedCompanies from './components/connectedCompanies'; // Adjust the import path as necessary

// Import the AI Profile Builder component
import PersonaWizard from 'layouts/applications/wizard'

// Import Soft UI components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

const WorkspacePage = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox p={3}>
        <SoftTypography variant="h4" fontWeight="bold" mb={2}>
          Workspace Settings
        </SoftTypography>
        <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
          <Tab label="Settings" />
          <Tab label="Team Members" />
          <Tab label="Connected Companies" />
          <Tab label="AI Profile Builder" />
        </Tabs>
        <Divider sx={{ my: 3 }} />
        {tabIndex === 0 ? (
          <WorkspaceSettings />
        ) : tabIndex === 1 ? (
          <TeamMembers />
        ) : tabIndex === 2 ? (
          <ConnectedCompanies />
        ) : (
          <PersonaWizard />
        )}
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
};

export default WorkspacePage;
