import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";

function ComicSpeech({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>custom-icon-4</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="m440-803-83 83H240v117l-83 83 83 83v117h117l83 83 100-100 168 85-86-167 101-101-83-83v-117H523l-83-83Zm0-113 116 116h164v164l116 116-116 116 115 226q7 13 4 25.5T828-132q-8 8-20.5 11t-25.5-4L556-240 440-124 324-240H160v-164L44-520l116-116v-164h164l116-116Zm0 396Z"
          fill={colors[color] ? colors[color].main : colors.dark.main}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

// Setting default values for the props of CustomSVG4
ComicSpeech.defaultProps = {
  color: "dark",
  size: "16px",
};

// Typechecking props for the CustomSVG4
ComicSpeech.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "white",
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ComicSpeech;
