import { collection, query, where, doc, getDocs, getDoc, addDoc, Timestamp, deleteDoc, updateDoc, limit, startAfter, setDoc } from "firebase/firestore";
import { list } from "firebase/storage";

//Store post
export async function schedulePost(content, status, scheduledTime, userId, db, postImageUrl, postImageMetaData, initialComment, postingProfile, currentWorkspaceId, linkedInUID) {
try {
    const scheduledPost = await addDoc(collection(db, "workspaces", currentWorkspaceId, "posts"), {
    firebaseUID: userId,
    linkedInUID: linkedInUID || "",
    content: content,
    status: status,
    scheduledTime: Timestamp.fromDate(new Date(scheduledTime)),
    datetimeCreated: new Date(),
    postImageUrl: postImageUrl || "",
    postImageMetaData: postImageMetaData || "",
    initialComment: initialComment || "",
    internalImprovements: "",
    internalFeedback: "",
    internalApprover: "",
    approvalHistory: [],
    internalComments: [],
    postingProfile: postingProfile || "",
    workspace: currentWorkspaceId || "",
    });

    // Add the workspaceId, postId and scheduled Time to the scheduledPosts collection
    await addDoc(collection(db, "scheduledPosts"), {
    workspaceId: currentWorkspaceId,
    postId: scheduledPost.id,
    scheduledTime: Timestamp.fromDate(new Date(scheduledTime)),
    });

    console.log("Post stored");
} catch (e) {
    console.error("Error adding post:", e);
    throw e; // Re-throw the error to be caught by the calling function
}
}

// Function to update the scheduledTime of a post
export async function updateScheduledTime(db, postId, scheduledTime, workspaceId) {
    console.log("Post ID from updateScheduledTime function: " + postId)
    console.log("Scheduled Time from updateScheduledTime function: " + scheduledTime)
    try {
        const postRef = doc(db, "workspaces", workspaceId, "posts", postId);
        await updateDoc(postRef, {
            scheduledTime: Timestamp.fromDate(new Date(scheduledTime)),
        });

        // Find the postId in the scheduledPosts collection and update the scheduledTime
        const q = query(collection(db, "scheduledPosts"), where("postId", "==", postId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            updateDoc(doc.ref, {
                scheduledTime: Timestamp.fromDate(new Date(scheduledTime)),
            });
        });
    } catch (e) {
        console.error("Error updating scheduledTime:", e);
        throw "Error updating post scheduled time";
    }
}

// Store draft post
export async function storeDraft(postContent, postStatus, dateTime, userId, db, postImageUrl, postImageMetaData, initialComment, postingProfile, currentWorkspaceId, linkedInUID) {
    try {
        await addDoc(collection(db, "workspaces", currentWorkspaceId, "posts"), {
            firebaseUID: userId,
            content: postContent,
            linkedInUID: linkedInUID || "",
            status: postStatus,
            postingProfile: postingProfile || "",
            postImage: postImageUrl || "",
            postImageMetadata: postImageMetaData || "",
            initialComment: initialComment || "",
            datetimeCreated: new Date(),
            workspace: currentWorkspaceId,
        });
    } catch (e) {
        console.error("Error adding draft:", e);
        throw e; // Re-throw the error to be caught by the calling function
    }
}

//Delete post
export async function deletePost(db, postId, workspaceId) {
try {
    const postRef = doc(db, "workspaces", workspaceId, "posts", postId);
    await deleteDoc(postRef);

    // Check if the post is scheduled and delete it from the scheduledPosts collection
    const q = query(collection(db, "scheduledPosts"), where("postId", "==", postId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        deleteDoc(doc.ref);
    });

} catch (e) {
    console.error("Error deleting post:", e);
    throw "Error deleting post";
}
}

// Fetch all posts from the posts sub-collection under the workspace
export async function fetchPosts(db, workspaceId) {
    try {
        const q = query(collection(db, "workspaces", workspaceId, "posts"));
        const querySnapshot = await getDocs(q);
        const posts = [];
        querySnapshot.forEach((doc) => {
            const postData = doc.data();
            posts.push({
                content: postData.content,
                postedTimestamp: postData.postedTimestamp,
                scheduledTimestamp: postData.scheduledTime,
                initialComment: postData.initialComment,
                status: postData.status,
                linkedInUID: postData.linkedInUID,
                postingProfile: postData.postingProfile,
                postImage: postData.postImage,
                postImageMetadata: postData.postImageMetadata,
                id: doc.id, // Store the post ID for reference
            });
        });
        return posts;
    } catch (error) {
        console.error("Error fetching posts:", error);
        return null;
    }
}

// Fetch scheduled posts from Firestore and set them to the state
export async function fetchScheduledPosts(userId, db, setScheduledPosts) {
    console.log("User ID from Scheduled Posts function: " + userId)
try {
    const q = query(collection(db, "posts"), where("firebaseUID", "==", userId), where("status", "==", "Scheduled"));
    const querySnapshot = await getDocs(q);
    const posts = [];
    querySnapshot.forEach((doc) => {
        const postData = doc.data();
        posts.push({
            title: postData.content,
            start: postData.scheduledTime.toDate(),
            className: postData.status,
            postImageUrl: postData.postImageUrl,
            id: doc.id, // Store the post ID for reference
        });
    });
    setScheduledPosts(posts);
    } catch (error) {
        console.error("Error fetching scheduled posts:", error);
        // Optionally, you can also handle the error in other ways, 
        // like showing an error message to the user.
    }
    };

//Fetch drafts from Firestore and set them to the state
export async function fetchDrafts(userId, db, setDrafts) {
try {
    const q = query(collection(db, "posts"), where("firebaseUID", "==", userId), where("status", "==", "Draft"));
    const querySnapshot = await getDocs(q);
    const draftsArray = [];
    querySnapshot.forEach((doc) => {
        draftsArray.push({
            id: doc.id,
            date: doc.data().datetimeCreated,
            content: doc.data().content,
            postImage: doc.data().postImage,
            postImageMetadata: doc.data().postImageMetadata,
            initialComment: doc.data().initialComment,
            postingProfile: doc.data().postingProfile,
        });
    });
    setDrafts(draftsArray);
    } catch (error) {
        console.error("Error fetching draft posts:", error);
        // Optionally, you can also handle the error in other ways, 
        // like showing an error message to the user.
    }
    };

// Fetch custom schedule from Firestore and set it to the state
export async function getExistingSchedule(db, workspaceId, linkedInUID) {
    // Check if the workspace ID is provided
    if (!workspaceId) {
        console.error("Workspace ID is required");
        throw new Error("Workspace ID is required");
    }

    // Check if the LinkedIn UID is provided
    if (!linkedInUID) {
        console.error("LinkedIn UID is required");
        throw new Error("LinkedIn UID is required");
    }

    try {
        const q = query(collection(db, "workspaces", workspaceId, "customSchedules"), where("linkedInUID", "==", linkedInUID));
        const querySnapshot = await getDocs(q);
        let schedule = null;
        querySnapshot.forEach((doc) => {
            if (!schedule) { // Assuming each LinkedIn Profile has at most one custom schedule
                schedule = doc.data().schedule;
            }
        });
    return schedule;
    } catch (error) {
    console.error("Error fetching custom schedule:", error);
    // Optionally, you can also handle the error in other ways, 
    // like showing an error message to the user.
    }
}

// Save custom schedule to the workspace
export async function saveCustomSchedule(db, schedule, workspaceId, linkedInUID) {
    // Check if the schedule is provided
    if (!schedule) {
        console.error("Schedule is required");
        throw new Error("Schedule is required");
    }

    // Check if the workspace ID is provided
    if (!workspaceId) {
        console.error("Workspace ID is required");
        throw new Error("Workspace ID is required");
    }

    // Check if the LinkedIn UID is provided
    if (!linkedInUID) {
        console.error("LinkedIn UID is required");
        throw new Error("LinkedIn UID is required");
    }

    try {
        // Save the schedule to a new document in the sub-collection 'customSchedules' under the workspace
        await addDoc(collection(db, "workspaces", workspaceId, "customSchedules"), {
            linkedInUID: linkedInUID,
            schedule: schedule,
            datetimeCreated: new Date(),
        });

        console.log("Custom schedule saved successfully");

    } catch (error) {
        console.error("Error saving custom schedule:", error);
        // Optionally, you can also handle the error in other ways,
        throw error;
    }
}

// Delete custom schedule from the workspace for the linkedInUID
export async function deleteCustomSchedule(db, workspaceId, linkedInUID) {
    // Check if the workspace ID is provided
    if (!workspaceId) {
        console.error("Workspace ID is required");
        throw new Error("Workspace ID is required");
    }

    // Check if the LinkedIn UID is provided
    if (!linkedInUID) {
        console.error("LinkedIn UID is required");
        throw new Error("LinkedIn UID is required");
    }

    try {
        // Fetch the custom schedule for the selected LinkedIn Profile
        const q = query(collection(db, "workspaces", workspaceId, "customSchedules"), where("linkedInUID", "==", linkedInUID));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            deleteDoc(doc.ref);
        });

        return "Custom schedule deleted successfully";
    } catch (error) {
        console.error("Error deleting custom schedule:", error);
        throw "Error deleting custom schedule";
    }
}

// Fetch the published posts from Firestore under 'workspaces/workspaceId/posts'
export async function fetchPublishedPosts(db, workspaceId) {
    // Check if the workspace ID is provided
    if (!workspaceId) {
        console.error("Workspace ID is required");
        throw new Error("Workspace ID is required");
    }

    try {
        const q = query(collection(db, "workspaces", workspaceId, "posts"), where("status", "==", "Posted"));
        const querySnapshot = await getDocs(q);
        const posts = [];
        querySnapshot.forEach((doc) => {
            const postData = doc.data();
            posts.push({
                postId: doc.id,
                content: postData.content,
                postedTime: postData.postedTimestamp,
                postStatus: postData.status,
                mediaURL: postData.mediaURL || "",
                mediaURN: postData.mediaURN || "",
                linkedInUID: postData.linkedInUID || "",
                linkedInPostId: postData.linkedInPostId || "", // Store the post ID for reference
                postImageUrl: postData.postImageUrl || "",
                postImageMetaData: postData.postImageMetaData || "",
                initialComment: postData.initialComment || "",
                postingProfile: postData.postingProfile || "",
            });
        });
        return posts;
    } catch (error) {
        console.error("Error fetching published posts:", error);
        return null;
    }
}

//Save generated AI profile to Firestore
export async function saveAiProfile(db, AIResponse, workspaceId, linkedInUID) {
    // Check if the AI response is provided
    if (!AIResponse) {
        console.error("AI response is required");
        throw new Error("AI response is required");
    }

    // Check if the workspace ID is provided
    if (!workspaceId) {
        console.error("Workspace ID is required");
        throw new Error("Workspace ID is required");
    }

    // Check if the LinkedIn UID is provided
    if (!linkedInUID) {
        console.error("LinkedIn UID is required");
        throw new Error("LinkedIn UID is required");
    }

    try {
        // Save the AI Profile to the Workspace under the 'aiProfiles' collection
        await addDoc(collection(db, "workspaces", workspaceId, "aiProfiles"), {
            linkedInUID: linkedInUID,
            aiProfile: AIResponse,
            datetimeCreated: new Date(),
        });
    } catch (e) {
        console.error("Error adding profile:", e);
        throw e; // Re-throw the error to be caught by the calling function
    }
}

//Fetch AI profile from Firestore for Selected LinkedIn Profile
export async function fetchAiProfile(db, workspaceId, linkedInUID) {
    // Check if the workspace ID is provided
    if (!workspaceId) {
        console.error("Workspace ID is required");
        throw new Error("Workspace ID is required");
    }

    // Check if the LinkedIn UID is provided
    if (!linkedInUID) {
        console.error("LinkedIn UID is required");
        throw new Error("LinkedIn UID is required");
    }

    try {
        // Fetch the AI Profile for the selected LinkedIn Profile
        const q = query(collection(db, "workspaces", workspaceId, "aiProfiles"), where("linkedInUID", "==", linkedInUID));
        const querySnapshot = await getDocs(q);
        let aiProfile = null;
        querySnapshot.forEach((doc) => {
            if (!aiProfile) { // Assuming each LinkedIn Profile has at most one AI Profile
                aiProfile = {
                    ...doc.data(),
                    id: doc.id // Store the AI Profile ID for reference
                };
            }
        });
        return aiProfile;
    } catch (error) {
        console.error("Error fetching AI profile:", error);
        return null;
    }
}

//Delete AI profile from Firestore for Selected LinkedIn Profile
export async function deleteAiProfile(db, workspaceId, linkedInUID) {
    // Check if the workspace ID is provided
    if (!workspaceId) {
        console.error("Workspace ID is required");
        throw new Error("Workspace ID is required");
    }

    // Check if the LinkedIn UID is provided
    if (!linkedInUID) {
        console.error("LinkedIn UID is required");
        throw new Error("LinkedIn UID is required");
    }

    try {
        // Fetch the AI Profile for the selected LinkedIn Profile
        const q = query(collection(db, "workspaces", workspaceId, "aiProfiles"), where("linkedInUID", "==", linkedInUID));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            deleteDoc(doc.ref);
        });

        return "AI profile deleted successfully";
    } catch (error) {
        console.error("Error deleting AI profile:", error);
        throw "Error deleting AI profile";
    }
}


// Get the users invoice history from Firestore under 'billing/userId/invoices'
export async function getInvoiceHistory(db, tenantId) {
    console.log("User ID from Invoice History function: " + tenantId)

    if (!tenantId) {
        return null;
    }

    try {
        // Directly access the invoices subcollection using the userId
        const invoicesQuery = query(collection(db, "tenants", tenantId, "invoices"));
        const invoicesSnapshot = await getDocs(invoicesQuery);
        const invoices = [];
        invoicesSnapshot.forEach((doc) => {
            const invoiceData = doc.data();
            invoices.push({
                invoiceId: invoiceData.invoiceId,
                date: invoiceData.date,
                status: invoiceData.status,
                url: invoiceData.url,
                pdf: invoiceData.pdf,
                amount_paid: invoiceData.values.amount_paid,
                currency: invoiceData.values.currency,
            });
        });
        console.log("Invoice History from Firestore: ", invoices)
        return invoices;
    } catch (error) {
        console.error("Error fetching invoice history:", error);
        return null;
    }
}

// Get the users transaction history from Firestore under 'billing/userId/transactions'
export async function getTransactionHistory(db, tenantId) {
    console.log("User ID from Transaction History function: " + tenantId)

    if (!tenantId) {
        return null;
    }

    try {
        // Directly access the invoices subcollection using the userId
        const transactionQuery = query(collection(db, "tenants", tenantId, "transactions"));
        const transactionSnapshot = await getDocs(transactionQuery);
        const transactions = [];
        transactionSnapshot.forEach((doc) => {
            const transactionData = doc.data();
            transactions.push({
                transactionId: doc.id,
                date: transactionData.date,
                amount: transactionData.amount,
                status: transactionData.paid,
                description: transactionData.description,
                currency: transactionData.currency,
                receipt_url: transactionData.receipt_url,
                card_brand: transactionData.payment_method.brand,
                card_last4: transactionData.payment_method.last4,
            });
        });
        console.log("Transaction History from Firestore: ", transactions)
        return transactions;
    } catch (error) {
        console.error("Error fetching transactions history:", error);
        return null;
    }
}

// Get the users subscription details from Firestore under 'billing/userId/subscription'
export async function getSubscriptionDetails(db, tenantId) {
    console.log("User ID from Subscription Details function: " + tenantId)

    if (!tenantId) {
        return null;
    }

    try {
        // Directly access the subscriptions collection using the userId
        const subscriptionQuery = collection(db, "tenants", tenantId, "subscriptions");
        const subscriptionSnapshot = await getDocs(subscriptionQuery);
        const subscription = [];
        subscriptionSnapshot.forEach((doc) => {
            const subscriptionData = doc.data();
            subscription.push({
                subscriptionId: doc.id,
                status: subscriptionData.status,
                current_period_end: subscriptionData.current_period_end,
                // canceled_at: subscriptionData.canceled_at,
                cancel_at_period_end: subscriptionData.cancel_at_period_end,
                nickname: subscriptionData.items?.nickname,
                // planId: subscriptionData.items?.planId,
                // quantity: subscriptionData.items?.quantity,
                // amount: subscriptionData.items?.amount,
                // interval: subscriptionData.items?.interval,
                // tax: subscriptionData.items?.tax_enabled,
                // url: subscriptionData.items?.url,                
            });            
        });
        console.log("Subscription Details from Firestore: ", subscription)
        return subscription;
    } catch (error) {
        console.error("Error fetching subscription details:", error);
        return null;
    }
}

// Change post status to 'Draft' for selected post ID
export async function changePostStatusToDraft(postId, db) {
    try {
        const postRef = doc(db, "posts", postId);
        await updateDoc(postRef, {
            status: "Draft",
        });
    } catch (e) {
        console.error("Error updating post status:", e);
    }
}

// Get the users organisation collections and documnets from Firestore under 'workspaces/workspaceId/organisations'
export async function getUserOrganizations(db, userId, workspaceId) {
    // Check if the user ID is provided
    if (!userId) {
        console.error("User ID is required");
        throw new Error("User ID is required");
    }

    // Check if the workspace ID is provided
    if (!workspaceId) {
        console.error("Workspace ID is required");
        throw new Error("Workspace ID is required");
    }

    try {
        // Directly access the organisations collection using the workspaceId
        const organizationsQuery = query(collection(db, "workspaces", workspaceId, "organisations"));
        const organizationsSnapshot = await getDocs(organizationsQuery);
        const organizations = [];
        organizationsSnapshot.forEach((doc) => {
            const organizationData = doc.data();
            organizations.push({
                organizationId: doc.id,
                organizationURN: organizationData.URN,
                linkedInProfileId: organizationData.linkedInProfileId,
                name: organizationData.name,
                logoUrl: organizationData.logos[0],
                role: organizationData.role,
                state: organizationData.state,
            });
        });
        console.log("Organizations from Firestore: ", organizations)
        return organizations;
    } catch (error) {
        console.error("Error fetching organizations:", error);
        return null;
    }
}

// Get Job Function name by URN
export async function getJobFunctionNameByURN(urn, db) {
    let id;
    try {
        console.log("Job Function URN from getJobFunctionNameByURN function: " + urn)
        id = urn.split(':').pop();
        console.log("Job Function ID from getJobFunctionNameByURN function: " + id)
        
        // Reference to the document
        const docRef = doc(db, `standardisedAPIData/jobfunctions/${id}/${id}`);
        const docSnapshot = await getDoc(docRef);
      
        if (docSnapshot.exists()) {
          return docSnapshot.data().name;
        } else {
          console.warn("Document does not exist");
          return null; // or handle this however you'd like
        }
    } catch (error) {
        console.error("Error fetching job function name:", error);
        // Optionally, you can also handle the error in other ways, 
        // like showing an error message to the user.
        return id;
    }
}

// Get Industry name by URN
export async function getIndustryNameByURN(urn, db) {
    let id;
    try {
        console.log("Industry URN from getIndustryNameByURN function: " + urn)
        id = urn.split(':').pop();
        console.log("Industry ID from getIndustryNameByURN function: " + id)
        
        // Reference to the document
        const docRef = doc(db, `standardisedAPIData/industries/${id}/${id}`);
        const docSnapshot = await getDoc(docRef);
      
        if (docSnapshot.exists()) {
          return docSnapshot.data().name;
        } else {
          console.warn("Document does not exist");
          return null; // or handle this however you'd like
        }
    } catch (error) {
        console.error("Error fetching industry name:", error);
        // Optionally, you can also handle the error in other ways, 
        // like showing an error message to the user.
        return id;
    }
}

// Get Seniority name by URN
export async function getSeniorityNameByURN(urn, db) {
    let id;
    try {
        console.log("Seniority URN from getSeniorityNameByURN function: " + urn)
        id = urn.split(':').pop();
        console.log("Seniority ID from getSeniorityNameByURN function: " + id)
        
        // Reference to the document
        const docRef = doc(db, `standardisedAPIData/seniorities/${id}/${id}`);
        const docSnapshot = await getDoc(docRef);
      
        if (docSnapshot.exists()) {
          return docSnapshot.data().name;
        } else {
          console.warn("Document does not exist");
          return null; // or handle this however you'd like
        }
    } catch (error) {
        console.error("Error fetching seniority name:", error);
        // Optionally, you can also handle the error in other ways, 
        // like showing an error message to the user.
        return id;
    }
}

// Get Geographic Country name by URN
export async function getGeoCountryNameByURN(urn, db) {
    let id;
    try {
        console.log("Geo Country URN from getGeoCountryNameByURN function: " + urn)
        id = urn.split(':').pop();
        console.log("Geo Country ID from getGeoCountryNameByURN function: " + id)
        
        // Reference to the document
        const docRef = doc(db, `standardisedAPIData/regions/${id}/${id}`);
        const docSnapshot = await getDoc(docRef);
      
        if (docSnapshot.exists()) {
          return docSnapshot.data().name;
        } else {
          console.warn("Document does not exist");
          return null; // or handle this however you'd like
        }
    } catch (error) {
        console.error("Error fetching geographic country name:", error);
        // Optionally, you can also handle the error in other ways, 
        // like showing an error message to the user.
        return id;
    }
}

// Get the reactions for a post from Firestore under 'posts/postId/reactions' where collection("posts").where("linkedInPostId", "==", linkedInPostId).limit(1).get();
export async function getPostReactions(db, postId, workspaceId) {
    // Check if the workspace ID is provided
    if (!workspaceId) {
        console.error("Workspace ID is required");
        return null;
    }

    // Check if the post ID is provided
    if (!postId) {
        console.error("Post ID is required");
        return null;
    }

    try {
        // Directly fetch reactions for the provided postId
        const reactionsCollection = collection(db, "workspaces", workspaceId, "posts", postId, "reactions");
        const reactionsSnapshot = await getDocs(reactionsCollection);

        // const reactionsCollection = collection(db, "posts", postId, "reactions");
        // const reactionsSnapshot = await getDocs(reactionsCollection);

        if (reactionsSnapshot.empty) {
            return [];
        }

        const reactions = reactionsSnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        return reactions;

    } catch (error) {
        console.error("Error fetching post reactions:", error);
        console.error("Firebase error code:", error.code); // Log the error code
        console.error("Firebase error message:", error.message); // Log the detailed error message
        return null;
    }    
}

// Get the comments for a post from Firestore under 'posts/postId/comments'
export async function getPostComments(db, postId, workspaceId) {
    // Check if the workspace ID is provided
    if (!workspaceId) {
        console.error("Workspace ID is required");
        return null;
    }

    // Check if the post ID is provided
    if (!postId) {
        console.error("Post ID is required");
        return null;
    }

    try {
        // Directly fetch reactions for the provided postId
        const commentsCollection = collection(db, "workspaces", workspaceId, "posts", postId, "comments");
        const commentsSnapshot = await getDocs(commentsCollection);

        if (commentsSnapshot.empty) {
            return [];
        }

        const comments = commentsSnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        return comments;

    } catch (error) {
        console.error("Error fetching post comments:", error);
        console.error("Firebase error code:", error.code); // Log the error code
        console.error("Firebase error message:", error.message); // Log the detailed error message
        return null;
    }    
}

// Get the comments for a post from Firestore under 'posts/postId/comments'
export async function getPostsAndComments(db, workspaceId) {
    // Check if the workspace ID is provided
    if (!workspaceId) {
        console.error("Workspace ID is required");
        return null;
    }

    try {

        const thirtyDaysAgo = Timestamp.fromMillis(Date.now() - (30 * 24 * 60 * 60 * 1000));

        // Construct the query to fetch posts created in the last 30 days from the workspace and status is 'Posted'
        const postQuery = query(collection(db, "workspaces", workspaceId, "posts"), where("status", "==", "Posted"), where("postedTimestamp", ">", thirtyDaysAgo));

        // Execute the query
        const postsSnapshot = await getDocs(postQuery);

        const postsAndComments = [];
        
        for (let postDoc of postsSnapshot.docs) {
            const postData = postDoc.data();

            // Query for comments associated with this post
            const commentsSnapshot = await getDocs(collection(postDoc.ref, "comments"));
            const comments = [];
            for (let commentDoc of commentsSnapshot.docs) {
                const commentData = commentDoc.data();

                // Query for replies associated with this comment
                const repliesSnapshot = await getDocs(collection(commentDoc.ref, "replies"));
                const replies = repliesSnapshot.docs.map(replyDoc => replyDoc.data());

                // Add replies to comment data
                commentData.replies = replies;

                comments.push(commentData);
            }

            // Add post and its associated comments (with replies) to the result array
            postsAndComments.push({
            postId: postDoc.id,
            post: postData,
            comments: comments,
            });
        }

        return postsAndComments;
    } catch (error) {
        console.error("Error fetching posts, comments, and replies:", error);
        return null;
    }
};

// Get the post templates from 'post_generation_templates' collection in Firestore
export async function getPostGenerationTemplates(userId, db) {
    // Check if the user is authenticated
    if (!userId) {
        return null;
    }

    // Check if the Firestore database is available
    if (!db) {
        return null;
    }

    // Query the 'post_generation_templates' collection
    try {
        const templatesQuery = query(collection(db, "post_generation_templates"));
        const templatesSnapshot = await getDocs(templatesQuery);

        const templates = templatesSnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        return templates;
    } catch (error) {
        console.error("Error fetching post templates:", error);
        return null;
    }
}

// Get the post templates from 'post_templates' collection in Firestore
export async function getPostFormatTemplates(userId, db) {
    // Check if the user is authenticated
    if (!userId) {
        return null;
    }

    // Check if the Firestore database is available
    if (!db) {
        return null;
    }

    // Query the 'post_templates' collection
    try {
        const templatesQuery = query(collection(db, "post_templates"));
        const templatesSnapshot = await getDocs(templatesQuery);

        const templates = templatesSnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        return templates;

    } catch (error) {

        console.error("Error fetching post templates:", error);
        return null;

    }

}

// Update the workspace name
export async function updateWorkspaceName(db, workspaceId, newWorkspaceName, userId) {
    // Check if the workspace ID is provided
    if (!workspaceId) {
        console.error("Workspace ID is required");
        throw new Error("Workspace ID is required");
    }

    // Check if the new workspace name is provided
    if (!newWorkspaceName) {
        console.error("New workspace name is required");
        throw new Error("New workspace name is required");
    }

    // Check if the user ID is provided
    if (!userId) {
        console.error("User ID is required");
        throw new Error("User ID is required");
    }

    try {
        // Get the workspace document
        const workspaceRef = doc(db, "workspaces", workspaceId);
        const workspaceDoc = await getDoc(workspaceRef);

        // Check if the user is the owner of the workspace
        if (workspaceDoc.exists() && workspaceDoc.data().workspaceOwner === userId) {
            // Update the workspace name
            await updateDoc(workspaceRef, {
                workspaceName: newWorkspaceName,
            });
        } else {
            console.error("User does not have permission to update this workspace");
            throw new Error("User does not have permission to update this workspace");
        }
    } catch (e) {
        console.error("Error updating workspace name:", e);
        throw e;
    }
}

// Helper function to chunk arrays in fetching the team members
function chunkArray(array, size) {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

// Fetch workspace members information with pagination
export async function fetchWorkspaceMembers(db, userId, workspaceId, pageSize, lastVisibleMember) {

    // Console log the userId
    console.log("User ID from fetchWorkspaceMembers function: " + userId)

    // Check if the workspace ID is provided
    if (!workspaceId) {
        console.error("Workspace ID is required");
        throw new Error("Workspace ID is required");
    }

    // Check if the user ID is provided
    if (!userId) {
        console.error("User ID is required");
        throw new Error("User ID is required");
    }

    // Check if the user is authenticated
    if (!db) {
        console.error("Database connection is required");
        throw new Error("Database connection is required");
    }

    // Set the default page size if not provided
    if (!pageSize) {
        pageSize = 10;
    }

    // Set the default last visible member if not provided
    if (!lastVisibleMember) {
        lastVisibleMember = null;
    }


  try {
    // Get the workspace document
    const workspaceRef = doc(db, "workspaces", workspaceId);
    const workspaceDoc = await getDoc(workspaceRef);

    if (!workspaceDoc.exists()) {
      throw new Error("Workspace not found");
    }

    const members = workspaceDoc.data().members;

    if (!members || members.length === 0) {
      throw new Error("No members found in the workspace");
    }

    // Split members into chunks of 10
    const memberChunks = chunkArray(members, 10);

    const userInfos = {};
    const workspaceMembers = [];
    let querySnapshot;

    // Fetch members' basic information from the users collection
    for (const chunk of memberChunks) {
      const usersRef = collection(db, "users");
      let usersQuery = query(usersRef, where("__name__", "in", chunk), limit(pageSize));
      
      if (lastVisibleMember) {
        usersQuery = query(usersRef, where("__name__", "in", chunk), startAfter(lastVisibleMember), limit(pageSize));
      }

      querySnapshot = await getDocs(usersQuery);
      querySnapshot.forEach((doc) => {
        userInfos[doc.id] = doc.data();
      });
    }

    // Fetch members' permissions from the workspaceMembers sub-collection
    for (const chunk of memberChunks) {
      const workspaceMembersRef = collection(db, `workspaces/${workspaceId}/workspaceMembers`);
      let workspaceMembersQuery = query(workspaceMembersRef, where("__name__", "in", chunk), limit(pageSize));
      
      if (lastVisibleMember) {
        workspaceMembersQuery = query(workspaceMembersRef, where("__name__", "in", chunk), startAfter(lastVisibleMember), limit(pageSize));
      }

      querySnapshot = await getDocs(workspaceMembersQuery);
      querySnapshot.forEach((doc) => {
        const memberId = doc.id;
        const memberData = {
          ...userInfos[memberId],
          ...doc.data(),
        };
        workspaceMembers.push(memberData);
      });
    }

    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    return { workspaceMembers, lastVisible };
  } catch (error) {
    console.error("Error fetching workspace members:", error);
    throw error;
  }
}

// Save team member invitation link to Firestore
export async function saveInvitationLink(db, userId, workspaceId, email, invitationLink, invitationToken, role) {
    // Check if the workspace ID is provided
    if (!workspaceId) {
        console.error("Workspace ID is required");
        throw new Error("Workspace ID is required");
    }

    // Check if the user ID is provided
    if (!userId) {
        console.error("User ID is required");
        throw new Error("User ID is required");
    }

    try {
        await addDoc(collection(db, `workspaces/${workspaceId}/invtationLinks`), {
            invitedBy: userId,
            invitedEmail: email,
            invitationLink: invitationLink,
            invitationToken: invitationToken,
            userRole: role,
            datetimeCreated: new Date(),
        });
    } catch (e) {
        console.error("Error saving invitation link:", e);
        throw e;
    }
}

// Fetch team member invitation document from Firestore
export async function fetchInvitationLink(db, workspaceId, invitationToken) {
    // Check if the invitation token is provided
    if (!invitationToken) {
        console.error("Invitation token is required");
        throw new Error("Invitation token is required");
    }

    // Check if the workspace ID is provided
    if (!workspaceId) {
        console.error("Workspace ID is required");
        throw new Error("Workspace ID is required");
    }

    console.log("Invitation token", invitationToken);

    try {
        const invitationLinksRef = collection(db, `workspaces/${workspaceId}/invtationLinks`);
        const invitationLinksQuery = query(invitationLinksRef, where("invitationToken", "==", invitationToken));
        const invitationLinksSnapshot = await getDocs(invitationLinksQuery);

        if (invitationLinksSnapshot.empty) {
            console.error("No invitation link found");
            return null;
        }

        const invitationLink = invitationLinksSnapshot.docs[0].data();
        return invitationLink;
    } catch (error) {
        console.error("Error fetching invitation link:", error);
        throw error;
    }
}

// Remove LinkedIn Account from Workspace
export async function removeLinkedInAccount(db, workspaceId, accountId) {


    try {
        const workspaceRef = doc(db, "workspaces", workspaceId);
        const workspaceDoc = await getDoc(workspaceRef);

        if (!workspaceDoc.exists()) {
            throw new Error("Workspace not found");
        }

        const linkedInAccounts = workspaceDoc.data().linkedInAccounts;

        if (!linkedInAccounts || !linkedInAccounts[accountId]) {
            throw new Error("LinkedIn Account not found in the workspace");
        }

        delete linkedInAccounts[accountId];

        await updateDoc(workspaceRef, {
            linkedInAccounts: linkedInAccounts,
        });
    } catch (error) {
        console.error("Error removing LinkedIn Account from Workspace:", error);
        throw error;
    }
}

// Get all reactions and comments for all posts in the workspace. Stored in the 'workspaces/workspaceId/posts/postId/reactions' and 'workspaces/workspaceId/posts/postId/comments' collections
export async function fetchAllReactionsAndComments(db, workspaceId) {
    console.log("Workspace ID from fetchAllReactionsAndComments function: " + workspaceId);

    // Check if the workspace ID is provided
    if (!workspaceId) {
        console.error("Workspace ID is required");
        return null;
    }

    try {
        // Get all posts in the workspace
        const postsQuery = query(collection(db, "workspaces", workspaceId, "posts"));
        const postsSnapshot = await getDocs(postsQuery);

        // Array to store all post reactions and comments
        const allReactionsAndComments = [];

        // Loop through each post
        for (const postDoc of postsSnapshot.docs) {
            // Get the post ID for the current post which is the document ID
            const postId = postDoc.id;
            const linkedInUID = postDoc.data().linkedInUID;
            const postingProfile = postDoc.data().postingProfile;

            // Console Log the Post ID
            console.log("Post ID from fetchAllReactionsAndComments function: " + postId);

            // Get all reactions for the post
            const reactionsQuery = query(collection(db, "workspaces", workspaceId, "posts", postId, "reactions"));
            const reactionsSnapshot = await getDocs(reactionsQuery);

            // Check if there are no reactions for the post
            if (reactionsSnapshot.empty) {
                // Add post ID to the result array
                allReactionsAndComments.push({
                    postId,
                    linkedInUID,
                    postingProfile,
                    reactions: [],
                    comments: []
                });
                continue;
            }

            // Array to store all reactions for the post
            const reactions = reactionsSnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));

            // Get all comments for the post
            const commentsQuery = query(collection(db, "workspaces", workspaceId, "posts", postId, "comments"));
            const commentsSnapshot = await getDocs(commentsQuery);

            // Check if the post has no comments
            if (commentsSnapshot.empty) {
                // Add post ID and reactions to the result array
                allReactionsAndComments.push({
                    postId,
                    linkedInUID,
                    postingProfile,
                    reactions,
                    comments: []
                });
                continue;
            }

            // Array to store all comments for the post
            const comments = commentsSnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));

            // Add post ID, reactions, and comments to the result array
            allReactionsAndComments.push({
                postId,
                linkedInUID,
                postingProfile,
                reactions,
                comments
            });
        }

        // Console Log the Reactions and Comments
        console.log("Reactions and Comments from fetchAllReactionsAndComments function: ", allReactionsAndComments);

        return allReactionsAndComments;
    } catch (error) {
        console.error("Error fetching reactions and comments:", error);
        return null;
    }
}

// Save the passed profile information to a list under the workspace in 'workspaces/workspaceId/savedLists'
export async function saveProfileToList(db, workspaceId, profile, listId) {
    // Check if the workspace ID is provided
    if (!workspaceId) {
        console.error("Workspace ID is required");
        throw new Error("Workspace ID is required");
    }

    // Check if the profile is provided
    if (!profile) {
        console.error("Profile is required");
        throw new Error("Profile is required");
    }

    // Check if the list name is provided
    if (!listId) {
        console.error("List name is required");
        throw new Error("List name is required");
    }

    try {
        // Add the profile to the list in Firestore
        const listRef = doc(db, `workspaces/${workspaceId}/savedLists/${listId}`);
        const listDoc = await getDoc(listRef);

        if (!listDoc.exists()) {
            console.error("List not found");
            return null;
        }

        const profileList = listDoc.data().profile;
        profileList.push(profile);

        await updateDoc(listRef, {
            profile: profileList,
        });
        
    } catch (e) {
        console.error("Error saving profile to saved list:", e);
        throw e;
    }
}

// Fetch the profiles saved in the list under the workspace from Firestore
export async function fetchProfilesFromList(db, workspaceId, listId) {
    // Check if the workspace ID is provided
    if (!workspaceId) {
        console.error("Workspace ID is required");
        return null;
    }

    // Check if the list name is provided
    if (!listId) {
        console.error("List name is required");
        return null;
    }

    try {
        // Fetch the profiles saved in the list from Firestore
        const listRef = doc(db, `workspaces/${workspaceId}/savedLists/${listId}`);
        const listDoc = await getDoc(listRef);

        if (!listDoc.exists()) {
            console.error("List not found");
            return null;
        }

        return listDoc.data().profile;
    } catch (error) {
        console.error("Error fetching profiles from saved list:", error);
        return null;
    }
}

// Delete the saved list from the workspace in Firestore
export async function deleteList(db, workspaceId, listId) {
    // Check if the workspace ID is provided
    if (!workspaceId) {
        console.error("Workspace ID is required");
        throw new Error("Workspace ID is required");
    }

    // Check if the list name is provided
    if (!listId) {
        console.error("List name is required");
        throw new Error("List name is required");
    }

    try {
        // Delete the saved list from the workspace
        await deleteDoc(doc(db, `workspaces/${workspaceId}/savedLists/${listId}`));

        return {success: true}
    } catch (error) {
        console.error("Error deleting saved list:", error);
        throw error;
    }
}

// Get the saved lists from the workspace in Firestore
export async function getLists(db, workspaceId) {
    // Check if the workspace ID is provided
    if (!workspaceId) {
        console.error("Workspace ID is required");
        return null;
    }

    try {
        // Fetch the saved lists from Firestore
        const listsQuery = query(collection(db, `workspaces/${workspaceId}/savedLists`));
        const listsSnapshot = await getDocs(listsQuery);

        const getLists = listsSnapshot.docs.map(doc => ({
            id: doc.id,
            name: doc.data().listName,
            profile: doc.data().profile,
            createdOn: doc.data().createdOn,
        }));

        return getLists;
    } catch (error) {
        console.error("Error fetching saved lists:", error);
        return null;
    }
}

// Create a new list in the workspace in Firestore
export async function createList(db, workspaceId, listName) {
    // Check if the workspace ID is provided
    if (!workspaceId) {
        console.error("Workspace ID is required");
        throw new Error("Workspace ID is required");
    }

    // Check if the list name is provided
    if (!listName) {
        console.error("List name is required");
        throw new Error("List name is required");
    }

    try {
        // Create a new document with a generated ID in the 'savedLists' collection
        const createdList = await addDoc(collection(db, `workspaces/${workspaceId}/savedLists`), {
            listName: listName,
            profile: [],
            createdOn: Timestamp.fromMillis(Date.now()),
        }); 

    return { id: createdList.id, name: listName }

    } catch (error) {
        console.error("Error creating list:", error);
        throw error;
    }
}