import React from 'react';
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from 'components/SoftTypography';
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import PropTypes from 'prop-types';
import { useLinkedIn } from 'react-linkedin-login-oauth2';

function LinkedInSSO({ linkedInSSO, setLinkedInSSO, handleLoginSuccess }) {
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirectUri: process.env.REACT_APP_CALLBACK_URL,
    onSuccess: handleLoginSuccess,
    onError: (error) => {
      console.error("Error during LinkedIn login:", error);
    },
    scope: process.env.REACT_APP_LINKEDIN_SCOPE,
  });

  return (
    <Modal
      open={linkedInSSO}
      onClose={() => setLinkedInSSO(false)}
      aria-labelledby="LinkedIn SSO"
      aria-describedby="Open LinkedIn SSO Prompt"
    >
      <SoftBox sx={modalStyle}>
        <Card>
          <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12} justifyContent="center" alignItems="center">
              <SoftTypography variant="h4" fontWeight="medium" color="dark" style={{ textAlign: "center" }}>
                Connect LinkedIn Account
              </SoftTypography>
            </Grid>
            <Grid item xs={12} justifyContent="center" alignItems="center">
              <SoftTypography variant="body2" fontWeight="light" color="dark" style={{ textAlign: "center" }}>
                You need to connect your LinkedIn account to use PropelPosts, it only takes a few seconds!
              </SoftTypography>
            </Grid>
            <Grid item xs={12} justifyContent="center" alignItems="center">
              <SoftBox mb={2} alignItems="center" justifyContent="center">
                <img
                  onClick={linkedInLogin}
                  src={linkedin}
                  alt="Sign in with Linked In"
                  style={{ maxWidth: '180px', cursor: 'pointer' }}
                />
              </SoftBox>
            </Grid>
          </Grid>
        </Card>
      </SoftBox>
    </Modal>
  );
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  minWidth: '40%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

LinkedInSSO.propTypes = {
  linkedInSSO: PropTypes.bool.isRequired,
  setLinkedInSSO: PropTypes.func.isRequired,
  handleLoginSuccess: PropTypes.func.isRequired,
};

export default LinkedInSSO;
