import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Modal from '@mui/material/Modal';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';

//Button Icons
import SpaceShip from 'examples/Icons/SpaceShip';
import { Icon } from '@mui/material';

//Import Stripe Pricing Table
import PricingCards from 'layouts/pages/account/billing/components/PricingCards';


export const FreeTrialModal = ({ open, currentUserData }) => {

    const[openPricingTable, setOpenPricingTable] = useState(false);
    // Pricing
    const [activePricingOption, setActivePricingOption] = useState("monthly");
    const [prices, setPrices] = useState(["19", "25", "90"]);

    // Function to handle the billing period change
    const handleBillingPeriodChange = () => {

      if (activePricingOption === "monthly") {
        setActivePricingOption("annual");
          setPrices(["190", "250", "900"]);
      } else {
        setActivePricingOption("monthly");
          setPrices(["19", "25", "90"]);
      }
    };
    
  return (
    <>
    <Modal
      open={open}
      onClose={() => {}} // Disable closing the modal
      aria-labelledby="full-screen-modal"
      aria-describedby="full-screen-modal-description"
    >
      <SoftBox
        sx={{
          ...modalStyle,
        }}
      >
    <Grid 
      container 
      spacing={3} 
      alignItems="center" // Align items vertically in the center
      justifyContent="center" // Align items horizontally in the center
    >
    {!openPricingTable && (
    <>
    <Grid item xs={12}>
    <SoftBox mb={2}>
      <SoftTypography variant="h2" fontWeight="medium" textAlign="center">Your Free Trial Has Expired</SoftTypography>
      <SoftTypography variant="body2" fontWeight="medium" textAlign="center">Regain uninterrupted access to all our features.</SoftTypography>
      <SoftTypography variant="body2" fontWeight="medium" textAlign="center">Don’t let your current queue go to waste – without a paid plan it will be frozen and no scheduled posts will go live.</SoftTypography>
    </SoftBox>
    </Grid>
    <Grid item xs={12}>
      <SoftBox 
        mb={2} 
        display="flex" // Set the display to flex
        justifyContent="center" // Center the content horizontally
      >
        <SoftButton 
          variant="contained"
          color="light"
          onClick={() => setOpenPricingTable(!openPricingTable)}
        >
          <Icon component={SpaceShip} />
          {'\u00A0'} Upgrade Your Subscription
        </SoftButton>
      </SoftBox>
    </Grid>

      </>
    )}

    {openPricingTable && (
    <>
    <Grid item xs={12}>
      <SoftBox mb={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <SoftTypography variant="body2" fontWeight="medium" style={{ marginRight: '8px' }}>Monthly</SoftTypography>
          <Switch
              checked={activePricingOption === "annual"}
              onChange={handleBillingPeriodChange}
              name="modalSwitch"
          />
        <SoftTypography variant="body2" fontWeight="medium" style={{ marginLeft: '8px' }}>Yearly - Save 20%</SoftTypography>
      </SoftBox>
    </Grid>
    <Grid item xs={12}>
    <SoftBox mb={2}>
        <SoftTypography variant="h2" fontWeight="medium" textAlign="center">Upgrade Your Subscription</SoftTypography>
        <SoftTypography variant="body2" fontWeight="medium" color="info" textAlign="center">Save 20% with an annual subscription</SoftTypography>
    </SoftBox>
    </Grid>
    <Grid item xs={12}>
    <SoftBox mb={2}>
      <PricingCards prices={prices} activePricingOption={activePricingOption} tenantId={currentUserData?.tenantData[0]?.tenantId} />
    </SoftBox>
    </Grid>
    </>
    )}
    </Grid>
    </SoftBox>
    </Modal>
    </>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  minWidth: '100%',
  minHeight: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
  overflowY: 'auto',
};

FreeTrialModal.propTypes = {
    open: PropTypes.bool.isRequired,
    currentUserData: PropTypes.object.isRequired,
    };
