import React, { useState, useContext } from 'react';

// Import MUI Material Components
import { Drawer, Grid, Card, CardContent } from '@mui/material';

// Import MUI Icons
import Icon from '@mui/material/Icon';

// Import PropTypes
import PropTypes from 'prop-types';

// Import User and Firebase Context
import { useUserData } from 'context/userManagement';
import FirebaseContext from 'configs/firebasecontext';

// Import HTTPS Callable Functions
import { httpsCallable } from '@firebase/functions';

// Import Soft UI Components
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';
import SoftInput from 'components/SoftInput';
import SoftAlert from 'components/SoftAlert';
import SoftButton from 'components/SoftButton';

const NewWorkspaceDrawer = ({ open, onClose }) => {
    const { userId, currentUserData, setCurrentUserData } = useUserData();
    const { functions } = useContext(FirebaseContext);
    const [workspaceName, setWorkspaceName] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Create a new workspace
    const handleCreateWorkspace = async () => {
        setIsLoading(true);

        if (workspaceName.trim() === "") {
            setError("Workspace name cannot be empty");
            setIsLoading(false);
            return;
        }

        // Check if the workspace name already exists under currentUserData.workspaceData object in field workspaceName
        if (currentUserData.workspaceData) {
            const workspaceExists = currentUserData.workspaceData.find((workspace) => workspace.workspaceName === workspaceName);
            if (workspaceExists) {
                setError("Workspace name already exists");
                setIsLoading(false);
                return;
            }
        }

        // Get the tenant ID
        const tenantId = currentUserData.tenantData[0].tenantId;

        // Create the workspace
            try {
                const createWorkspace = httpsCallable(functions, process.env.REACT_APP_CLOUD_FUNCTION_CREATE_NEW_WORKSPACE);
                const response = await createWorkspace({ workspaceName, tenantId });

                if (!response.data.success) {
                    setError("Error creating workspace");
                    setIsLoading(false);
                    return;
                }

                // Push the new workspace to the currentUserData object
                setCurrentUserData({
                    ...currentUserData,
                    workspaceData: [
                        ...currentUserData.workspaceData,
                        {
                            workspaceName,
                            workspaceOwner: userId,
                            tenantOwner: tenantId,
                            members: [userId],
                            datetimeCreated: new Date(),
                            id: response.data.id,
                        },
                    ],
                });

                // Console log the currentUserData object
                console.log("New Current UserData: ", currentUserData);

                setSuccess("Workspace created successfully");
                setWorkspaceName("");
                setIsLoading(false);
                // Close the drawer after 3 seconds
                setTimeout(() => {
                    setSuccess(null);
                    setError(null);
                    setWorkspaceName("");
                    onClose();
                }, 3000);
            } catch (error) {
                console.error("Error creating workspace: ", error);
                setError("Error creating workspace");
                setIsLoading(false);
            }
    }

    const drawerStyle = {
        width: "75%", // Default width for larger screens
        height: "95%", // Set a maximum height
        overflowY: "auto", // Set vertical overflow to auto
        bgcolor: '#FFFFFF', // Set the background color to white
        '@media (max-width: 768px)': {
            width: '100%',  // Full width on mobile screens
        },
    };

return (
    <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
            sx: drawerStyle
        }}
    >

        <Card sx={{ margin: 2, overflowY: "auto" }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SoftTypography variant="h5" gutterBottom>
                            Add new Workspace
                        </SoftTypography>
                        {error && (
                            <SoftBox display="flex" alignItems="center" justifyContent="center">
                                <SoftAlert 
                                    color="error"
                                    dismissible
                                >
                                    <Icon fontSize="small">error</Icon>
                                    <SoftTypography variant="body2" fontWeight="medium" color="white" ml={1}>
                                        {error}
                                    </SoftTypography>
                                </SoftAlert>
                            </SoftBox>
                        )}
                        {success && (
                            <SoftBox display="flex" alignItems="center" justifyContent="center">
                                <SoftAlert 
                                    color="success"
                                    dismissible
                                >
                                    <Icon fontSize="small">check_circle</Icon>
                                    <SoftTypography variant="body2" fontWeight="medium" color="white" ml={1}>
                                        {success}
                                    </SoftTypography>
                                </SoftAlert>
                            </SoftBox>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <SoftTypography variant="body2" fontWeight="bold" gutterBottom>
                            Workspace Name
                        </SoftTypography>
                        <SoftInput
                            placeholder="Workspace Name"
                            value={workspaceName}
                            onChange={(e) => setWorkspaceName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SoftButton
                            variant="contained"
                            color="primary"
                            onClick={handleCreateWorkspace}
                            fullWidth
                        >
                            {isLoading ? "Creating Workspace..." : "Create Workspace"}
                        </SoftButton>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </Drawer>
    );
}

export default NewWorkspaceDrawer;

NewWorkspaceDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
