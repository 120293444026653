import React from "react";
import propTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Billing page components
import Invoice from "layouts/pages/account/billing/components/Invoice";

function Invoices({ invoices }) {

  const currencySymbols = {
    USD: '$',
    GBP: '£',
    EUR: '€',
  };

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <SoftTypography variant="h6" fontWeight="medium">
          Invoices
        </SoftTypography>
        {/* <SoftButton variant="outlined" color="info" size="small">
          view all
        </SoftButton> */}
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {invoices?.map((invoice, index) => (
            <a key={invoice?.invoiceId} href={invoice?.url} target="_blank" rel="noopener noreferrer">
              <Invoice
                key={invoice?.invoiceId}
                date={new Date(invoice?.date * 1000).toLocaleDateString()}
                id={`#${invoice?.invoiceId}`}
                price={(invoice?.amount_paid / 100).toFixed(2)}
                currency={currencySymbols[invoice?.currency]}
                pdf={invoice?.pdf}
                noGutter={index === invoices?.length - 1}
              />
            </a>
          ))}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// PropTypes for the Invoices component
Invoices.propTypes = {
  invoices: propTypes.array.isRequired,
};

Invoices.defaultProps = {
  invoices: {},
};

export default Invoices;
