import { useState, useContext } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import chat from "assets/images/illustrations/chat.png";

import Card from "@mui/material/Card";

//Import Firebase Context
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

function SignIn() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleEmailSignIn = async () => {
      
      const auth = getAuth();
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          console.log("User signed in", user)
          // ...
          navigate("/app/dashboards/default")
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          //Create switch statement to handle different Firebase auth errors
          switch (error.code) {
            case 'auth/invalid-email':
              setError('Invalid email');
              break;
            case 'auth/user-not-found':
              setError('User not found');
              break;
            case 'auth/wrong-password':
              setError('Wrong password');
              break;
            case 'auth/user-disabled':
              setError('Your account has been disabled as your subscription has been cancelled. Please contact us to reactivate your account.');
              break;
            default:
              setError('Error signing in');
              break;          
          }
        });
    }

  return (

    <IllustrationLayout
      title="Sign In"
      description="Sign in with your PropelPosts account!"
      illustration={{
        image: chat,
        title: '"Attention is the new currency"',
        description:
          "The more effortless the writing looks, the more effort the writer actually put into the process.",
      }}
    >
      {error && (
        <SoftBox mb={3}>
          <SoftTypography variant="h6" fontWeight="bold" textAlign="center" color="error">
            {error}
          </SoftTypography>
        </SoftBox>
      )}
      <Card>
            {/* <SoftBox mb={2} paddingTop="10px">
              <img
                onClick={linkedInLogin}
                src={linkedin}
                alt="Sign in with Linked In"
                style={{ maxWidth: '180px', cursor: 'pointer' }}
              />
            </SoftBox>
        <Separator /> */}
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
        <SoftInput 
            type="email" 
            placeholder="Email" 
            size="large" 
            onChange={(e) => setEmail(e.target.value)} 
        />

        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput 
            type="password" 
            placeholder="Password" 
            size="large" 
            onChange={(e) => setPassword(e.target.value)}
          />
        </SoftBox>
        {/* <SoftBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </SoftTypography>
        </SoftBox> */}
        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="info" size="large" fullWidth onClick={handleEmailSignIn}>
            sign in
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SoftTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Forgotten your password?{" "}
            <SoftTypography
              component={Link}
              to="/authentication/reset-password"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Forgotten Password
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      </Card>
    </IllustrationLayout>
  );
}

export default SignIn;
