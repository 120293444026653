// @mui material components
import Card from "@mui/material/Card";

import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Billing page components
import Subscription from "layouts/pages/account/billing/components/Subscription";

function SubscriptionInformation({ subscriptions }) {

  console.log("Subscriptions: ", subscriptions)

  const stripeURL = process.env.REACT_APP_STRIPE_PORTAL_URL

  return (
    <Card id="subscription-information">
      <SoftBox pt={3} px={2}>
        <SoftTypography variant="h6" fontWeight="medium">
          Subscription Information
        </SoftTypography>
      </SoftBox>
      <SoftBox pt={1} pb={2} px={2}>
        <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {subscriptions?.map((subscription, index) => (
            <Subscription
              key={index}
              nickname={subscription?.nickname}
              status={subscription?.status}
              current_period_end={subscription?.current_period_end}
              cancel_at_period_end={subscription?.cancel_at_period_end}
              url={stripeURL}
            />
          ))}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

SubscriptionInformation.propTypes = {
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      nickname: PropTypes.string,
      status: PropTypes.string,
      current_period_end: PropTypes.number,
      url: PropTypes.string,
    })
  ).isRequired,
};

export default SubscriptionInformation;

