//React Components
import React, { useState } from "react";

import PropTypes from 'prop-types';

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";
import SoftTagInput from "components/SoftTagInput";

// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField";

//Context Imports
import { useUserData } from "context/userManagement";

function About({onAboutChange}) {

  // Get user context states
  const { userId, userData } = useUserData();

  const [about, setAbout] = useState("");
  const [professionalBackground, setProfessionalBackground] = useState("");
  const [presentation, setPresentation] = useState("");
  const [languages, setLanguages] = useState("");
  const [book, setBook] = useState("");

  const handleInputChange = (field, value) => {
    switch (field) {
      case "about":
        setAbout(value);
        break;
      case "professionalBackground":
        setProfessionalBackground(value);
        break;
      case "presentation":
        setPresentation(value);
        break;
      case "languages":
        setLanguages(value);
        break;
      case "book":
        setBook(value);
        break;
      default:
        break;
    }
    // Send the current data to the parent component
    onAboutChange({
      about,
      professionalBackground,
      presentation,
      languages,
      book,
      [field]: value  // Update the changed value
    });
  };

  return (
    <SoftBox>
      <SoftBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5" fontWeight="regular">
            Let&apos;s start with the basic information
          </SoftTypography>
        </SoftBox>
        <SoftTypography variant="body2" fontWeight="regular" color="text">
          In a few sentences, tell us about yourself, your professional background and how you want to present yourself to the world.
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} container justifyContent="center">
            <SoftBox position="relative" height="max-content" mx="auto">
              <SoftAvatar src={userData?.profilePic} alt="profile picture" size="xxl" variant="rounded" />
              <SoftBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
              </SoftBox>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={8}>
            <SoftBox mb={2}>
              <FormField 
                multiline label="A bit about yourself" 
                placeholder="Eg. Hobbies, Fun Facts, Interest Facts, etc" 
                onChange={(e) => handleInputChange("about", e.target.value)}
                />
            </SoftBox>
            <SoftBox mb={2}>
              <FormField 
                multiline label="Professional background" 
                placeholder="Eg. Where you've worked, what roles you've had, interesting projects, etc" 
                onChange={(e) => handleInputChange("professionalBackground", e.target.value)}
                />
            </SoftBox>
            <SoftBox>
              <FormField 
                multiline label="How do you want to present yourself?" 
                placeholder="Eg. Experienced engineer, start-up founder, consultant, etc" 
                onChange={(e) => handleInputChange("presentation", e.target.value)}
                />
            </SoftBox>
            <SoftBox>
              <FormField 
                multiline label="Languages you speak" 
                placeholder="Eg. English, French, Italian, Malay, Arabic, etc" 
                onChange={(e) => handleInputChange("languages", e.target.value)}
                />
            </SoftBox>
            <SoftBox>
              <FormField 
                multiline label="A few keywords and topics you're interested in" 
                placeholder="Eg. Start-Ups, Marketing, Finance, etc" 
                onChange={(e) => handleInputChange("book", e.target.value)}
                />
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

About.propTypes = {
  onAboutChange: PropTypes.func.isRequired,
  about: PropTypes.string,
  professionalBackground: PropTypes.string,
  presentation: PropTypes.string,
  languages: PropTypes.string,
  book: PropTypes.string,
  // ... (and so on for all the other props you might pass)
};

export default About;
