import React, { useState, useEffect, useContext } from 'react';

import { Grid, Tabs, Tab, Tooltip, IconButton } from '@mui/material';

// Import Icons
import Info from '@mui/icons-material/Info';

import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftSnackbar from 'components/SoftSnackbar';

import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

import EngageProfiles from './components/engageProfiles';
import ManageLists from './components/manageLists';

import LoadingOverlay from 'components/SharedFunctions/loadingOverlay';

import { useUserData } from "context/userManagement";
import FirebaseContext from "configs/firebasecontext";

import { httpsCallable } from '@firebase/functions';

import { fetchAllReactionsAndComments, saveProfileToList, fetchProfilesFromList, deleteList, getLists, createList } from 'components/DatabaseFunctions';

// Import Upgrade Plan Modal
import UpgradePlanModal from "components/SharedFunctions/upgradePlanModal";

const EngageGrow = () => {
    const { userId, currentUserData, currentWorkspace } = useUserData();
    const { db, functions } = useContext(FirebaseContext);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [reactionsAndComments, setReactionsAndComments] = useState([]);
    const [linkedInProfiles, setLinkedInProfiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);

    // Variables for Upgrade Plan Modal
    const [openUpgradePlanModal, setOpenUpgradePlanModal] = useState(false);
    const userPermissions = currentUserData?.tenantData[0]?.subscriptionData[0]?.planAccess || {};

    const handleTabChange = (event, newValue) => {
      setTabIndex(newValue);
    };

    // Get all reactions and comments
    const getAllReactionsAndComments = async () => {
        // Fetch all reactions and comments
        try {
            const reactionsAndComments = await fetchAllReactionsAndComments(db, currentWorkspace.id);
            console.log("Reactions and Comments:", reactionsAndComments);
            setReactionsAndComments(reactionsAndComments);
        } catch (error) {
            console.error("Error fetching all reactions and comments:", error);
            setIsLoading(false);
            // Set Snackbar Message
            setSnackbarMessage("Error fetching engagements. Please try again later.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        } finally {
            // Set Snackbar Timeout
            setTimeout(() => setSnackbarOpen(false), 3000);
        }
    };

    // Get the retrieved reactions and comments on load
    useEffect(() => {
        // Check if the user has the necessary permissions
        if (!userPermissions?.engageAndGrow) {
            setOpenUpgradePlanModal(true);
            return;
        }

        getAllReactionsAndComments();
    }, []);

    // Get the LinkedIn Profiles for the reactions and comments
    const getLinkedInProfile = async ({linkedInUID, postingProfile, reactions}) => {
        try {
            const getLinkedInReactionProfiles = httpsCallable(functions, process.env.REACT_APP_CLOUD_FUNCTION_POSTREACTIONPROFILES);
            const response = await getLinkedInReactionProfiles({
                workspaceId: currentWorkspace.id,
                linkedInUID: linkedInUID,
                postingProfile: postingProfile,
                reactions: reactions,
            });

            console.log("LinkedIn Profiles:", response.data);

            const persons = response.data.persons && response.data.persons.results ? Object.values(response.data.persons.results) : [];
            const organizations = response.data.organizations && response.data.organizations.results ? Object.values(response.data.organizations.results) : [];

            // Remove duplicates from the persons and organizations arrays
            const uniquePersons = persons.filter((person, index, self) => self.findIndex((p) => p.vanityName === person.vanityName) === index);
            const uniqueOrganizations = organizations.filter((organization, index, self) => self.findIndex((o) => o.vanityName === organization.vanityName) === index);

            // Console Log the Unique Persons and Organizations
            console.log("Unique Persons:", uniquePersons);
            console.log("Unique Organizations:", uniqueOrganizations);

            setLinkedInProfiles((prevState) => [...prevState, ...uniquePersons, ...uniqueOrganizations]);
        } catch (error) {
            console.error("Error getting LinkedIn Profile:", error);
        }
    };

    // Get all LinkedIn Profiles
    const getLinkedInProfiles = async () => {
        setIsLoading(true);

        // Get the LinkedIn Profiles for all reactions and comments
        try {
            for (const post of reactionsAndComments) {
                const combinedReactionsAndComments = [...post.reactions, ...post.comments];

                if (combinedReactionsAndComments.length > 0) {
                    const linkedInUID = post.linkedInUID;
                    const postingProfile = post.postingProfile;

                    await getLinkedInProfile({linkedInUID, postingProfile, reactions: combinedReactionsAndComments});
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error getting LinkedIn Profiles:", error);
            setIsLoading(false);
            // Snackbar Message
            setSnackbarMessage("Error getting LinkedIn Profiles. Please try again later.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        } finally {
            setIsLoading(false);
            console.log("All LinkedIn Profiles:", linkedInProfiles);
            setTimeout(() => setSnackbarOpen(false), 3000);
        }
    };

    // Get the LinkedIn Profiles for all reactions and comments on load
    useEffect(() => {
        if (reactionsAndComments.length > 0) {
            getLinkedInProfiles();
        }
    }, [reactionsAndComments]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox p={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <SoftBox>
                            <SoftTypography variant="h4" fontWeight="bold">
                                Engage and Grow
                                <Tooltip title="Find and save profiles which have interacted with your content" placement="right-start">
                                    <IconButton size="small">
                                        <Info fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </SoftTypography>
                        </SoftBox>
                    </Grid>

                <Grid item xs={12}>
                    <SoftBox mb={3}>
                        <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                            <Tab label="Engaged Profiles" />
                            <Tab label="List Management" />
                        </Tabs>
                    </SoftBox>
                </Grid>

                <Grid item xs={12}>
        
                    {tabIndex === 0 ? (
                        <EngageProfiles linkedInProfiles={linkedInProfiles} saveProfileToList={saveProfileToList} getLists={getLists} createList={createList} db={db} currentWorkspace={currentWorkspace} />
                    ) : (
                        <ManageLists getLists={getLists} fetchProfilesFromList={fetchProfilesFromList} deleteList={deleteList} db={db} currentWorkspace={currentWorkspace} />
                    )}
                </Grid>
            </Grid>

            </SoftBox>

            <Footer />

            <SoftSnackbar
                open={snackbarOpen}
                close={() => setSnackbarOpen(false)}
                dateTime={new Date().toLocaleString()}
                content={snackbarMessage}
                color={snackbarSeverity}
                icon="notifications"
                title="Notification"
            />

            {isLoading && <LoadingOverlay />}

            <UpgradePlanModal open={openUpgradePlanModal} onClose={() => setOpenUpgradePlanModal(false)} />

        </DashboardLayout>
    );
};

export default EngageGrow;
