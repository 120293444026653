import React, { useEffect, useState } from 'react';

import { Grid, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { BookmarkAdd, OpenInNew } from '@mui/icons-material';

import SoftBox from 'components/SoftBox';
import SoftSelect from 'components/SoftSelect';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';
import SoftInput from 'components/SoftInput';
import SoftSnackbar from 'components/SoftSnackbar';
import SoftBadge from 'components/SoftBadge';

// Import DataTable
import DataTable from "examples/Tables/DataTable";

// Import Drawer
import DetailedListInformation from './viewListDetailsDrawer';

import PropTypes from 'prop-types';

const ManageLists = ({ getLists, fetchProfilesFromList, deleteList, db, currentWorkspace }) => {
    // Snackbar State
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    // Lists State
    const [lists, setLists] = useState([]);
    const [selectedList, setSelectedList] = useState({});
    const [profiles, setProfiles] = useState([]);

    // Drawer State
    const [drawerOpen, setDrawerOpen] = useState(false);

    // Get all lists
    useEffect(() => {
        getLists(db, currentWorkspace.id).then((returnedLists) => {
            if (!returnedLists) {
                return;
            }
            setLists(returnedLists);
        });
    }, [getLists, db, currentWorkspace.id]);

    // Function to fetch profiles from a list
    const handleFetchProfiles = (list) => {
        if (!list) {
            return;
        }

        const listId = list.id;

        fetchProfilesFromList(db, currentWorkspace.id, listId).then((profiles) => {
            console.log("Profiles:", profiles);
            setProfiles(profiles);
            setSelectedList(list);
            setDrawerOpen(true);
        });
    };

    // Function to delete a list
    const handleDeleteList = (list) => {
        if (!list) {
            return;
        }

        const listId = list.id;

        deleteList(db, currentWorkspace.id, listId).then((response) => {
            if (response.success) {
                // Set Snackbar Message
                setSnackbarMessage("List deleted successfully.");
                setSnackbarSeverity("success");
                setOpenSnackbar(true);

                // Get all lists
                getLists(db, currentWorkspace.id).then((returnedLists) => {
                    if (!returnedLists) {
                        return;
                    }
                    setLists(returnedLists);
                });
            }
        });
    };

    // Create Data Table Rows
    const rows = lists.map((list) => {
        return {
            id: list.id,
            listName: list.name,
            listCreatedOn: new Date(list.createdOn.seconds * 1000).toLocaleString(),
            listActions: (
                <>
                    <SoftButton
                        variant="outlined"
                        color="info"
                        size="small"
                        startIcon={<BookmarkAdd />}
                        onClick={() => handleFetchProfiles(list)}
                    >
                        View
                    </SoftButton>
                    <SoftButton
                        variant="outlined"
                        color="error"
                        size="small"
                        startIcon={<BookmarkAdd />}
                        onClick={() => handleDeleteList(list)}
                        sx={{ marginLeft: 2 }}
                    >
                        Delete
                    </SoftButton>
                </>
            ),
        };
    });
    
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <SoftBox display="flex" justifyContent="center" alignItems="center">
                        <SoftTypography variant="body2" fontWeight="regular" textAlign="center">
                            Manage lists of saved profiles below. Click on &quot;View&quot; to see the profiles in a list.
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox display="flex" justifyContent="center" alignItems="center" mt={2}>
                        <SoftTypography variant="body2" fontWeight="regular" textAlign="center">
                            Export the list to a CSV file by clicking on the &quot;Export&quot; button once the list is open.
                        </SoftTypography>
                    </SoftBox>
                </Grid>
                <Grid item xs={12}>
                {lists.length === 0 && (
                    <SoftBox display="flex" justifyContent="center" alignItems="center">
                        <SoftTypography variant="h6" fontWeight="bold" textAlign="center">
                            No lists found. Create a new list to get started.
                        </SoftTypography>
                    </SoftBox>
                )}

                {lists.length > 0 && (
                    <Card>
                        <CardContent>
                            <DataTable
                                isSortable={true}
                                isSearchable={true}
                                table={{
                                    columns: [
                                        { Header: "List Name", accessor: "listName", width: "33%" },
                                        { Header: "Created On", accessor: "listCreatedOn", width: "33%"},
                                        { Header: "Actions", accessor: "listActions", width: "33%"},
                                    ],
                                    rows,
                                }}
                            />
                        </CardContent>
                    </Card>
                )}
                    
                        
                </Grid>
            </Grid>

            <SoftSnackbar
            open={openSnackbar}
            close={() => setOpenSnackbar(false)}
            dateTime={new Date().toLocaleString()}
            title="Notification"
            content={snackbarMessage}
            color={snackbarSeverity}
            icon="notifications"
            />

            <DetailedListInformation isOpen={drawerOpen} onClose={() => setDrawerOpen(false)} profiles={profiles} selectedList={selectedList} />
        </>
    );
}

ManageLists.propTypes = {
    getLists: PropTypes.func.isRequired,
    fetchProfilesFromList: PropTypes.func.isRequired,
    deleteList: PropTypes.func.isRequired,
    db: PropTypes.object.isRequired,
    currentWorkspace: PropTypes.object.isRequired,
}

export default ManageLists;
