/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard PRO React layouts
import Default from "layouts/dashboards/default";

// Soft UI Dashboard PRO React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
// import SettingsIcon from "examples/Icons/Settings";
// import Basket from "examples/Icons/Basket";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
// import CreditCard from "examples/Icons/CreditCard";

//Custom Routes
import { CustomSchedule } from "layouts/pages/scheduledposts/customSchedule";
import Billing from "layouts/pages/account/billing";
import PasswordReset from "layouts/authentication/reset-password";
import PostHistory from "layouts/pages/post-history";
import ContactUs from "layouts/pages/contact";
import SignOut from "layouts/authentication/sign-out";
import LongFormToPost from "layouts/pages/longform-conversion";
//Generate Posts
import GeneratePostContent from "layouts/pages/generate";
//New Generator
import NewsGenerator from "layouts/pages/news-generator";
// Import Post Generator Page
import PostGenerator from "layouts/pages/post-generator";
// Import Posts Page
import PostsPage from "layouts/pages/posts";
// Import Workspace Settings
import WorkspacePage from "layouts/pages/account/workspace";

// Import Test Post Generator Page
import TestPostGenerator from "layouts/pages/post-generator/test";

// Import LinkedIn Connections
import LinkedInConnections from "layouts/pages/connections";

// Import Engage Grow Page
import EngageGrow from "layouts/pages/engage-grow";

// Import Brand Management Page
import BrandManagement from "layouts/pages/brand-management";

// Import Write Post Page
import WritePost from "layouts/pages/write-post";

const routes = [
  {
    type: "collapse",
    name: "Post Management",
    key: "post-management",
    icon: <SpaceShip size="12px" />,
    collapse: [
      {
        name: "Write A Post",
        key: "write-posts",
        route: "pages/write-post",
        component: <WritePost />,
      },
      {
        name: "Your Posts",
        key: "your-posts",
        route: "pages/posts",
        component: <PostsPage />,
      },
      // {
      //   name: "Post Generator",
      //   key: "post-generator",
      //   route: "pages/post-generator",
      //   component: <PostGenerator />,
      // },
      // {
      //   name: "Post Generator - Test",
      //   key: "post-generator-test",
      //   route: "pages/post-generator/test",
      //   component: <TestPostGenerator />,
      // },
      {
        name: "Create Post Schedule",
        key: "custom-schedule",
        route: "pages/scheduledposts/custom-schedule",
        component: <CustomSchedule />,
      },
    ],
  },
  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "Post Tools",
    key: "post-tools",
    icon: <Document size="12px" />,
    collapse: [
      {
        name: "Generate Posts Ideas",
        key: "generate-posts",
        route: "pages/generate",
        component: <GeneratePostContent />,
      },
      {
        name: "Repurpose Content",
        key: "article-to-post",
        route: "pages/longform-conversion",
        component: <LongFormToPost />,
      },
    ]
  },
  { type: "divider", key: "divider-2" },
  {
    type: "collapse",
    name: "Analytics",
    key: "analytics",
    icon: <Shop size="12px" />,
    collapse: [
      {
        name: "Post Analytics",
        key: "post-analytics",
        route: "pages/post-history",
        component: <PostHistory />,
      },
      {
        name: "Leads & Engagement",
        key: "engage-grow",
        route: "pages/engage-grow",
        component: <EngageGrow />,
      }
      // {
      //   name: "LinkedIn Connections",
      //   key: "linkedin-connections",
      //   route: "pages/connections",
      //   component: <LinkedInConnections />,
      // },
    ]
  },
  { type: "divider", key: "divider-3" },
  { 
    type: "collapse",
    name: "Companies",
    key: "brand-management",
    icon: <Office size="12px" />,
    collapse: [
      {
        name: "Brand Management",
        key: "brand-management",
        route: "pages/brand-management",
        component: <BrandManagement />,
      },
    ]
  },
  { type: "divider", key: "divider-5" },
  { type: "title", title: "Admin", key: "title-admin" },
  {
    type: "collapse",
    name: "Your Account",
    key: "account-management",
    icon: <Document size="12px" />,
    collapse: [
      {
        name: "Workspace Settings",
        key: "workspace-settings",
        route: "pages/account/workspace",
        component: <WorkspacePage />,
      },
      {
        name: "Billing",
        key: "billing",
        route: "pages/account/billing",
        component: <Billing />,
      },
      {
        name: "Password Reset",
        key: "password-reset",
        route: "authentication/reset-password",
        component: <PasswordReset />,
      },
      {
        name: "Contact Us",
        key: "contact-us",
        route: "pages/contact",
        component: <ContactUs />,
      },
      {
        name: "Log Out",
        key: "log-out",
        route: "app/authentication/sign-out",
        component: <SignOut />,
      },
    ]
  },
];

export default routes;