//PropTypes
import propTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
// import SoftButton from "components/SoftButton";

// Billing page components
import Transaction from "layouts/pages/account/billing/components/Transaction";

function Transactions({ transactions }) {

  const currencySymbols = {
    usd: '$',
    gbp: '£',
    eur: '€',
  };

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Your Transaction&apos;s
        </SoftTypography>
      </SoftBox>
      <SoftBox pt={3} pb={2} px={2}>
        <SoftBox mb={2}>
          <SoftTypography
            variant="caption"
            color="text"
            fontWeight="bold"
            textTransform="uppercase"
          >
            newest
          </SoftTypography>
        </SoftBox>
        <SoftBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
            {transactions?.map((transaction, index) => (
              <>
                <a 
                  key={transaction?.transactionId} 
                  href={transaction?.receipt_url} 
                  target="_blank" 
                  rel="noopener noreferrer">
                  <Transaction
                    id={transaction?.transactionId}
                    color={transaction?.status ? "success" : "error"}
                    icon={transaction?.status ? "arrow_upward" : "arrow_downward"}
                    brand={transaction?.card_brand}
                    last_4={transaction?.card_last4}
                    currency={transaction?.currency}
                    description={transaction?.description}
                    date={`${new Date(transaction?.date * 1000).toLocaleDateString()}, at ${new Date(transaction?.date * 1000).toLocaleTimeString()}`}
                    value={
                      transaction.status 
                        ? `+ ${currencySymbols[transaction?.currency]}${(transaction?.amount/100).toFixed(2)}` 
                        : `- ${currencySymbols[transaction?.currency]}${(transaction?.amount/100).toFixed(2)}`
                    }
                  />
                </a>
              </>
            ))}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

Transactions.propTypes = {
  transactions: propTypes.array.isRequired,
};

Transactions.defaultProps = {
  transactions: [],
};

export default Transactions;
