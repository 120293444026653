import React from 'react';

import { Grid, Card } from '@mui/material';

import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';

import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

const BrandManagement = () => {
    return (
        <DashboardLayout>
            <DashboardNavbar />
        <Grid container spacing={3}>
            <Grid item xs={12}>
                    <SoftBox p={2}>
                        <SoftTypography variant="h4" fontWeight="bold">
                            Brand Management
                        </SoftTypography>
                    </SoftBox>
            </Grid>

            <Grid item xs={12}>
                    <SoftBox p={2} display="flex" justifyContent="space-between">
                        <SoftTypography variant="h6" fontWeight="regular">
                            Coming soon on the Professional and Agency plans
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox p={2} mb={2}>
                        <SoftTypography variant="body2" fontWeight="regular">
                            <SoftBox ml={2}>
                            <ul>
                                <li>Monitor mentions of your company on LinkedIn</li>
                                <li>Manage your company pages</li>
                                <li>View company page analytics</li>
                            </ul>
                            </SoftBox>
                        </SoftTypography>
                    </SoftBox>
        </Grid>
    </Grid>
    <Footer />
    </DashboardLayout>

    );
}

export default BrandManagement;