import React, { useState, useEffect, useContext } from 'react';

//Import Soft UI Dashboard React components
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftSnackbar from 'components/SoftSnackbar';
import SoftInput from 'components/SoftInput';

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

//Import Material UI components
import {
    Grid,
    Card,
    CircularProgress,
    Select,
    MenuItem,
    Tooltip,
    IconButton,
    CardContent,
    Avatar
} from '@mui/material';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveIcon from '@mui/icons-material/Save';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import ScheduleIcon from '@mui/icons-material/Schedule';

import ViewPostModal from 'layouts/pages/posts/components/viewPostModal';

//Import Firebase Context
import FirebaseContext from "configs/firebasecontext";

//Import User Context
import { useUserData } from "context/userManagement";

//Import database query function for getting AI profile
import { fetchAiProfile, storeDraft, getUserOrganizations } from "components/DatabaseFunctions";
import { httpsCallable } from '@firebase/functions';
import { set } from 'date-fns';

function GeneratePostContent() {
    //Get Firebase Context
    const { db, functions } = useContext(FirebaseContext);

    //Get User Context
    const { userId, userData, currentWorkspace } = useUserData();

    //States for snackbard
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [loading, setLoading] = useState(false);

    //States for AI profile
    const [aiProfile, setAiProfile] = useState(null);

    //Array of generated post content
    const [postContent, setPostContent] = useState([]);

    //State for ideas content
    const [ideasContent, setIdeasContent] = useState("");

    // State for handling LinkedIn profile selection
    const [selectedLinkedInProfile, setSelectedLinkedInProfile] = useState(null);
    const [profilePic, setProfilePic] = useState("");
    const [name, setName] = useState("");
    const [organizations, setOrganizations] = useState([]);
    const [postingProfileOptions, setPostingProfileOptions] = useState([]);

    // States for Opening View Post Modal
    const [viewPostModalOpen, setViewPostModalOpen] = useState(false);
    const [selectedPost, setSelectedPost] = useState([]);

    //Async function for generating post content
    const generatePostContent = async (content, useAiProfile = false) => {
        setLoading(true);

        try {
            const generatePostContentFunction = httpsCallable(functions, process.env.REACT_APP_CLOUD_FUNCTION_OPENAI_GENERATE_POST_IDEAS);
            const result = await generatePostContentFunction({ content, aiProfile: useAiProfile ? aiProfile : null });

            if (result.data.success) {
                // Console Log
                console.log("Post Content Generated:", result.data.posts);
                setPostContent(result.data.posts);
                setSnackbarMessage("Post content generated successfully.");
                setSnackbarSeverity("success");
            } else {
                setSnackbarMessage("Error generating post content: " + result.data.error);
                setSnackbarSeverity("error");
            }

            setSnackbarOpen(true);
            setTimeout(() => {
                setSnackbarOpen(false);
            }, 3000);
        } catch (error) {
            console.log(error);
            setSnackbarMessage("Error generating post content.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            setTimeout(() => {
                setSnackbarOpen(false);
            }, 3000);
        } finally {
            setLoading(false);
        }
    }

    // Use Effect for getting AI profile
    useEffect(() => {
        if (!currentWorkspace || !selectedLinkedInProfile) return;

        const workspaceId = currentWorkspace.id;

        async function fetchProfile() {
            const aiProfileText = await fetchAiProfile(db, workspaceId, selectedLinkedInProfile);

            if (aiProfileText) {
                setAiProfile(aiProfileText);
                console.log("Loaded AI Profile", aiProfileText);
            } else {
                setAiProfile(null);
                console.log("User doesn't have a profile or an error occurred.");
            }
        }

        fetchProfile(); // Invoke the async function
    }, [currentWorkspace, selectedLinkedInProfile]);

    // Function to Handle Save to Drafts Button
    const handleSaveToDrafts = async (post) => {
        // Check if the user has selected a LinkedIn profile
        if (!db || !currentWorkspace || !userId || !post || !selectedLinkedInProfile) {
            return;
        }

        // Save the post idea to drafts in the database - postContent, postStatus, dateTime, userId, db, postImageUrl, postImageMetaData, initialComment, postingProfile, currentWorkspaceId, linkedInUID

        const workspaceId = currentWorkspace.id;
        const postImageUrl = post.postImage || "";
        const postImageMetaData = post.postImageMetadata || "";
        const initialComment = post.content || "";
        const postingProfile = "";
        const linkedInUID = selectedLinkedInProfile;
        const postStatus = "Draft";
        const dateTime = new Date().toLocaleString();

        try {
            await storeDraft(post, postStatus, dateTime, userId, db, postImageUrl, postImageMetaData, initialComment, postingProfile, workspaceId, linkedInUID);

            setSnackbarMessage("Post saved to drafts successfully.");
            setSnackbarSeverity("success");
        } catch (error) {
            console.log(error);
            setSnackbarMessage("Error saving post to drafts.");
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
            setTimeout(() => {
                setSnackbarOpen(false);
            }, 3000);
        }
    };

    // Get the user's organizations and LinkedIn accounts
    useEffect(() => {
        if (!userId || !db || !currentWorkspace) {
            return;
        }

        const fetchData = async () => {
            const data = await getUserOrganizations(db, userId, currentWorkspace.id);
            const linkedInAccounts = currentWorkspace?.linkedInAccounts || {};

            const linkedInAccountsOptions = Object.values(linkedInAccounts).map((account) => ({
                value: account.profileURN,
                label: account.name,
                image: account.profilePic || "",
                type: "account", // to distinguish between accounts and organizations
            }));

            let connectedOrgsOptions = [];
            if (data) {
                const filteredData = data.filter((org) => currentWorkspace.connectedOrganizations.includes(org.organizationURN));
                setOrganizations(filteredData);

                connectedOrgsOptions = filteredData.map((org) => ({
                    value: org.organizationURN,
                    label: org.name,
                    image: org.logoUrl || "",
                    type: "organization", // to distinguish between accounts and organizations
                }));
            }

            const combinedOptions = [...linkedInAccountsOptions, ...connectedOrgsOptions];
            setPostingProfileOptions(combinedOptions);

            if (combinedOptions.length > 0) {
                setSelectedLinkedInProfile(combinedOptions[0].value);
            }
        };

        fetchData();
    }, [userId, db, currentWorkspace]);

    // Function to handle opening view post modal
    const handleViewPostModalOpen = (post) => {

       // Console Log
        console.log("View Post Modal Opened:", post);

        // Adjust the 'post' variable to include .content for the modal to display the post content
        const postWithContent = {
            ...post,
            content: post,
        };

        setSelectedPost(postWithContent);

        setViewPostModalOpen(true);
    };

    // Function to handle posting profile change
    const handlePostingProfileChange = (event) => {
        const selectedProfile = postingProfileOptions.find(profile => profile.value === event.target.value);

        setSelectedLinkedInProfile(selectedProfile?.value || "");
        setProfilePic(selectedProfile?.image || "");
        setName(selectedProfile?.label || "");
    };

    // Function to render profile option
    const renderProfileOption = (option) => (
        <MenuItem key={option.value} value={option.value}>
            <img src={option.image} alt={option.label} style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
            {option.label}
        </MenuItem>
    );

    // Function to render posts
    const renderPosts = (posts) => (
        <Grid container spacing={3}>
            {posts?.map((post, index) => (
                <Grid item xs={12} md={4} key={index}>
                    <Card 
                        style={{ width: '100%', height: '200px', padding: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <CardContent>
                            {/* <SoftBox display="flex" alignItems="center" mb={2}>
                                <Avatar src={profilePic} alt="Author" />
                                <SoftBox display="flex" flexDirection="column" ml={2}>
                                    <SoftTypography variant="caption" fontWeight="bold" gutterBottom>
                                        {name}
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox> */}
                            <SoftBox display="flex" alignItems="center" mb={2}>
                                <SoftTypography variant="body2" fontWeight="regular" ml={2}>
                                    {post.substring(0, 100)}...
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox display="flex" justifyContent="flex-start" alignItems="center" mt={2}>
                                <IconButton aria-label="view post" onClick={() => handleViewPostModalOpen(post)}>
                                    <OpenInNewIcon />
                                </IconButton>
                                <IconButton aria-label="save to drafts" onClick={() => handleSaveToDrafts(post)}>
                                    <SaveIcon />
                                </IconButton>
                            </SoftBox>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );

    return (
        <>
            <SoftSnackbar
                open={snackbarOpen}
                close={() => setSnackbarOpen(false)}
                dateTime={new Date().toLocaleString()}
                content={snackbarMessage}
                color={snackbarSeverity}
                icon="notifications"
                title="Notification"
            />
            <DashboardLayout>
                <DashboardNavbar />
                <SoftBox p={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <SoftBox mb={2}>
                                <SoftTypography variant="h4" fontWeight="bold">
                                    Generate Post Ideas
                                    <Tooltip
                                        title="Generate up to 10 post content ideas that you can use for your LinkedIn posts"
                                        placement="right-start"
                                    >
                                        <IconButton size="small">
                                            <InfoIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SoftBox mb={3} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Card
                                    style={{ width: '100%', padding: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <SoftTypography variant="body2" fontWeight="light" color="dark" textAlign="center">
                                        Select a LinkedIn profile to generate post ideas.
                                    </SoftTypography>
                                    <SoftBox mt={2} width="70%">
                                        <Select
                                            size="small"
                                            displayEmpty
                                            value={selectedLinkedInProfile}
                                            onChange={handlePostingProfileChange}
                                            renderValue={(selected) => {
                                                if (!selected) {
                                                    return <em>Select Profile</em>;
                                                }

                                                const selectedOption = postingProfileOptions.find(option => option.value === selected);
                                                return (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={selectedOption?.image || ""} alt="Profile Picture" style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
                                                        {selectedOption?.label || ""}
                                                    </div>
                                                );
                                            }}
                                        >
                                            {postingProfileOptions.map(renderProfileOption)}
                                        </Select>
                                    </SoftBox>
                                    <SoftBox mt={2}>
                                        {aiProfile ? (
                                            <SoftButton onClick={() => generatePostContent("", true)} color="info" variant="contained">
                                                {loading ? "Loading..." : "Generate Using AI Profile"}
                                            </SoftButton>
                                        ) : (
                                            <SoftTypography variant="body2" fontWeight="light" color="dark">
                                                You need to create an AI profile first.
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Card>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SoftBox mb={3} width="100%" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Card
                                    style={{ width: '100%', padding: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <SoftTypography variant="body2" fontWeight="light" color="dark" textAlign="center">
                                        Generate 10 custom post content ideas.
                                    </SoftTypography>
                                    <SoftBox mt={2} width="70%">
                                        <SoftInput
                                            id="ideasContent"
                                            placeholder="Post topic..."
                                            fullWidth
                                            value={ideasContent}
                                            onChange={(event) => setIdeasContent(event.target.value)}
                                        />
                                    </SoftBox>
                                    <SoftBox mb={2} mt={2}>
                                        <SoftButton onClick={() => generatePostContent(ideasContent)} color="info" variant="contained">
                                            {loading ? "Loading..." : "Generate Custom Ideas"}
                                        </SoftButton>
                                    </SoftBox>
                                </Card>
                            </SoftBox>
                        </Grid>
                        {postContent.length > 0 && (
                            <Grid item xs={12}>
                                <SoftBox>
                                    {loading ? (
                                        <CircularProgress />
                                    ) : (
                                        renderPosts(postContent)
                                    )}
                                </SoftBox>
                            </Grid>
                        )    
                        }
                    </Grid>
                </SoftBox>
                <Footer />
            </DashboardLayout>

            
        <ViewPostModal
            open={viewPostModalOpen}
            onClose={() => setViewPostModalOpen(false)}
            post={selectedPost}
        />
        </>

    );
}

export default GeneratePostContent;
