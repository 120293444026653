import React from 'react';
import { Modal } from '@mui/material';

// Soft UI Dashboard PRO React components
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

const OnBoardingComplete = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <SoftBox sx={{ ...modalStyle }}>
        <SoftBox display="flex" justifyContent="center" alignItems="center" mb={2}>
        <SoftTypography variant="h4" fontWeight="bold">
            Congratulations! You&apos;ve completed the onboarding steps.
        </SoftTypography>
        </SoftBox>
        <SoftBox mb={2}>
        <SoftTypography variant="body2" fontWeight="regular">
            You&apos;re now ready to use PropelPosts and claim your free gift!
        </SoftTypography>
        </SoftBox>
        <SoftBox mb={2}>
        <SoftTypography variant="body2" fontWeight="regular">
            Use the code below to get 35% off any PropelPosts subscription plan for the first year!
        </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" justifyContent="center" alignItems="center" mb={2}>
        <SoftTypography variant="body2" fontWeight="regular">
            Code: <strong>WELCOME35</strong>
        </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" justifyContent="center" alignItems="center" mb={2}>
        <SoftButton variant="contained" size="small" color="primary" onClick={onClose} sx={{ mt: 2 }}>
          Close
        </SoftButton>
        </SoftBox>
      </SoftBox>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  minWidth: '40%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

OnBoardingComplete.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default OnBoardingComplete;
