import { useEffect, useContext } from "react";

// react-router components
import { useNavigate } from "react-router-dom";

// Import Firebase Context
import FirebaseContext from "configs/firebasecontext";

//Import User Context
import { useUserData } from "context/userManagement";

//Import Firebase Auth
import {signOut} from "firebase/auth";

function SignOut () {

    const navigate = useNavigate();

    //User context states
    const { setUserId, setUserData, setCurrentUserData, setCurrentWorkspace } = useUserData();

    //Firebase Context
    const { auth } = useContext(FirebaseContext);

    useEffect(() => {
          // Clear user data from local storage and context
        const clearUserData = () => {
            localStorage.removeItem("userId");
            localStorage.removeItem("userData");
            localStorage.removeItem("currentUserData");
            localStorage.removeItem("currentWorkspace");
            setUserId(null);
            setUserData(null);
            setCurrentUserData(null);
            setCurrentWorkspace(null);
            signOut(auth);
            navigate("/app/sign-in");
        };

        clearUserData();
    }
    , []);

}

export default SignOut;