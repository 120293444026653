import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Drawer, Grid } from '@mui/material';
import { BookmarkAdd, OpenInNew } from '@mui/icons-material';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import DataTable from "examples/Tables/DataTable";
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

function DetailedListInformation({ isOpen, onClose, profiles, selectedList }) {
    console.log("Selected List:", selectedList);
    console.log("Profiles:", profiles);

    // Convert profiles to CSV
    const exportToCSV = () => {
        const csvData = profiles.map(profile => {
            const isCompany = profile?.localizedWebsite;
            const name = isCompany ? profile?.localizedName : `${profile?.localizedFirstName} ${profile?.localizedLastName}`;
            const headline = profile?.localizedHeadline || "No headline available";

            return {
                name: name,
                type: isCompany ? "Company" : "Person",
                headline: headline,
                profileLink: `https://www.linkedin.com/in/${profile?.vanityName}`
            };
        });

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `${selectedList.name}_profiles.csv`);
    };

    // Set the rows for the DataTable  
    const rows = profiles.map((profile) => {
        const isCompany = profile?.localizedWebsite;
        const name = isCompany ? profile?.localizedName : `${profile?.localizedFirstName} ${profile?.localizedLastName}`;
        const headline = profile?.localizedHeadline?.substring(0, 45) + "..." || "No headline available";

        return {
            name: name,
            type: isCompany ? "Company" : "Person",
            headline: headline,
            actions: (
                <SoftBox display="flex" justifyContent="center">
                    <SoftButton
                        variant="outlined"
                        color="info"
                        size="small"
                        startIcon={<Tooltip title="View Profile"><OpenInNew /></Tooltip>}
                        onClick={() => window.open(`https://www.linkedin.com/in/${profile?.vanityName}`, "_blank")}
                    >
                        View Profile
                    </SoftButton>
                </SoftBox>
            ),
        };
    });

    return (
        <Drawer
            anchor="right"
            open={isOpen}
            onClose={onClose}
            PaperProps={{
                sx: drawerStyle
            }}
        >
            <SoftBox p={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SoftBox display="flex" justifyContent="space-between">
                            <SoftTypography variant="h6" fontWeight="bold">
                                {selectedList.name}
                            </SoftTypography>
                            <SoftButton
                                variant="outlined"
                                color="info"
                                size="small"
                                startIcon={<Tooltip title="Export To CSV"><BookmarkAdd /></Tooltip>}
                                onClick={exportToCSV}
                            >
                                Export To CSV
                            </SoftButton>
                        </SoftBox>
                    </Grid>
                    <Grid item xs={12}>
                        {rows.length > 0 && (
                            <DataTable
                                isSortable={true}
                                isSearchable={true}
                                table={{
                                    columns: [
                                        { Header: "Name", accessor: "name", width: "20%" },
                                        { Header: "Type", accessor: "type", width: "20%" },
                                        { Header: "Headline", accessor: "headline", width: "30%" },
                                        { Header: "Actions", accessor: "actions", width: "20%" },
                                    ],
                                    rows,
                                }}
                            />
                        )}

                        {rows.length === 0 && (
                            <SoftBox display="flex" justifyContent="center">
                                <SoftTypography variant="h6" fontWeight="regular">
                                    No profiles found in this list.
                                </SoftTypography>
                            </SoftBox>
                        )}

                    </Grid>
                    <Grid item xs={12}>
                        <SoftBox display="flex" justifyContent="center">
                            <SoftButton variant="contained" size="small" color="dark" onClick={onClose}>Close</SoftButton>
                        </SoftBox>
                    </Grid>
                </Grid>
            </SoftBox>
        </Drawer>
    );
}

const drawerStyle = {
    padding: 2, // Add padding to the drawer
    width: "85%", // Default width for larger screens
    height: "95%", // Set a maximum height
    overflowY: "auto", // Set vertical overflow to auto
    bgcolor: '#FFFFFF', // Set the background color to white
    '@media (max-width: 768px)': {
        width: '100%',  // Full width on mobile screens
    },
};

DetailedListInformation.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    profiles: PropTypes.array,
    selectedList: PropTypes.object,
};

export default DetailedListInformation;
