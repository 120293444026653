// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

function Subscription({ nickname, status, current_period_end, cancel_at_period_end, url }) {

  console.log("Subscription: ", nickname, status, current_period_end, url)
  return (
    <SoftBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor="grey-100"
      borderRadius="lg"
      p={3}
      mt={2}
    >
      <SoftBox width="100%" display="flex" flexDirection="column">
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
        >
          <SoftTypography variant="button" fontWeight="medium" textTransform="capitalize">
            {nickname}
          </SoftTypography>

          <SoftBox
            display="flex"
            alignItems="center"
            mt={{ xs: 2, sm: 0 }}
            ml={{ xs: -1.5, sm: 0 }}
          >
            <SoftBox mr={1}>
              <SoftButton variant="text" color="error" href={url} target="_blank" rel="noopener noreferrer">
                <Icon>delete</Icon>&nbsp;delete
              </SoftButton>
            </SoftBox>
            <SoftButton variant="text" color="dark" href={url} target="_blank" rel="noopener noreferrer">
              <Icon>edit</Icon>&nbsp;edit
            </SoftButton>
          </SoftBox>
        </SoftBox>
        <SoftBox mb={1} lineHeight={0}>
          <SoftTypography variant="caption" color="text">
            Subscription:&nbsp;&nbsp;&nbsp;
            <SoftTypography variant="caption" fontWeight="medium" textTransform="capitalize">
              {nickname}
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
        <SoftBox mb={1} lineHeight={0}>
          <SoftTypography variant="caption" color="text">
            Status:&nbsp;&nbsp;&nbsp;
            <SoftTypography variant="caption" fontWeight="medium" textTransform="capitalize">
            {cancel_at_period_end ? "Cancelled" : status}
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
        <SoftBox mb={1} lineHeight={0}>
        <SoftTypography variant="caption" color="text">
          {cancel_at_period_end ? "Subscription Live Until:" : "Next Billing Date:"}&nbsp;&nbsp;&nbsp;
          <SoftTypography variant="caption" fontWeight="medium">
            {new Date((current_period_end) * 1000).toLocaleDateString()}
          </SoftTypography>
        </SoftTypography>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

// Typechecking props for the Subscription
Subscription.propTypes = {
  nickname: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  current_period_end: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  cancel_at_period_end: PropTypes.bool,
};

Subscription.defaultProps = {
  cancel_at_period_end: false,
};

export default Subscription;


