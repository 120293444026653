import React, { useState, useContext } from 'react';

// Import MUI components
import { Grid, IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert as MoreVertIcon, Add as AddIcon } from '@mui/icons-material';

// Import Soft UI components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import SoftSnackbar from 'components/SoftSnackbar';

// Import User Data Context
import { useUserData } from "context/userManagement";

// Import Firebase
import FirebaseContext from "configs/firebasecontext";

// Import Database Function
import { updateWorkspaceName, removeLinkedInAccount } from "components/DatabaseFunctions";

// Import LinkedIn SSO
import LinkedInSSO from "components/SharedFunctions/linkedInSSO";
import axios from 'axios';

// Import Upgrade Plan Modal
import UpgradePlanModal from "components/SharedFunctions/upgradePlanModal";

const WorkspaceSettings = () => {
  const { db } = useContext(FirebaseContext);
  const { userId, currentWorkspace, setCurrentWorkspace, currentUserData } = useUserData();
  const [newWorkspaceName, setNewWorkspaceName] = useState(currentWorkspace?.workspaceName || 'default');
  const [isSaving, setIsSaving] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [linkedInSSO, setLinkedInSSO] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);

  // Variables for Upgrade Plan Modal
  const [openUpgradePlanModal, setOpenUpgradePlanModal] = useState(false);
  const userPermissions = currentUserData?.tenantData[0]?.subscriptionData[0]?.planAccess || {};
  const maxLinkedInAccounts = currentUserData?.tenantData[0]?.subscriptionData[0]?.accountLimit || 1;

  // Menu functions
  const handleMenuOpen = (event, account) => {
    setAnchorEl(event.currentTarget);
    setSelectedAccount(account);
    console.log("Selected Account: ", account); // Debugging
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedAccount(null);
  };

  // Update workspace name function
  const handleUpdateWorkspaceName = async (workspaceName) => {
    setIsSaving(true);
    try {
      await updateWorkspaceName(db, currentWorkspace.id, workspaceName, userId);
      const updatedWorkspace = { ...currentWorkspace, workspaceName };

      setCurrentWorkspace(updatedWorkspace);
      setSnackbarMessage("Workspace name updated successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setTimeout(() => setSnackbarOpen(false), 3000);
    } catch (error) {
      console.error("Error updating workspace name:", error);
      setSnackbarMessage("Error updating workspace name: " + error.message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setTimeout(() => setSnackbarOpen(false), 3000);
    } finally {
      setIsSaving(false);
    }
  };

  const handleLoginSuccess = async (code) => {
    try {
      const workspaceId = currentWorkspace.id;
      const firebaseuid = userId;
      const additionalAccount = true;
      //Console log code
      // console.log("LinkedIn Code:", code);
      // console.log("Firebase UID:", firebaseuid);
      // console.log("Workspace ID:", workspaceId);
      const profileResponse = await axios.post(process.env.REACT_APP_CLOUD_FUNCTION_LINKEDINTOKEN, { code, workspaceId, firebaseuid, additionalAccount });

      // Console log profile response
      console.log("Profile Response:", profileResponse);

      const newLinkedInAccount = profileResponse.data;

      const updatedWorkspace = {
        ...currentWorkspace,
        linkedInAccounts: {
          ...currentWorkspace.linkedInAccounts,
          [newLinkedInAccount.uid]: newLinkedInAccount
        }
      };

      setCurrentWorkspace(updatedWorkspace);

      // Close the LinkedIn SSO modal
      setLinkedInSSO(false);

      setSnackbarMessage("LinkedIn account added successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setTimeout(() => setSnackbarOpen(false), 3000);
    } catch (error) {
      console.error("Error during LinkedIn login process:", error);

      // Close the LinkedIn SSO modal
      setLinkedInSSO(false);
      
      setSnackbarMessage("Error connecting LinkedIn account. Please try again or contact support.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setTimeout(() => setSnackbarOpen(false), 3000);
    }
  };

  const handleRemoveAccount = async (accountId) => {
    try {
      
      await removeLinkedInAccount(db, currentWorkspace.id, accountId);

      setSnackbarMessage("LinkedIn account removed successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setTimeout(() => setSnackbarOpen(false), 3000);
    } catch (error) {
      console.error("Error removing LinkedIn account:", error);
      setSnackbarMessage("Error removing LinkedIn account. Please try again or contact support.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setTimeout(() => setSnackbarOpen(false), 3000);
    } finally {
      handleMenuClose();

      // Remove the account from the current workspace
      const updatedWorkspace = { ...currentWorkspace };
      delete updatedWorkspace.linkedInAccounts[accountId];
      setCurrentWorkspace(updatedWorkspace);
      
    }
  };

  // Handle click Add Account button
  const handleClickAddAccount = () => {

    // Check if the user has a LinkedIn account already connected or if the linkedInAccounts doesn't exist
    if (!currentWorkspace?.linkedInAccounts || Object.keys(currentWorkspace.linkedInAccounts).length === 0) {
      setLinkedInSSO(true);
      return;
    }

    // Check if the tenant has permissions to add more LinkedIn accounts on their plan
    if (!userPermissions?.multipleAccounts) {
      setOpenUpgradePlanModal(true);
      setLinkedInSSO(false);
      return;
    }

    // Check if the user has reached the maximum number of LinkedIn accounts
    if (Object.keys(currentWorkspace.linkedInAccounts).length >= maxLinkedInAccounts) {
      setSnackbarMessage("You have reached the maximum number of LinkedIn accounts for your plan. Please contact support to add more accounts.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setTimeout(() => setSnackbarOpen(false), 3000);
      setLinkedInSSO(false);
      setOpenUpgradePlanModal(true);
      return;
    }

    setLinkedInSSO(true);
  }

  return (
    <>
      <Grid container spacing={3}>
      {currentWorkspace?.workspaceOwner === userId ? (
        <>
        <Grid item xs={12}>
          <SoftTypography variant="h6" fontWeight="bold" mb={2}>
            Workspace Name
          </SoftTypography>

              <SoftInput
                value={currentWorkspace?.workspaceName}
                size="small"
                width="150px"
                placeholder="Workspace Name"
                onChange={(e) => setNewWorkspaceName(e.target.value)}
                sx={{ width: '40%' }}
              />
              <SoftTypography variant="caption">
              You can use a company or brand name.
            </SoftTypography>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-start">
              <SoftButton variant="contained" color="primary" sx={{ mr: 2 }} onClick={() => handleUpdateWorkspaceName(newWorkspaceName)}>
                {isSaving ? 'Saving...' : 'Save Changes'}
              </SoftButton>
            </Grid>
          </>
          ) : (
            <>
            <Grid item xs={12}>
              <SoftTypography variant="h6" fontWeight="bold" mb={2}>
                Workspace Name
              </SoftTypography>
            </Grid>
            <Grid item xs={12}>
              <SoftTypography variant="body2" fontWeight="medium" mb={2}>
                {currentWorkspace?.workspaceName || 'default'}
              </SoftTypography>
            </Grid>
            </>
          )}

        <Grid item xs={12}>
          <SoftTypography variant="h6" fontWeight="bold" mb={2}>
            Connected LinkedIn Accounts
          </SoftTypography>
          <SoftTypography variant="body2" mb={3}>
            Manage LinkedIn accounts connected to this workspace
          </SoftTypography>
          {currentWorkspace?.linkedInAccounts &&
            Object.values(currentWorkspace.linkedInAccounts).map((account) => (
              <SoftBox display="flex" alignItems="center" mb={2} key={account?.id}>
                <img
                  src={account.profilePic || ''}
                  alt="LinkedIn Avatar"
                  style={{ borderRadius: '50%', marginRight: '10px', width: '40px', height: '40px' }}
                />
                <SoftBox>
                  <SoftTypography variant="body1">{account.name}</SoftTypography>
                  <SoftTypography variant="body2">
                    Account needs reconnecting on: {new Date(account?.refreshTokenExpiresIn?._seconds * 1000).toLocaleDateString() || 'Unknown'}
                  </SoftTypography>
                </SoftBox>
                <IconButton aria-label="more" sx={{ ml: 'auto' }} onClick={(e) => handleMenuOpen(e, account)}>
                  <MoreVertIcon />
                </IconButton>
              </SoftBox>
            ))}
          <SoftButton variant="outlined" startIcon={<AddIcon />} color="primary" onClick={handleClickAddAccount}>
            Add Account
          </SoftButton>
        </Grid>
      </Grid>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleRemoveAccount(selectedAccount.id)}>Remove Account</MenuItem>
        <MenuItem onClick={handleMenuClose}>Reconnect Account</MenuItem>
      </Menu>

      <LinkedInSSO linkedInSSO={linkedInSSO} setLinkedInSSO={setLinkedInSSO} handleLoginSuccess={handleLoginSuccess} />

      <SoftSnackbar
        open={snackbarOpen}
        close={() => setSnackbarOpen(false)}
        dateTime={new Date().toLocaleString()}
        content={snackbarMessage}
        color={snackbarSeverity}
        icon="notifications"
        title="Notification"
      />

      <UpgradePlanModal open={openUpgradePlanModal} onClose={() => setOpenUpgradePlanModal(false)} />
    </>
  );
};

export default WorkspaceSettings;
