import React, { createContext, useContext, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import FirebaseContext from "configs/firebasecontext";
import { httpsCallable } from "firebase/functions";

const UserDataContext = createContext();

export const useUserData = () => useContext(UserDataContext);

export const UserDataProvider = ({ children }) => {
    console.log("UserDataProvider mounted");

    const [userId, setUserId] = useState(null);
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [currentUserData, setCurrentUserData] = useState(null);
    const [currentWorkspace, setCurrentWorkspace] = useState(null);
    const [availableWorkspaces, setAvailableWorkspaces] = useState([]);
    const [authInitialized, setAuthInitialized] = useState(false);

    const { functions } = useContext(FirebaseContext);
    const auth = getAuth();

    useEffect(() => {
        console.log("Setting up auth state listener");
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log("Auth state changed - user:", user);
            setAuthInitialized(true);
            if (user) {
                setUserId(user.uid);
                setUser(user);
            } else {
                setUserId(null);
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, [auth]);

    const getUser = async () => {
        if (!userId) {
            console.log("No userId found");
            return null;
        }
        console.log("Calling function with userId:", userId);
        try {
            const getUserByFirebaseUID = httpsCallable(functions, process.env.REACT_APP_CLOUD_FUNCTION_GETUSERBYFIREBASEUID);
            const result = await getUserByFirebaseUID({ userId });
            console.log("Function call successful, result:", result);
            return result.data;
        } catch (error) {
            console.error("Error calling function:", error);
            console.error("Error details:", error.message, error.stack);
            throw error;
        }
    };

    const { data: fetchedUserData, isLoading, error } = useQuery(['user', userId], getUser, {
        enabled: !!userId,
        staleTime: 5 * 60 * 1000,
        onSuccess: (data) => {
            console.log("Query successful, data:", data);
            setUserData(data.userData);
            setCurrentUserData(data);
            localStorage.setItem("userData", JSON.stringify(data.userData));
            localStorage.setItem("currentUserData", JSON.stringify(data));

            setAvailableWorkspaces(data.workspaceData || []);

            const persistedWorkspace = localStorage.getItem("currentWorkspace");
            if (persistedWorkspace) {
                setCurrentWorkspace(JSON.parse(persistedWorkspace));
            } else {
                setCurrentWorkspace(data.workspaceData?.[0] ?? null);
            }
        },
        onError: (err) => {
            console.error("Query error:", err);
            console.error("Error details:", err.message, err.stack);
        }
    });

    useEffect(() => {
        console.log("Loading cached data");
        const cachedUserData = localStorage.getItem("userData");
        const cachedCurrentUserData = localStorage.getItem("currentUserData");
        if (cachedUserData) {
            console.log("Loaded cached userData");
            setUserData(JSON.parse(cachedUserData));
        }
        if (cachedCurrentUserData) {
            console.log("Loaded cached currentUserData");
            setCurrentUserData(JSON.parse(cachedCurrentUserData));
        }
    }, []);

    console.log("Current state - userId:", userId, "user:", user, "authInitialized:", authInitialized);

    const value = {
        userId,
        setUserId,
        user,
        setUser,
        userData,
        setUserData,
        currentUserData,
        setCurrentUserData,
        currentWorkspace,
        setCurrentWorkspace,
        availableWorkspaces,
        setAvailableWorkspaces,
        isLoading,
        error,
        authInitialized
    };

    console.log("UserDataProvider value:", value);

    if (!authInitialized) {
        return <div>Initializing authentication...</div>;
    }

    return (
        <UserDataContext.Provider value={value}>
            {children}
        </UserDataContext.Provider>
    );
};

UserDataProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default UserDataProvider;