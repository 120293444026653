import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Import Material UI Components
import { Drawer, Grid, Card, Button, IconButton, Typography, Select, Menu, MenuItem } from '@mui/material';

// Soft UI Components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';

// LinkedIn Profile Card
import LinkedInProfileCard from 'examples/Cards/ProfileCards/LinkedInProfileCard';

// Import MUI Icons
import { ArrowForward, ArrowBack, Close } from '@mui/icons-material';

// Import the Database Functions
import { getUserOrganizations } from 'components/DatabaseFunctions';

import { handleStoreDraft } from "components/SharedFunctions/postFunctions";

import SoftSnackbar from 'components/SoftSnackbar';


function PostGrid({ posts = [], userData, onOpen, onClose, currentWorkspace, db, userId }) {
  const [selectedPost, setSelectedPost] = useState(null);
  const [showFullPost, setShowFullPost] = useState(false);
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const postsPerPage = isMobile ? 1 : 3;

  // States for Organizations
  const [organizations, setOrganizations] = useState([]);


  // States for Posting Profile
  const [postingProfileOptions, setPostingProfileOptions] = useState([]);
  const [linkedInPostingAccount, setLinkedInPostingAccount] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [name, setName] = useState("");
  const [headline, setHeadline] = useState("");
  const [postingProfile, setPostingProfile] = useState("");

  // States for Activities
  const [isSaving, setIsSaving] = useState(false);

  // States for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLeftArrowClick = () => {
    setCurrentStartIndex(Math.max(currentStartIndex - postsPerPage, 0));
  };

  const handleRightArrowClick = () => {
    setCurrentStartIndex(Math.min(currentStartIndex + postsPerPage, posts.length - postsPerPage));
  };

  const handleClosePostPreview = () => {
    setSelectedPost(null);
    setShowFullPost(false);
  };

  // Function to render the profile option
  const handleSaveDraft = async () => {
    const linkedInUID = linkedInPostingAccount;

    if (!linkedInUID) {
      return;
    }

    // Loop through the posts and store them as drafts
    for (let i = 0; i < posts.length; i++) {

      const postContent = posts[i];
      const postImageUrl = "";
      const imageMetaData = "";
      const initialComment = "";
      const userId = userData?.id;
      
        const { saveStatus, saveComplete } = await handleStoreDraft(
          postingProfile,
          postContent,
          userId,
          db,
          postImageUrl,
          imageMetaData,
          initialComment,
          currentWorkspace.id,
          linkedInUID
        );

        if (saveStatus) {
          console.log("Post Saved Successfully");
          console.log("Save Complete:", saveComplete);
          console.log("Save State:", saveStatus);
          // Set Snackbar Message
          setSnackbarMessage("Post Saved Successfully");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          setTimeout(() => setSnackbarOpen(false), 3000);
          setIsSaving(false);
          // Close the Drawer
          onClose();
        } else {
          console.log("Error Saving Post");
          console.log("Save Complete:", saveComplete);
          console.log("Save State:", saveStatus);
          // Set Snackbar Message
          setSnackbarMessage("Error Saving Post. Please try again later.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setTimeout(() => setSnackbarOpen(false), 3000);
          setIsSaving(false);
        }
    }
  };

  // Get the user's organizations and LinkedIn accounts
  useEffect(() => {
    if (!userId || !db || !currentWorkspace) {
      return;
    }
      
    const fetchOrganizationsData = async () => {
      const data = await getUserOrganizations(db, userId, currentWorkspace.id);
      const linkedInAccounts = currentWorkspace?.linkedInAccounts || {};

      const linkedInAccountsOptions = Object.values(linkedInAccounts).map((account) => ({
        value: account.profileURN,
        label: account.name,
        image: account.profilePic || "",
        type: "account", // to distinguish between accounts and organizations
      }));

      let connectedOrgsOptions = [];
      if (data) {
        const filteredData = data.filter((org) => currentWorkspace.connectedOrganizations.includes(org.organizationURN));
        setOrganizations(filteredData);

        console.log("Filtered Data:", filteredData);

        connectedOrgsOptions = filteredData.map((org) => ({
          value: org.organizationURN,
          label: org.name,
          image: org.logoUrl || "",
          type: "organization", // to distinguish between accounts and organizations
        }));
      }

      const combinedOptions = [...linkedInAccountsOptions, ...connectedOrgsOptions];
      setPostingProfileOptions(combinedOptions);

      if (combinedOptions.length > 0) {
        setLinkedInPostingAccount(combinedOptions[0].value);
        setPostingProfile(combinedOptions[0].type === 'organization' ? 'Organisation' : 'Personal');
      }
    };

    fetchOrganizationsData();

  }, [userId, db, currentWorkspace]);

  const handlePostingProfileChange = (event) => {
    const selectedProfile = postingProfileOptions.find(profile => profile.value === event.target.value);

    setLinkedInPostingAccount(selectedProfile?.value || "");
    setProfilePic(selectedProfile?.image || "");
    setName(selectedProfile?.label || "");

    // Console Log the selected profile
    console.log("Selected Profile:", selectedProfile);

    if (selectedProfile?.type === 'organization') {
      setPostingProfile('Organisation');
    } else {
      setPostingProfile('Personal');
    }

    const linkedInAccounts = currentWorkspace?.linkedInAccounts || {};
    setHeadline(linkedInAccounts[selectedProfile?.value]?.headline || "A PropelPosts User");
  };  

  
  const renderProfileOption = (option) => (
    <MenuItem key={option.value} value={option.value}>
        <img src={option.image} alt={option.label} style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
        {option.label}
    </MenuItem>
);

  // Console Log the Passed Posts
  console.log("Posts in Draw", posts);
  console.log("Current Workspace", currentWorkspace);

  const renderDrawerContent = () => {
    if (showFullPost) {
      return (
        <SoftBox
          sx={{
            cursor: 'pointer',
            height: '100%',
            overflow: 'auto',
            width: '100%',
          }}
        >
          <SoftBox
            pl={2}
            pr={2}
            pt={2}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={() => onClose()}>
              <Close />
            </IconButton>
          </SoftBox>
          <SoftBox pl={2} pr={2} pt={2} pb={2}>
            <Typography variant="body1">{selectedPost}</Typography>
          </SoftBox>
        </SoftBox>
      );
    }
    return (
      <SoftBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <IconButton onClick={() => onClose()}>
              <Close />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <SoftBox textAlign="left" mb={2}>
              <SoftTypography variant="h4" fontWeight="medium">
                Content Converted To Posts
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12}>
          {postingProfileOptions.length > 0 && (
                <SoftBox>
                  <Select
                    size="small"
                    displayEmpty
                    value={linkedInPostingAccount}
                    onChange={handlePostingProfileChange}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>Select Profile</em>;
                      }

                      const selectedOption = postingProfileOptions.find(option => option.value === selected);
                      return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={selectedOption?.image || ""} alt="Profile Picture" style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
                          {selectedOption?.label || ""}
                        </div>
                      );
                    }}
                  >
                    {postingProfileOptions.map(renderProfileOption)}
                  </Select>
                </SoftBox>
              )}
          </Grid>
            {posts.slice(currentStartIndex, currentStartIndex + postsPerPage).map((post, index) => (
              <Grid item xs={12} md={4} key={index}>
                <SoftBox
                  // onClick={() => handlePostClick(post)}
                  p={2}
                >
                  <LinkedInProfileCard
                    image={profilePic}
                    name={name}
                    headline={headline}
                    postContent={post.substring(0, 100)}
                    showReactions={false}
                    shouldTruncateText={true}
                  />
                </SoftBox>
              </Grid>
            ))}
          <SoftBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <IconButton onClick={handleLeftArrowClick} disabled={currentStartIndex === 0}>
              <ArrowBack />
            </IconButton>
            <IconButton onClick={handleRightArrowClick} disabled={currentStartIndex + postsPerPage >= posts.length}>
              <ArrowForward />
            </IconButton>
          </SoftBox>
          <SoftBox sx={{ display: 'flex', justifyContent: 'space-around', width: '100%', marginTop: '20px' }}>
            <SoftButton variant="contained" color="primary" onClick={handleSaveDraft}>
              {isSaving ? "Saving..." : "Save Draft"}
            </SoftButton>
            {/* <SoftButton variant="contained" color="secondary" onClick={handleConfirmSchedule}>
              Schedule All
            </SoftButton> */}
          </SoftBox>
        </Grid>
      </SoftBox>
    );

    <SoftSnackbar
      open={snackbarOpen}
      close={() => setSnackbarOpen(false)}
      dateTime={new Date().toLocaleString()}
      content={snackbarMessage}
      color={snackbarSeverity}
      icon="notifications"
      title="Notification"
  />
  };

  return (
    <Drawer
      anchor="right"
      open={onOpen}
      onClose={onClose}
      PaperProps = {{ sx: drawerStyle }}
    >
      <Card sx={{ width: '100%', maxHeight: '100%', overflow: 'auto', p: 2 }}>
        {renderDrawerContent()}
      </Card>
    </Drawer>
  );
}

const drawerStyle = {
  width: "75%", // Default width for larger screens
  maxHeight: "95%", // Set a maximum height
  overflowY: "auto", // Set vertical overflow to auto
  bgcolor: '#FFFFFF', // Set the background color to white
  '@media (max-width: 768px)': {
    width: '100%',  // Full width on mobile screens
  },
};

PostGrid.propTypes = {
  posts: PropTypes.array,
  userData: PropTypes.object.isRequired,
  onOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.bool.isRequired,
  currentWorkspace: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
};

PostGrid.defaultProps = {
  posts: [],
};

export default PostGrid;
