import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

// Import MUI components
import { Grid, Tabs, Tab, Box, Typography, Card, CardContent, IconButton, Avatar } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import ScheduleIcon from '@mui/icons-material/Schedule';

// Import SoftUI components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftSnackbar from 'components/SoftSnackbar';

// Import Page Components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

// Import FetchPosts Database Function
import { fetchPosts, deletePost, updateScheduledTime, getUserOrganizations } from 'components/DatabaseFunctions';

// Import User Context
import { useUserData } from "context/userManagement";

// Import Firebase Context
import FirebaseContext from "configs/firebasecontext";

// Import Modals
import SchedulePostModal from 'layouts/pages/write-post/components/SchedulePostsModal';
import ViewPostModal from 'layouts/pages/posts/components/viewPostModal';

// Import Shared Functions
import { handleConfirmSchedule, handleStoreDraft, handlePostNow } from "components/SharedFunctions/postFunctions";
import { useNextAvailableTimeSlot } from "components/customSchedule";

const PostsPage = () => {
  const { userId, currentUserData, currentWorkspace } = useUserData();
  const { db } = useContext(FirebaseContext);
  const [postsData, setPostsData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [tabIndex, setTabIndex] = useState(2);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [scheduleMode, setScheduleMode] = useState('manual');
  const [customSchedule, setCustomSchedule] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const nextSlot = useNextAvailableTimeSlot(userId, db);
  const [isScheduling, setIsScheduling] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [postingProfileOptions, setPostingProfileOptions] = useState([]);
  const [profilePics, setProfilePics] = useState({});
  const [linkedInPostingAccount, setLinkedInPostingAccount] = useState("");
  const [postingProfile, setPostingProfile] = useState("Personal");

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const fetchProfilePictures = async (posts) => {
    const linkedInUIDs = posts.map(post => post.linkedInUID);
    const pics = {};
    linkedInUIDs.forEach(uid => {
      const profile = postingProfileOptions.find(profile => profile.value === uid);
      if (profile) {
        pics[uid] = profile.image;
      }
    });
    setProfilePics(pics);
  };

  // Fetch Posts Data
  useEffect(() => {
    const fetchPostsData = async () => {
      try {
        const posts = await fetchPosts(db, currentWorkspace.id);

        if (posts === null) {
          setSnackbarMessage("No posts made yet!");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setTimeout(() => {
            setSnackbarOpen(false);
          }, 5000);
          return;
        }

        const drafts = posts.filter(post => post.status === "Draft");
        const scheduled = posts.filter(post => post.status === "Scheduled");
        const published = posts.filter(post => post.status === "Posted");

        // Set Posts Data in Date Order with the latest post first
        drafts.sort((a, b) => b.createdTimestamp - a.createdTimestamp);
        scheduled.sort((a, b) => b.scheduledTimestamp - a.scheduledTimestamp);
        published.sort((a, b) => b.postedTimestamp - a.postedTimestamp);

        setPostsData({ drafts, scheduled, published });
        fetchProfilePictures(posts);

      } catch (error) {
        setSnackbarMessage("Error fetching posts!");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
        }, 5000);
      }
    };

    fetchPostsData();
  }, [db, currentWorkspace]);

  useEffect(() => {
    if (!userId || !db || !currentWorkspace) {
      return;
    }

    const fetchData = async () => {
      // Console Log
      console.log("Fetching User Organizations");

      const data = await getUserOrganizations(db, userId, currentWorkspace.id);
      const linkedInAccounts = currentWorkspace?.linkedInAccounts || {};

      // Console log the linkedInAccounts and data for debugging
      console.log("LinkedIn Accounts:", linkedInAccounts);
      console.log("Organizations:", data);

      const linkedInAccountsOptions = Object.values(linkedInAccounts).map((account) => ({
        value: account.profileURN,
        label: account.name,
        image: account.profilePic || "",
        type: "account",
      }));

      let connectedOrgsOptions = [];
      if (data) {
        const filteredData = data.filter((org) => currentWorkspace.connectedOrganizations.includes(org.organizationURN));
        setOrganizations(filteredData);

        connectedOrgsOptions = filteredData.map((org) => ({
          value: org.organizationURN,
          label: org.name,
          image: org.logoUrl || "",
          type: "organization",
        }));
      }

      const combinedOptions = [...linkedInAccountsOptions, ...connectedOrgsOptions];
      // Console Log the combinedOptions for debugging
      console.log("Combined Options:", combinedOptions);
      setPostingProfileOptions(combinedOptions);

      if (combinedOptions.length > 0) {
        setLinkedInPostingAccount(combinedOptions[0].value);
        setPostingProfile(combinedOptions[0].type === 'organization' ? 'Organisation' : 'Personal');
      }
    };

    fetchData();
  }, [userId, db, currentWorkspace]);

  const handleDelete = async (postId) => {
    try {
      await deletePost(db, postId, currentWorkspace.id);
      setSnackbarMessage("Post deleted successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      const posts = await fetchPosts(db, currentWorkspace.id);
      const drafts = posts.filter(post => post.status === "Draft");
      const scheduled = posts.filter(post => post.status === "Scheduled");
      const published = posts.filter(post => post.status === "Posted");
      setPostsData({ drafts, scheduled, published });
      fetchProfilePictures(posts);
    } catch (error) {
      setSnackbarMessage("Error deleting post!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 5000);
    }
  };

  const handleOpenScheduleModal = (post) => {
    setSelectedPost(post);
    setScheduleModalOpen(true);
  };

  const handleOpenViewModal = (post) => {
    setSelectedPost(post);
    setViewModalOpen(true);
  };

  const handleSchedulePostFromModal = async () => {
    setIsScheduling(true);

    try {
      await updateScheduledTime(db, selectedPost.id, selectedTime, currentWorkspace.id);
      setSnackbarMessage("Post scheduled successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      const posts = await fetchPosts(db, currentWorkspace.id);
      const drafts = posts.filter(post => post.status === "Draft");
      const scheduled = posts.filter(post => post.status === "Scheduled");
      const published = posts.filter(post => post.status === "Posted");
      setPostsData({ drafts, scheduled, published });
      fetchProfilePictures(posts);
    } catch (error) {
      setSnackbarMessage("Error scheduling post!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsScheduling(false);
      setScheduleModalOpen(false);
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 5000);
    }
  };

  const renderPosts = (posts) => (
    <Grid container spacing={3}>
      {posts?.map((post) => (
        <Grid item xs={12} md={4} key={post.id}>
          <Card>
            <CardContent>
              <SoftBox display="flex" alignItems="center" mb={2}>
                <Avatar src={profilePics[post.linkedInUID]} alt="Author" />
                <SoftBox display="flex" flexDirection="column" ml={2}>
                  <SoftTypography variant="caption" fontWeight="bold" gutterBottom>
                  {post?.postedTimestamp ? `Published on ${new Date(post.postedTimestamp.seconds * 1000).toLocaleString()}` : post?.scheduledTimestamp ? `Scheduled for ${new Date(post.scheduledTimestamp.seconds * 1000).toLocaleString()}` : "Draft"}
                  </SoftTypography>
                </SoftBox>

              </SoftBox>
              <SoftBox display="flex" alignItems="center" mb={2}>
                <SoftTypography variant="body2" fontWeight="regular" ml={2}>
                  {post?.content ? post.content.substring(0, 75) : "Untitled Post"}
                </SoftTypography>
              </SoftBox>

              <SoftBox display="flex" justifyContent="flex-start" alignItems="center" mt={2}>
                <IconButton aria-label="view post" onClick={() => handleOpenViewModal(post)}>
                  <OpenInNewIcon />
                </IconButton>
                {post.status !== "Posted" && (
                  <IconButton aria-label="schedule post" onClick={() => handleOpenScheduleModal(post)}>
                    <ScheduleIcon />
                  </IconButton>
                )}
                {post.status !== "Posted" && (
                <IconButton aria-label="delete post" onClick={() => handleDelete(post.id)}>
                  <DeleteIcon />
                </IconButton>
                )}
              </SoftBox>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox p={3}>
        <SoftTypography variant="h4" fontWeight="bold" mb={2}>
          Posts
        </SoftTypography>
        <SoftTypography variant="body2" mb={3}>
          Here are your Posts
        </SoftTypography>
        <Tabs value={tabIndex} onChange={handleChange} indicatorColor="primary" textColor="primary">
          <Tab label={`Drafts (${postsData?.drafts?.length || 0})`} />
          <Tab label={`Scheduled (${postsData?.scheduled?.length || 0})`} />
          <Tab label={`Published (${postsData?.published?.length || 0})`} />
        </Tabs>
        <Box mt={3}>
          {tabIndex === 0 && renderPosts(postsData?.drafts)}
          {tabIndex === 1 && renderPosts(postsData?.scheduled)}
          {tabIndex === 2 && renderPosts(postsData?.published)}
        </Box>
      </SoftBox>
      <Footer />

      <SoftSnackbar
        open={snackbarOpen}
        close={() => setSnackbarOpen(false)}
        dateTime={new Date().toLocaleString()}
        content={snackbarMessage}
        color={snackbarSeverity}
        icon="notifications"
        title="Notification"
      />

      {selectedPost && (
        <>
          <SchedulePostModal
            open={scheduleModalOpen}
            onClose={() => setScheduleModalOpen(false)}
            scheduleMode={scheduleMode}
            setScheduleMode={setScheduleMode}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
            handleConfirmSchedule={handleSchedulePostFromModal}
            customSchedule={customSchedule}
            nextSlot={nextSlot}
            isScheduling={isScheduling}
            isScheduled={isScheduled}
          />
          <ViewPostModal
            open={viewModalOpen}
            onClose={() => setViewModalOpen(false)}
            post={selectedPost}
          />
        </>
      )}
    </DashboardLayout>
  );
};

export default PostsPage;
