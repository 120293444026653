import { useState, useEffect, useContext } from 'react';
import FirebaseContext from "configs/firebasecontext";
import { getPostReactions, getPostComments } from "components/DatabaseFunctions";
import { useUserData } from "context/userManagement";

const useFetchPosts = (posts) => {
  const { db } = useContext(FirebaseContext);
  const { currentWorkspace } = useUserData();
  const [allReactions, setAllReactions] = useState({});
  const [allComments, setAllComments] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Check states have settled
    if (!posts || posts.length === 0) {
      setLoading(false);
      return;
    }

    // Fetch all reactions and comments for each post

    const fetchAllReactions = async () => {
      let reactionsForAllPosts = {};

      for (let post of posts) {
        const reactionsFromDb = await getPostReactions(db, post.postId, currentWorkspace.id);
        reactionsForAllPosts[post.postId] = reactionsFromDb;
      }

      setAllReactions(reactionsForAllPosts);
    };

    const fetchAllComments = async () => {
      let commentsForAllPosts = {};

      for (let post of posts) {
        const commentsFromDb = await getPostComments(db, post.postId, currentWorkspace.id);
        commentsForAllPosts[post.postId] = commentsFromDb;
      }

      setAllComments(commentsForAllPosts);
    };

    fetchAllComments();
    fetchAllReactions();
    setLoading(false);
  }, [posts, db]);

  return { allReactions, allComments, loading };
};

export default useFetchPosts;
