// LoadingOverlay.js
import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

const Overlay = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent background
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1300, // Make sure it's above other content
}));

const LoadingOverlay = () => {
  return (
    <Overlay>
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    </Overlay>
  );
};

export default LoadingOverlay;
