import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";

// Import MUI
import {
  Drawer,
  Grid,
  Switch,
  Divider,
} from "@mui/material";

// SoftUI
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Lottie from "react-lottie";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";

// Import MUI Icons for Buttons
import {
  Done,
  AutoFixHigh,
  ThumbUp,
  ShortText,
  Spellcheck,
  BeachAccess,
  Gavel,
  VerifiedUser,
} from "@mui/icons-material";

import SpaceShip from "examples/Icons/SpaceShip";

// Context Imports
import { useUserData } from "context/userManagement";
import FirebaseContext from "configs/firebasecontext";
import { httpsCallable } from "@firebase/functions";

// Import database functions
import { fetchAiProfile } from "components/DatabaseFunctions";

function OpenAICompletions({
  postContent,
  setPostContent,
  setPostImageUrl,
  setImageMetaData,
  setInitialComment,
  open,           // Added open prop
  onClose,         // Added onClose prop
  linkedInPostingAccount,
  workspaceId
}) {
  // OpenAPI state variables
  const [activeModal, setActiveModal] = useState("options");
  const [aiResponse, setAIResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [postTopic, setPostTopic] = useState("");
  const [postAudience, setPostAudience] = useState("");
  const [postStyle, setPostStyle] = useState([]);
  const [aiProfile, setAiProfile] = useState(null);
  const [useAiProfile, setUseAiProfile] = useState(false);

  // Firebase Context
  const { functions, db } = useContext(FirebaseContext);

  // Get user context states
  const { currentUserData } = useUserData();
  const tenantId = currentUserData?.tenantData[0]?.id;

  // Console Log for Debugging - Current User Data
  console.log("Current User Data in Front End:", currentUserData);

  // Console Log for Debugging - Tenant ID
  console.log("Tenant ID in Front End:", tenantId);

  // Function to clear post content and editor state
  const clearPostContent = () => {
    setPostContent("");
    setPostImageUrl(null);
    setImageMetaData(null);
    setInitialComment("");
  };

  // Get AI Profile from database on selection of linkedInPostingAccount
  useEffect(() => {
    if (linkedInPostingAccount) {
      fetchAiProfile(db, workspaceId, linkedInPostingAccount).then((profile) => {
        setAiProfile(profile);
        // Console Log for Debugging - AI Profile
        console.log("AI Profile:", profile);
      });
    }
  }, [linkedInPostingAccount]);

  // States for the options on AI writing
  const [state, setState] = useState({
    improve: false,
    complete: false,
    engagement: false,
    concise: false,
    grammar: false,
    formal: false,
    casual: false,
    assertive: false,
  });

  // Function to call OpenAITools Cloud Function
  const callOpenAITools = async (option) => {
    setIsLoading(true);
    try {
      const openAIToolsFunction = httpsCallable(functions, process.env.REACT_APP_CLOUD_FUNCTION_OPENAI_TOOLS);
      const response = await openAIToolsFunction({
        tenantId,
        option,
        postContent,
        useAiProfile,
        aiProfile,
      });

      setAIResponse(response.data);
    } catch (error) {
      console.error("Error calling OpenAITools:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to call OpenAIWrite Cloud Function
  const callOpenAIWrite = async () => {
    setIsLoading(true);
    try {
      const openAIWriteFunction = httpsCallable(functions, process.env.REACT_APP_CLOUD_FUNCTION_OPENAI_WRITE);
      const response = await openAIWriteFunction({
        tenantId,
        postTopic,
        postAudience,
        postStyle,
        useAiProfile,
        aiProfile,
      });

      setAIResponse(response.data);
    } catch (error) {
      console.error("Error calling OpenAIWrite:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleState = (key) => {
    const newState = { ...state, [key]: !state[key] };
    setState(newState);
  };

  // Handle style change for the SoftSelect component
  const handleStyleChange = (selectedOptions) => {
    const selectedStyles = selectedOptions.map((option) => option.value);
    setPostStyle(selectedStyles);
  };

  // Lottie Animation OpenAI Thinking
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: require("assets/lottie/animation_lmc3wm1x.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const customButtonStyles = ({
    functions: { pxToRem, rgba },
    borders: { borderWidth },
    palette: { transparent, dark, secondary },
  }) => ({
    width: pxToRem(180),
    height: pxToRem(80),
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,

    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `${borderWidth[2]} solid ${transparent.main}`,
    },

    "&:hover": {
      backgroundColor: `${transparent.main} !important`,
      border: `${borderWidth[2]} solid ${secondary.main} !important`,

      "& svg g": {
        fill: rgba(dark.main, 0.75),
      },
    },
  });

  const getIcon = (option, color) => {
    switch (option) {
      case "improve":
        return <AutoFixHigh style={{ color }} />;
      case "complete":
        return <Done style={{ color }} />;
      case "engagement":
        return <ThumbUp style={{ color }} />;
      case "concise":
        return <ShortText style={{ color }} />;
      case "grammar":
        return <Spellcheck style={{ color }} />;
      case "formal":
        return <Gavel style={{ color }} />;
      case "casual":
        return <BeachAccess style={{ color }} />;
      case "assertive":
        return <VerifiedUser style={{ color }} />;
      default:
        return null;
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getSubtitle = (option) => {
    switch (option) {
      case "improve":
        return "Readability";
      case "complete":
        return "Complete Post";
      case "engagement":
        return "Boost Post";
      case "concise":
        return "Shorter Post";
      case "grammar":
        return "Correct Errors";
      case "formal":
        return "Formal Tone";
      case "casual":
        return "Casual Tone";
      case "assertive":
        return "Assertive Tone";
      default:
        return "";
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open} // Use the open prop
        onClose={onClose} // Use the onClose prop
        PaperProps={{
          sx: {
            width: "450px", // Default width for larger screens
            maxHeight: "95%", // Set a maximum height
            overflowY: "auto", // Set vertical overflow to auto
            bgcolor: '#FFFFFF', // Set the background color to white
            '@media (max-width: 768px)': {
              width: '100%',  // Full width on mobile screens
            }
          }
        }}
      >
        <SoftBox p={3}>
          <SoftBox mb={2} display="flex" justifyContent="center" alignItems="center">
            <SoftTypography variant="body2" fontWeight="medium" color="dark">
              {activeModal === "options" ? "AI tools to improve your posts" : "Write a post with the help of AI"}
            </SoftTypography>
          </SoftBox>

          <SoftBox mb={3} display="flex" justifyContent="center" alignItems="center">
            <SoftTypography variant="button" fontWeight="light" mr={1}>AI Tools</SoftTypography>
            <Switch
              checked={activeModal === "writing"}
              onChange={() => setActiveModal(activeModal === "options" ? "writing" : "options")}
              name="modalSwitch"
            />
            <SoftTypography variant="button" fontWeight="light" color="dark" ml={1}>AI Writing</SoftTypography>
          </SoftBox>

          <Divider />

          {aiProfile ? (
            <SoftBox mb={2} display="flex" justifyContent="center" alignItems="center">
              <SoftTypography variant="button" fontWeight="light" color="dark" mr={1}>Use AI Profile</SoftTypography>
              <Switch
                checked={useAiProfile}
                onChange={() => setUseAiProfile(!useAiProfile)}
                name="profileSwitch"
              />
            </SoftBox>
          ) : (
            <SoftBox mb={2} display="flex" justifyContent="center" alignItems="center">
              <SoftTypography variant="body2" fontWeight="light" color="dark">Setup an AI profile for more personalized AI writing</SoftTypography>
            </SoftBox>
          )}

          <Divider />

          {activeModal === "options" && !isLoading && !aiResponse && (
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              {["improve", "complete", "engagement", "concise", "grammar", "formal", "casual", "assertive"].map((option, index) => (
                <Grid item xs={6} key={option}>
                  <SoftButton
                    color="secondary"
                    variant={state[option] ? "contained" : "outlined"}
                    onClick={() => callOpenAITools(option)}
                    sx={customButtonStyles}
                  >
                    <SoftBox display="flex" flexDirection="row" alignItems="center">
                      {getIcon(option, state[option] ? "white" : "dark")}
                      <SoftBox display="flex" flexDirection="column" ml={1}>
                        <SoftTypography variant="button" color={state[option] ? "white" : "dark"}>
                          {capitalizeFirstLetter(option)}
                        </SoftTypography>
                        <SoftTypography variant="caption" color={state[option] ? "white" : "dark"}>
                          {getSubtitle(option)}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </SoftButton>
                </Grid>
              ))}
              <SoftBox mt={2} mb={2} display="flex" justifyContent="center" alignItems="center">
                <SoftButton variant="contained" color="dark" onClick={onClose}>
                  Close
                </SoftButton>
              </SoftBox>
            </Grid>
          )}

          {activeModal === "writing" && !isLoading && !aiResponse && (
            <Grid container direction="column" spacing={2} alignItems="center" justifyContent="center">
              <Grid item>
                <SoftTypography variant="h6" fontWeight="medium" color="dark" textAlign="center">
                  What do you want to post about?
                </SoftTypography>
                <SoftInput multiline fullWidth placeholder="Describe your post..." onChange={(e) => setPostTopic(e.target.value)} />
                <Divider />
              </Grid>

              <Grid item>
                <SoftTypography variant="h6" fontWeight="medium" color="dark" textAlign="center">
                  Who is your audience for this post?
                </SoftTypography>
                <SoftInput multiline fullWidth variant="outlined" placeholder="Everyone, Content Creators, Business Owners, etc" onChange={(e) => setPostAudience(e.target.value)} />
                <Divider />
              </Grid>

              <Grid item>
                <SoftTypography variant="h6" fontWeight="medium" color="dark" textAlign="center">
                  Style of post (select all that apply)
                </SoftTypography>
                <SoftSelect
                  isMulti
                  color="secondary"
                  options={[
                    { value: "informative", label: "Informative" },
                    { value: "educational", label: "Educational" },
                    { value: "inspirational", label: "Inspirational" },
                    { value: "motivational", label: "Motivational" },
                    { value: "entertaining", label: "Entertaining" },
                    { value: "persuasive", label: "Persuasive" },
                    { value: "controversial", label: "Controversial" },
                    { value: "emotional", label: "Emotional" },
                    { value: "personal", label: "Personal" },
                    { value: "professional", label: "Professional" },
                    { value: "casual", label: "Casual" },
                    { value: "formal", label: "Formal" },
                    { value: "funny", label: "Funny" },
                    { value: "informal", label: "Informal" },
                    { value: "conversational", label: "Conversational" },
                    { value: "technical", label: "Technical" },
                  ]}
                  onChange={handleStyleChange}
                />
                <Divider />
              </Grid>

              <Grid item>
                <SoftBox display="flex" flexDirection="row" spacing={2}>
                  <SoftBox mr={2}>
                    <SoftButton variant="contained" color="dark" onClick={callOpenAIWrite}>
                      Write Post
                    </SoftButton>
                  </SoftBox>
                  <SoftBox>
                    <SoftButton variant="contained" color="light" onClick={onClose}>
                      Close
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
          )}

          {isLoading && !aiResponse && (
            <Grid item container direction="column" alignItems="center" justifyContent="center">
              <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                Sit back and relax
              </SoftTypography>
              <Lottie options={lottieOptions} height={200} width={200} />
            </Grid>
          )}

          {aiResponse && !isLoading && (
            <Grid container direction="column" mt={2} spacing={2} alignItems="center" justifyContent="center">
              <Grid item>
                <SoftBox
                  sx={{
                    maxHeight: '200px',
                    overflowY: 'auto',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                  }}
                >
                  <SoftTypography variant="body2" fontWeight="light" color="dark" whiteSpace="pre-line">
                    {aiResponse}
                  </SoftTypography>
                </SoftBox>
              </Grid>
              <Grid item>
                <SoftBox display="flex" flexDirection="row" spacing={2}>
                  <SoftBox mr={2}>
                    <SoftButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setPostContent(aiResponse);
                        onClose();
                        setActiveModal(null);
                        setAIResponse(null);
                      }}
                    >
                      <SoftBox mr={1}>
                        <SpaceShip size="15px" color="white" />
                      </SoftBox>
                      Accept and Edit
                    </SoftButton>
                  </SoftBox>
                  <SoftBox ml={2}>
                    <SoftButton variant="contained" color="error" onClick={() => setAIResponse(null)}>
                      Clear
                    </SoftButton>
                  </SoftBox>
                  <SoftBox ml={2}>
                    <SoftButton variant="contained" color="dark" onClick={onClose}>
                      Close
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
          )}
        </SoftBox>
      </Drawer>
    </>
  );
}

OpenAICompletions.propTypes = {
  postContent: PropTypes.string,
  setPostContent: PropTypes.func,
  setPostImageUrl: PropTypes.func,
  setImageMetaData: PropTypes.func,
  setInitialComment: PropTypes.func,
  open: PropTypes.bool, // Added open prop type
  onClose: PropTypes.func, // Added onClose prop type
  linkedInPostingAccount: PropTypes.string,
  workspaceId: PropTypes.string,
};

OpenAICompletions.defaultProps = {
  postContent: "",
  setPostContent: null,
  setPostImageUrl: "",
  setImageMetaData: null,
  setInitialComment: "",
  open: false, // Default open prop
  onClose: null, // Default onClose prop
  linkedInPostingAccount: "",
  workspaceId: "",
};

export { OpenAICompletions };
