import React, { useState } from "react";

import propTypes from 'prop-types';

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField";



function ExamplePosts({ onExampleChange }) {
    //State variables for example posts
  const [example1, setExample1] = useState("");
  const [example2, setExample2] = useState("");
  const [example3, setExample3] = useState("");

  return (
    <SoftBox>
      <SoftBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5" fontWeight="regular">
            Example Posts (Optional)
          </SoftTypography>
        </SoftBox>
        <SoftTypography variant="body2" fontWeight="regular" color="text">
          If you can provide three examples of posts or writing you have created in the past it will help us to tailor our AI to your style. These can be from any platform. Just copy and paste the text into the boxes below.
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <FormField 
              multiline label="Example 1" 
              placeholder=""
              onChange={(e) => {
                setExample1(e.target.value);
                onExampleChange({
                  example1: e.target.value,
                  example2,
                  example3
                });
              }}
            />
          </Grid>
          </Grid>
          <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <FormField 
              multiline label="Example 2"
              placeholder=""
              onChange={(e) => {
                setExample1(e.target.value);
                onExampleChange({
                  example1,
                  example2: e.target.value,
                  example3
                });
              }}
            />
          </Grid>
          </Grid>
          <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <FormField
            multiline label="Example 3" 
            placeholder="" 
            onChange={(e) => {
              setExample3(e.target.value);
              onExampleChange({
                example1,
                example2,
                example3: e.target.value
              });
            }}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

ExamplePosts.propTypes = {
  onExampleChange: propTypes.func.isRequired,
};

export default ExamplePosts;
