import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";

// Import MUI Components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Import Soft UI Controller
import Sidenav from "./examples/Sidenav";
import theme from "./assets/theme";
import routes from "./routes";
import pageRoutes from "./page.routes";
import { useSoftUIController, setMiniSidenav } from "./context";
import brand from "./assets/images/PropelPosts.png";

// Import Components
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { Analytics } from "@vercel/analytics/react";

// Import Page Components
import Error404 from "./layouts/authentication/error/404";
import SignIn from "./layouts/authentication/sign-in";
import SignUp from "./layouts/authentication/sign-up";
import LandingPage from "./landing";
import InviteSignup from "./layouts/authentication/invite";
import Dashboard from "./layouts/dashboards/default";
import PasswordReset from "layouts/authentication/reset-password";

// Import Auth Context and Protected Route
import { AuthProvider } from "./context/firebaseAuthProvider";
import ProtectedRoute from "./context/authContext";

// Import ChartJS 
import Chart from 'chart.js/auto';

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) => {
    let allRouteComponents = [];
    allRoutes.forEach((route) => {
      if (route.collapse) {
        allRouteComponents = allRouteComponents.concat(getRoutes(route.collapse, `${route.route ? `${route.route}/` : ''}`));
      } else if (route.route) {
        allRouteComponents.push(
          <Route
            exact
            path={`${route.route}`}
            element={<ProtectedRoute element={route.component} />}
            key={route.key}
          />
        );
      }
    });
    return allRouteComponents;
  };

  const getPageRoutes = (allRoutes) => {
    let allRouteComponents = [];
    allRoutes.forEach((route) => {
      if (route.collapse) {
        allRouteComponents = allRouteComponents.concat(getRoutes(route.collapse, `${route.route ? `${route.route}/` : ''}`));
      } else if (route.route) {
        allRouteComponents.push(
          <Route
            exact
            path={`${route.route}`}
            element={<route.component />}
            key={route.key}
          />
        );
      }
    });
    return allRouteComponents;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Routes>
          {/* <Route path="/" element={<LandingPage />} /> */}
          {/* {getPageRoutes(pageRoutes)} */}
          <Route path="/" element={<Navigate to="/app/sign-in" />} />
          <Route path="/invite" element={<InviteSignup />} />
          <Route path="/linkedin-callback" element={<LinkedInCallback />} />
          <Route path="/404" element={<Error404 />} />
          <Route path="/authentication/sign-up" element={<SignUp />} />
          <Route path="/authentication/sign-in" element={<SignIn />} />
          <Route path="/authentication/reset-password" element={<PasswordReset />} />
          <Route path="/app/*" element={<AppLayout />}>
            {getRoutes(routes)}
            <Route path="sign-in" element={<SignIn />} />
            <Route path="sign-up" element={<SignUp />} />
            <Route path="dashboards/default" element={<ProtectedRoute element={<Dashboard />} />} />
          </Route>
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
        <Analytics />
      </AuthProvider>
    </ThemeProvider>
  );
}

function AppLayout() {
  const [controller, dispatch] = useSoftUIController();
  const { layout, sidenavColor, miniSidenav } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  return (
    <>
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="PropelPosts"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      <div>
        <Outlet />
      </div>
    </>
  );
}
